import React, { Component } from 'react';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
    Col, Row, Card, CardBody, Container, Table,
    Nav, NavItem, NavLink, Input
} from 'reactstrap';
import PaginationCustom from '../../components/pagination/Pagination';
import { Link } from "react-router-dom";
import { getCustomers, blockCustomer, unblockCustomer, getTotalToPay } from '../../services/UserServices';
import { connect } from 'react-redux';
import './RemittersList.scss';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import Loading from '../../components/Loading';
import { logoutAction } from '../../redux/actions/UserActions';
import ModalCustomer from '../../components/ModalCustomer/ModalCustomer';
import Select from 'react-select';
import ModalLiquidPendingRemitter from '../../components/ModalLiquidPendingRemitter/ModalLiquidPendingRemitter';

class RemittersList extends Component {
    state = {
        breadcrumbItems: [
            { title: "Remitentes", link: "#" },
            { title: 'Listado', link: "#" }
        ],
        activeTab: '0',
        page: 1,
        pages: 0,
        customers: [],
        loading: false,
        customerIdSelect: null,
        search: '',
        orderBy: { value: 'nameA', label: 'Orden alfabético ASC' },
        filterRemitter: { value: 'all', label: 'Filtrar remitentes' },
        remitterShowLiquid: null,
        remitterShowLotsTitle: null,
        totalToPay: null
    }

    componentDidMount = () => {
        this.fetchCustomers();
        toast.configure();
    }

    fetchCustomers = async (loading = true) => {
        loading && await this.setState({ loading: true });
        try {
            let response = await getCustomers({
                token: this.props.sessionProps.account.token,
                page: this.state.page,
                filter: this.state.activeTab,
                onlyRemitter: true,
                search: encodeURIComponent(this.state.search),
                orderBy: this.state.orderBy.value,
                filterRemitter: this.state.filterRemitter.value,
            });
            await this.setState({
                pages: response.data.data.pages,
                customers: response.data.data.customers,
                loading: false
            })
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    getTotalLiquidate = async () => {
        try {
            let response = await getTotalToPay({
                token: this.props.sessionProps.account.token,
                page: this.state.page,
                filter: this.state.activeTab,
                onlyRemitter: true,
                search: encodeURIComponent(this.state.search),
                orderBy: this.state.orderBy.value,
                filterRemitter: this.state.filterRemitter.value,
            });
            await this.setState({
                totalToPay: response.data.data.totalToPay
            })
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    onPrev = async () => {
        await this.setState({ page: this.state.page - 1 });
        this.fetchCustomers();
    }

    onNext = async () => {
        await this.setState({ page: this.state.page + 1 });
        this.fetchCustomers();
    }

    onPage = async (page) => {
        await this.setState({ page: page });
        this.fetchCustomers();
    }

    toggleTab = async (tab) => {
        if (this.state.activeTab !== tab) {
            await this.setState({
                activeTab: tab,
                page: 1
            });
            this.fetchCustomers();
        }
    }

    closeModal = async (refetch = true) => {
        await this.setState({
            customerIdSelect: null,
            remitterShowLiquid: null,
        });
        if (refetch) {
            this.fetchCustomers(true);
        }
    }

    handleChangeSearch = async (event) => {
        const { name, value } = event.target;
        await this.setState({
            [name]: value,
            page: 1
        });
        this.fetchCustomers(false);
    }

    changeOrderBy = async (e) => {
        await this.setState({ orderBy: e, page: 1 })
        this.fetchCustomers();
    }

    changeFilterRemitter = async (e) => {
        await this.setState({ filterRemitter: e, page: 1 })
        this.fetchCustomers();
        if (e.value === 'liquidPending') {
            this.getTotalLiquidate();
        }
    }

    render() {
        return (
            <div>
                <div className="page-content">
                    {this.state.loading && <Loading />}
                    <Container fluid>
                        <Breadcrumbs title="Listado de remitentes" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody className="without-padding">
                                        <div class="row">
                                            <div className="col-7">
                                                <div className="form-inline mb-2">
                                                    <div className="search-box">
                                                        <div className="position-relative">
                                                            <Input type="text" name="search" className="form-control rounded" placeholder="Buscar # o nombre"
                                                                value={this.state.search} onChange={this.handleChangeSearch} />
                                                            <i className="mdi mdi-magnify search-icon"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-5 text-right div-btn-add">
                                                <Select
                                                    name="orderBy"
                                                    className="select2 select2-multiple w-50 mr-2"
                                                    options={[
                                                        { value: 'nameA', label: 'Orden alfabético ASC' },
                                                        { value: 'nameD', label: 'Orden alfabético DESC' },
                                                        { value: 'idA', label: 'Orden por código ASC' },
                                                        { value: 'idD', label: 'Orden por código DESC' }
                                                    ]}
                                                    value={this.state.orderBy}
                                                    onChange={(e) => this.changeOrderBy(e)}
                                                />
                                                <Select
                                                    name="filterRemitter"
                                                    className="select2 select2-multiple w-50"
                                                    options={[
                                                        { value: 'all', label: 'Todos' },
                                                        { value: 'liquidPending', label: 'Con liquidación pendiente' },
                                                    ]}
                                                    value={this.state.filterRemitter}
                                                    onChange={(e) => this.changeFilterRemitter(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-3"></div>
                                            <div className="col-6 text-center">
                                                <PaginationCustom page={this.state.page} pages={this.state.pages}
                                                    onPrev={this.onPrev} onNext={this.onNext} onPage={this.onPage} />
                                            </div>
                                            <div className="col-3 text-right div-btn-add">
                                                <Link onClick={() => this.setState({ customerIdSelect: 0 })} className="btn btn-success btn-add w-xs" style={{ marginRight: '1rem' }}>
                                                    <i className="mdi mdi-plus"></i> Agregar
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <Table className="mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Nombre</th>
                                                        <th>Email</th>
                                                        <th>Teléfono</th>
                                                        <th>Dirección</th>
                                                        <th>Prox. Remisión</th>
                                                        <th>Comisión</th>
                                                        {
                                                            this.state.filterRemitter.value === 'liquidPending' ?
                                                                <th>Total a pagar</th> : null
                                                        }
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.customers.map(item =>
                                                            <tr>
                                                                <th scope="row">{item.id}</th>
                                                                <td>{item.name}
                                                                    {
                                                                        item.fb &&
                                                                        <i className="mdi mdi-facebook" style={{ color: 'blue' }}></i>
                                                                    }
                                                                </td>
                                                                <td>{item.email}</td>
                                                                <td>{item.phone} - {item.telephone}</td>
                                                                <td>{item.address}{item.address ? ', ' : ''}
                                                                    {item.city}{item.city ? ', ' : ''}
                                                                    {item.departament ? item.departament.name : ''}</td>
                                                                <td>{item.remision}</td>
                                                                <td>{item.comision}{item.comision ? '%' : '-'}</td>
                                                                {
                                                                    this.state.filterRemitter.value === 'liquidPending' ?
                                                                        <td>$ {item.totalToPay}</td> : null
                                                                }
                                                                <td>
                                                                    <React.Fragment>
                                                                        <Link to="#" onClick={() => this.setState({ customerIdSelect: item.id })} className="text-success"><i className="mdi mdi-account-edit font-size-22" style={{ marginLeft: '5px' }}></i></Link>
                                                                    </React.Fragment>
                                                                    {
                                                                        this.state.filterRemitter.value === 'liquidPending' &&
                                                                        (
                                                                            <Link onClick={() => this.setState({ remitterShowLiquid: item.id, remitterShowLotsTitle: item.name })} className="text-success mr-1"><i className="mdi mdi-format-list-numbered font-size-18"></i></Link>
                                                                        )
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        <PaginationCustom page={this.state.page} pages={this.state.pages}
                                            onPrev={this.onPrev} onNext={this.onNext} onPage={this.onPage} />
                                        {
                                            this.state.filterRemitter.value === 'liquidPending' &&
                                            (
                                                <div className="row">
                                                    <div className="col-12 text-right" style={{
                                                        alignItems: 'flex-end',
                                                        justifyContent: 'flex-end',
                                                        paddingBottom: '1rem'
                                                    }}>
                                                        <span style={{ marginRight: '2rem', fontSize: '17px' }}>
                                                            Total a pagar: {this.state.totalToPay === null ?
                                                                'Cargando...' : <b>$ {this.state.totalToPay}</b>}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {
                    this.state.customerIdSelect !== null &&
                    <ModalCustomer customerId={this.state.customerIdSelect} {...this.props} close={() => this.closeModal()} forceRemitter={true} />
                }
                {
                    this.state.remitterShowLiquid !== null && (
                        <ModalLiquidPendingRemitter id={this.state.remitterShowLiquid} close={() => this.closeModal(false)} title={this.state.remitterShowLotsTitle} />
                    )
                }
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RemittersList);