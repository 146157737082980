import React, { Component } from 'react';
import { Row, Col, Button, Alert, Container, Label, FormGroup } from "reactstrap";
import { Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import { userLoginAction } from '../../redux/actions/UserActions';
import Loading from '../../components/Loading';
import { Constants } from '../../Constants';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: ""
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        this.props.login({
            email: this.state.email,
            password: this.state.password
        });
    }

    handleChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value })
    }

    render() {

        return (
            <React.Fragment>
                {this.props.sessionProps.loading && <Loading />}

                <div className="home-btn d-none d-sm-block">
                    <Link to="/"><i className="mdi mdi-home-variant h2 text-white"></i></Link>
                </div>

                <div>
                    <Container fluid className="p-0">
                        <Row className="no-gutters">
                            <Col lg={4}>
                                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col lg={9}>
                                                <div>
                                                    <div className="text-center">
                                                        <div>
                                                            <Link to="/" className="logo">
                                                                <img src={this.props.configAccount.config?.logo_admin ? Constants.BASE_URL + this.props.configAccount.config.logo_admin : ''}
                                                                    height="45" alt="logo" />
                                                            </Link>
                                                        </div>

                                                        <h4 className="font-size-18 mt-4">Bienvenido!</h4>
                                                        <p className="text-muted">Iniciar sesión para ingresar al panel.</p>
                                                    </div>


                                                    {this.props.sessionProps.error && this.props.sessionProps.error ? <Alert color="danger">{this.props.sessionProps.error}</Alert> : null}

                                                    <div className="p-2">
                                                        <AvForm className="form-horizontal" onValidSubmit={this.handleSubmit}>

                                                            <FormGroup className="auth-form-group-custom mb-4">
                                                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="email">Email</Label>
                                                                <AvField
                                                                    name="email" value={this.state.email}
                                                                    type="text" className="form-control"
                                                                    id="email" validate={{ required: true }}
                                                                    placeholder="Ingresar email" onChange={this.handleChange} />
                                                            </FormGroup>

                                                            <FormGroup className="auth-form-group-custom mb-4">
                                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="password">Contraseña</Label>
                                                                <AvField
                                                                    name="password" value={this.state.password}
                                                                    type="password" className="form-control" onChange={this.handleChange}
                                                                    id="password" placeholder="Ingresar contraseña" />
                                                            </FormGroup>

                                                            <div className="text-center">
                                                                <Button color="primary" className="w-md waves-effect waves-light" type="submit">Ingresar</Button>
                                                            </div>

                                                        </AvForm>
                                                    </div>

                                                    <div className="mt-5 text-center">
                                                        {new Date().getFullYear()} © by <a href="http://sayasoftware.com" target="_blank">SayaSoftware</a>.
                                                    </div>
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="authentication-bg">
                                    <div className="bg-overlay"></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => ({
    login: (data) => {
        dispatch(userLoginAction(data))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);