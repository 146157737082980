import React, { Component } from 'react';
import { logoutAction } from '../../redux/actions/UserActions';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardBody, FormGroup } from 'reactstrap';
import Loading from '../Loading';
import { toast } from 'react-toastify';

class ModalAddExpenseRemitter extends Component {
    state = {
        amount: '',
        loading: false,
    }

    componentDidMount = () => {
        toast.configure();
        document.getElementById("amount").focus();
    }

    addAmount = () => {
        if (this.state.amount === '') {
            toast.error(`Debe agregar un monto`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
        let amount = this.state.amount;
        if (this.props.isRest) {
            amount = amount * -1;
        }
        this.props.addNewExpense(amount);
    }

    onKeyDown = (e) => {
        if (e.keyCode === 13) {
            this.addAmount()
        }
        if (e.keyCode === 27) {
            this.close();
        }
    }

    close = () => {
        this.props.close();
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    render() {
        return (
            <div className="container-modal">
                {this.state.loading && <Loading />}
                <div className="content-modal">
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div onClick={() => this.close()} style={{ textAlign: 'right', cursor: 'pointer' }}>
                                            X
                                        </div>
                                        <div>
                                            <h4>{`${this.props.isRest ? 'Quitar' : 'Agregar'}`} gasto al remitente #{this.props.remitter.label}</h4>
                                        </div>
                                        <div style={{ marginTop: '1rem' }}>
                                            <div className="form-group">
                                                <label>Monto</label>
                                                <input type="text" className="form-control" placeholder="Ingrese monto"
                                                    name="amount" id="amount" onKeyDown={(e) => { this.onKeyDown(e) }}
                                                    value={this.state.amount} onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <FormGroup className="mb-0 text-right" style={{ marginTop: '1rem' }}>
                                            <div>
                                                <button onClick={() => this.close()} type="button" className="btn btn-secondary">Cerrar</button>
                                                <button onClick={() => this.addAmount()} style={{ marginLeft: '0.5rem' }} className="btn btn-primary">Agregar</button>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddExpenseRemitter);