import axios from 'axios';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Constants } from '../../../../Constants';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { logoutAction } from '../../../../redux/actions/UserActions';

const deleteLot = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.delete(`${Constants.BASE_URL}lots/${data.id}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

class DeleteLot extends Component {
    deleteLot = () => {
        confirmAlert({
            title: 'Eliminar lote ' + this.props.item.title,
            message: `¿Esta seguro que desea eliminar el lote?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._deleteLot(this.props.item.id)
                    }
                }
            ]
        });
    }

    _deleteLot = async (id) => {
        await this.setState({ loading: true });
        try {
            let response = await deleteLot({
                token: this.props.sessionProps.account.token,
                id: id
            });
            await this.setState({ loading: false });
            this.props.fetchLots();
            toast(`Lote eliminado con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            } else {
                console.log(error)

                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    render() {
        return (
            <i onClick={this.deleteLot} style={{ cursor: 'pointer' }} className="mdi mdi-delete-forever font-size-22"></i>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteLot);
