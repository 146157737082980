import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardBody, Input, Table, Button, FormGroup } from 'reactstrap';
import { getRemisions } from '../../services/RemisionServices';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { customersListAutocomplete } from '../../services/UserServices';
import Loading from '../Loading';
import { logoutAction } from '../../redux/actions/UserActions';
import PaginationCustom from '../pagination/Pagination';
import axios from 'axios';
import { Constants } from '../../Constants';

export const getAuctions = async (data) => {
    /**
     * data = {
     *  token: string,
     *  auctionId: number,
     * }
     */
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.get(`${Constants.BASE_URL}import-lots/auctions`, {
            ...httpOptions,
        });
    } catch (error) {
        console.log(error)
        throw error;
    }
}

export const getLots = async (data) => {
    /**
     * data = {
     *  token: string,
     *  auctionId: number,
     * }
     */
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.get(`${Constants.BASE_URL}import-lots/lots?auctionId=${data.auctionId}`, {
            ...httpOptions
        });
    } catch (error) {
        throw error;
    }
}

class ModalImportLotsFromAuction extends Component {
    state = {
        auctions: [],
        auctionSelect: { value: null, label: 'Filtrar por remate' },
        lots: [],
        selectLots: [],
        page: 1,
        pages: 1
    }

    componentDidMount = () => {
        toast.configure();
        this.fetchAutions();
    }

    fetchAutions = async (showSpinner = true) => {
        showSpinner && await this.setState({ loading: true });
        try {
            console.log(this.props.sessionProps.account.token)
            let response = await getAuctions({
                token: this.props.sessionProps.account.token,
                auctionId: 1
            });
            console.log(response);
            let auctions = response.data.data.auctions;
            await this.setState({
                auctions,
                loading: false
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    onPrev = async () => {
        await this.setState({ page: this.state.page - 1 });
        this.fetchLots();
    }

    onNext = async () => {
        await this.setState({ page: this.state.page + 1 });
        this.fetchLots();
    }

    onPage = async (page) => {
        await this.setState({ page: page });
        this.fetchLots();
    }

    fetchLots = async () => {
        try {
            let response = await getLots({
                token: this.props.sessionProps.account.token,
                auctionId: this.state.auctionSelect.value
            });
            const lots = response.data.data.lots;
            // ordernar por id_lot
            lots.sort((a, b) => a.id_lot - b.id_lot);
            await this.setState({
                lots
            });
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    handleSelectChange = async (selectedOption) => {
        await this.setState({ auctionSelect: selectedOption, page: 1 });
        this.fetchLots();
    };

    setChecked = async (item) => {
        let selectLots = [...this.state.selectLots];
        //si existe en selectLots la saco, y sino la agrego
        let exist = this.state.selectLots.filter(_item => _item.id === item.id).length > 0;
        if (exist) {
            let _index = 0;
            for (let selectLot of selectLots) {
                if (selectLot.id === item.id) {
                    selectLots.splice(_index, 1);
                    break;
                }
                _index = _index + 1;
            }
        }
        else {
            selectLots.push(item);
        }
        //
        await this.setState({
            selectLots: selectLots
        });
    }

    checkAll = async () => {
        if (this.state.selectLots.length === this.state.lots.length) {
            await this.setState({
                selectLots: []
            });
        }
        else {
            let lots = [...this.state.lots];
            await this.setState({
                selectLots: lots
            });
        }
    }

    close = () => {
        this.props.close();
    }

    importLots = () => {
        this.props.importSelectedLots(this.state.selectLots);
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading && <Loading />}
                <div className="container-modal">
                    <div className="content-modal">
                        <Container>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <div className="row">
                                                <div className="col-10">
                                                    <h5 style={{ marginBottom: 0 }}>Importar lotes</h5>
                                                </div>
                                                <div className="col-2" onClick={() => this.close()} style={{ textAlign: 'right', cursor: 'pointer' }}>
                                                    <h5 style={{ marginBottom: 0 }}>X</h5>
                                                </div>
                                            </div>
                                            <hr style={{ marginTop: '0.5rem' }} />
                                            <div class="row" style={{ marginBottom: '1rem' }}>
                                                <div className="col-12 col-md-6">
                                                    <Select
                                                        className="select2 select2-multiple"
                                                        placeholder="Filtrar por remate"
                                                        options={this.state.auctions.map(item => ({ value: item.id, label: item.title }))}
                                                        value={this.state.auctionSelect}
                                                        onChange={this.handleSelectChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="row" style={{ paddingTop: '.5rem' }}>
                                                <div className="col-12 text-center">
                                                    <PaginationCustom page={this.state.page} pages={this.state.pages}
                                                        onPrev={this.onPrev} onNext={this.onNext} onPage={this.onPage} />
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <Table className="mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th><input type="checkbox" checked={this.state.selectLots.length && this.state.selectLots.length === this.state.lots.length}
                                                                onChange={() => this.checkAll()} /></th>
                                                            <th>Lote</th>
                                                            <th>Remisión</th>
                                                            <th>Nombre</th>
                                                            <th>Cantidad</th>
                                                            <th width="10%">Precio base</th>
                                                            <th>Descripción</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.lots.map((item, index) =>
                                                                <tr>
                                                                    <td>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={this.state.selectLots.filter(_item => _item.id === item.id).length > 0}
                                                                            onChange={() => this.setChecked(item)}
                                                                            disabled={item.status === 3 || item.invoice_date}
                                                                        /></td>
                                                                    <th scope="row">Lote {item.id_lot}</th>
                                                                    <th >{item.remision_str}</th>
                                                                    <td>{item.title}</td>
                                                                    <td>{item.quantity}</td>
                                                                    <td>{item.price_base}</td>
                                                                    <td>{item.description}</td>
                                                                    <td>
                                                                        {
                                                                            item.status === 3 ? <span className="badge badge-success">Entregado</span> : null
                                                                        }
                                                                        {
                                                                            item.invoice_date ? <span className="badge badge-success">Facturado</span> : null
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <PaginationCustom page={this.state.page} pages={this.state.pages}
                                                onPrev={this.onPrev} onNext={this.onNext} onPage={this.onPage} />
                                            <FormGroup className="mb-0 text-right" style={{ marginTop: '1rem' }}>
                                                <div>
                                                    <Button onClick={() => this.close()} type="reset" color="secondary">
                                                        Cerrar
                                                    </Button>
                                                    <Button onClick={() => this.importLots()} color="primary" className="ml-2">
                                                        Confirmar
                                                    </Button>
                                                </div>
                                            </FormGroup>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalImportLotsFromAuction);