import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
// Form Editor
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { getHead, updateHead } from '../../services/PageFaqServices';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { logoutAction } from '../../redux/actions/UserActions';
import HelpPageFAQ from './HelpPageFAQ';

class HelpPage extends Component {
    state = {
        breadcrumbItems: [
            { title: "Paginas dinamicas", link: "#" },
            { title: '', link: "#" }
        ],
        description: EditorState.createEmpty(),
        loading: false,
        type: parseInt(this.props.match.params.type),
        title: ''
    }

    componentDidMount = async () => {
        this.init();
    }

    componentWillReceiveProps = async (nextProps) => {
        if (nextProps.match.params.type !== this.props.match.params.type) {
            await this.setState({
                type: parseInt(nextProps.match.params.type)
            })
            this.init();
        }
    }

    init = async () => {
        let title = '';
        switch (this.state.type) {
            case 1:
                title = 'Comprar';
                break;
            case 2:
                title = 'Vender';
                break;
            case 3:
                title = 'Forma de pago';
                break
            case 4:
                title = 'FAQ';
                break;
        }
        await this.setState({
            breadcrumbItems: [
                { title: "Paginas dinamicas", link: "#" },
                { title: title, link: "#" }
            ],
            title: title
        });
        toast.configure();
        this.fetchPage();
    }

    fetchPage = async () => {
        try {
            await this.setState({ loading: true });
            let response = await getHead({
                token: this.props.sessionProps.account.token,
                type: this.state.type
            });
            let { dynamic_seccion } = response.data.data.dynamicSection;
            await this.setState({
                description: EditorState.createWithContent(stateFromHTML(dynamic_seccion)),
                loading: false
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    save = async () => {
        await this.setState({ loading: true });
        try {
            let response = await updateHead({
                token: this.props.sessionProps.account.token,
                dynamicSection: stateToHTML(this.state.description.getCurrentContent()),
                type: this.state.type
            });
            await this.setState({ loading: false });
            toast(`Seccíon guardada con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    onEditorStateChange = (description) => {
        this.setState({
            description: description
        });
    };

    render() {
        return (
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={this.state.title} breadcrumbItems={this.state.breadcrumbItems} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <h5 style={{ marginBottom: '1rem' }}>Encabezado</h5>
                                    <FormGroup>
                                        <Editor
                                            editorState={this.state.description}
                                            onEditorStateChange={this.onEditorStateChange}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            toolbar={{
                                                options: ['inline', 'blockType', 'link', 'emoji', 'image'],
                                                inline: { inDropdown: true },
                                                link: { inDropdown: true },
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup className="mb-0 text-right">
                                        <div>
                                            <Button type="button" onClick={this.save} color="primary" className="ml-2">
                                                Guardar
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <HelpPageFAQ {...this.props} type={this.state.type} />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
})

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HelpPage);