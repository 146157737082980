import React, { Component } from 'react';
import './ModalStatusRemision.scss';
import { Container, Row, Col, Card, CardBody, FormGroup, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { Constants } from '../../Constants';
import { getRemision } from '../../services/RemisionServices';
import { connect } from 'react-redux';
import Loading from '../Loading';

class ModalStatusRemision extends Component {
    state = {
        id: parseInt(this.props.id),
        title: '',
        lots: [],
        remision: '',
        id_remitter: '',
        loading: false
    };

    componentDidMount() {
        toast.configure();
        if (this.state.id !== 0) {
            this.fetchRemision();
        }
    }

    fetchRemision = async () => {
        await this.setState({ loading: true });
        try {
            let response = await getRemision({
                token: this.props.sessionProps.account.token,
                id: this.state.id
            });
            let { title, lots, remision, id_remitter } = response.data.data;
            await this.setState({
                title: title,
                lots: lots,
                remision: remision,
                id_remitter: id_remitter,
                loading: false
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    close = () => {
        this.props.close();
    }

    render() {
        return (
            <div className="container-modal">
                {this.state.loading && <Loading />}
                <div className="content-modal">
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div onClick={() => this.close()} style={{ textAlign: 'right', cursor: 'pointer' }}>
                                            X
                                        </div>
                                        <div>
                                            <h4>Estado remisión {this.state.id_remitter}/{this.state.remision} {this.state.title}</h4>
                                        </div>
                                        <div className="table-responsive" style={{ marginTop: '1rem' }}>
                                            <Table className="mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Lote</th>
                                                        <th>Remate</th>
                                                        <th>Mejor oferta</th>
                                                        <th>Estado</th>
                                                        <th>Facturado</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.lots.map(item =>
                                                            <tr>
                                                                <th scope="row">{item.id_lot}</th>
                                                                <td>{item.title}</td>
                                                                <td>{item.moneySymbol} {item.bestOffer}</td>
                                                                <td>
                                                                    {item.status == Constants.STATUS_INITIAL && <div className="badge badge-soft-info font-size-12">Pendiente</div>}
                                                                    {item.status == Constants.STATUS_PENDING && <div className="badge badge-soft-warning font-size-12">Subastado</div>}
                                                                    {item.status == Constants.STATUS_IN_PROGRESS && <div className="badge badge-soft-info font-size-12">En proceso</div>}
                                                                    {item.status == Constants.STATUS_NOT_AUCTIONED && <div className="badge badge-soft-secondary font-size-12">No subastado</div>}
                                                                    {item.status == Constants.STATUS_DELIVERED && <div className="badge badge-soft-success font-size-12">Entregado</div>}
                                                                </td>
                                                                <td>{item.id_invoice === null ? '-' : <div className="badge badge-soft-danger font-size-12">Facturado</div>}</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        <FormGroup className="mb-0 text-right" style={{ marginTop: '1rem' }}>
                                            <div>
                                                <button onClick={() => this.close()} type="button" className="btn btn-secondary">Cerrar</button>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
});

export default connect(mapStateToProps, null)(ModalStatusRemision);