import React, { Component } from 'react';
import './ModalLiquidPendingRemitter.scss';
import { Container, Row, Col, Card, CardBody, FormGroup, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { Constants } from '../../Constants';
import { connect } from 'react-redux';
import Loading from '../Loading';
import { calculateTotalsLiquid, getLotsForLiquidate } from '../../services/LotServices';

class ModalLiquidPendingRemitter extends Component {
    state = {
        id: parseInt(this.props.id),
        lots: [],
        loading: false,
        totalToPay: 0,
        moneySymbol: '$'
    };

    componentDidMount() {
        toast.configure();
        if (this.state.id !== 0) {
            this.fetchLots();
        }
    }

    fetchLots = async () => {
        await this.setState({ loading: true });
        try {
            let response = await getLotsForLiquidate({
                token: this.props.sessionProps.account.token,
                customerId: this.state.id
            });
            await this.setState({
                lots: response.data.data.lots,
                loading: false
            });
            this.calculateTotals()
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    calculateTotals = async () => {
        try {
            let response = await calculateTotalsLiquid({
                user: this.state.id,
                token: this.props.sessionProps.account.token,
                lots: this.state.lots
            });
            await this.setState({
                ...this.state,
                totalToPay: response.data.data.totalToPay,
                moneySymbol: this.state.lots.length > 0 ? this.state.lots[0].auction.moneySymbol : '$'
            });
        } catch (error) {
            console.log(error)
        }
    }

    close = () => {
        this.props.close();
    }

    render() {
        return (
            <div className="container-modal">
                {this.state.loading && <Loading />}
                <div className="content-modal">
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div onClick={() => this.close()} style={{ textAlign: 'right', cursor: 'pointer' }}>
                                            X
                                        </div>
                                        <div>
                                            <h4>Lotes del cliente {this.props.title}</h4>
                                            <h6>Total a cobrar: {this.state.moneySymbol} {this.state.totalToPay}</h6>
                                        </div>
                                        <div className="table-responsive" style={{ marginTop: '1rem' }}>
                                            <Table className="mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Lote</th>
                                                        <th>Título</th>
                                                        <th>Remate</th>
                                                        <th>Mejor oferta</th>
                                                        <th>Estado</th>
                                                        <th>Facturado</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.lots.map(item =>
                                                            <tr>
                                                                <th scope="row">{item.id_lot}</th>
                                                                <td>{item.remision?.title}</td>
                                                                <td>{item.auction?.title}</td>
                                                                <td>{item.auction?.moneySymbol} {item.offer}</td>
                                                                <td>
                                                                    {item.status == Constants.STATUS_INITIAL && <div className="badge badge-soft-info font-size-12">Pendiente</div>}
                                                                    {item.status == Constants.STATUS_PENDING && <div className="badge badge-soft-warning font-size-12">Subastado</div>}
                                                                    {item.status == Constants.STATUS_IN_PROGRESS && <div className="badge badge-soft-info font-size-12">En proceso</div>}
                                                                    {item.status == Constants.STATUS_NOT_AUCTIONED && <div className="badge badge-soft-secondary font-size-12">No subastado</div>}
                                                                    {item.status == Constants.STATUS_DELIVERED && <div className="badge badge-soft-success font-size-12">Entregado</div>}
                                                                </td>
                                                                <td>{item.id_invoice === null ? '-' : <div className="badge badge-soft-danger font-size-12">Facturado</div>}</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        <FormGroup className="mb-0 text-right" style={{ marginTop: '1rem' }}>
                                            <div>
                                                <button onClick={() => this.close()} type="button" className="btn btn-secondary">Cerrar</button>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
});

export default connect(mapStateToProps, null)(ModalLiquidPendingRemitter);