import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Table } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Select from 'react-select';
import { customersListAutocomplete, addSign, getSign } from '../../services/UserServices';
import { connect } from 'react-redux';
import { getLotsForDelivery, deliveryLots, calculateTotals, invoiceLots } from '../../services/LotServices';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import { logoutAction } from '../../redux/actions/UserActions';
import { auctionListAutocomplete, downloadPdfsDeliveryLots, auctionGetMoneys } from '../../services/AuctionServices';
import './LotsDeliver.scss';
import { confirmAlert } from 'react-confirm-alert';
import { Constants } from '../../Constants';
import { Link } from 'react-router-dom';

class LotsDeliver extends Component {
    state = {
        breadcrumbItems: [
            { title: "Lotes", link: "#" },
            { title: 'Entregar', link: "#" }
        ],
        customers: [],
        lots: [],
        userId: null,
        userSelect: { value: null, label: 'Seleccionar cliente a buscar' },
        loading: false,
        checkAll: false,
        auctions: [],
        auctionSelect: { value: null, label: 'Seleccionar filtro de remate' },
        totals: {
            total: 0,
            comision: 0,
            iva: 0,
            totalToPay: 0,
            totalUYU: 0,
            comisionUYU: 0,
            ivaUYU: 0,
            totalToPayUYU: 0,
            sign: 0,
            signUYU: 0
        },
        sign: null,
        signMoney: null,
        moneys: []
    }

    componentDidMount = () => {
        toast.configure();
        this.fetchCustomers();
        this.fetchAuctions();
        this.fetchMoneys();
    }

    fetchMoneys = async () => {
        await this.setState({ loading: true });
        try {
            let response = await auctionGetMoneys({
                token: this.props.sessionProps.account.token,
                require_all: true
            });
            await this.setState({
                loading: false,
                moneys: response.data.data,
                signMoney: response.data.data.length > 0 ? response.data.data[0] : null
            })
        } catch (error) {
            await this.setState({ loading: false });
        }
    }

    fetchCustomers = async () => {
        try {
            let response = await customersListAutocomplete({
                token: this.props.sessionProps.account.token
            });
            await this.setState({
                customers: response.data.data.customers
            });
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    fetchAuctions = async () => {
        try {
            let data = await auctionListAutocomplete({
                token: this.props.sessionProps.account.token,
                all: true
            });
            await this.setState({
                auctions: [...data.data.data.auctions]
            });
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    loadLots = async (userId, e = null) => {
        this.initTotals();
        let data = {
            loading: true,
            userId: userId
        }
        if (e !== null) {
            data.auctionSelect = { value: null, label: 'Seleccionar filtro de remate' };
        }
        await this.setState(data);
        try {
            let response = await getLotsForDelivery({
                token: this.props.sessionProps.account.token,
                userId: userId,
                auction: this.state.auctionSelect.value !== null ? this.state.auctionSelect.value.id : null
            });
            let lots = response.data.data.lots;
            lots.map(item => item.checked = false);
            await this.setState({
                lots: response.data.data.lots,
                userId: userId,
                loading: false,
                userSelect: e !== null ? e : this.state.userSelect,
                checkAll: false
            });
            this.fetchSign();
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    deliveryLots = () => {
        if (this.state.lots.filter(item => item.checked === true).length === 0) {
            toast.error(`Debe seleccionar lotes a entregar.`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            return;
        }
        confirmAlert({
            title: 'Entregar lotes',
            message: `¿Esta seguro que desea entregar los lotes seleccionados?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._deliveryLots()
                    }
                }
            ]
        });
    }

    _deliveryLots = async () => {
        await this.setState({ loading: true });
        try {
            let response = await deliveryLots({
                token: this.props.sessionProps.account.token,
                lots: this.state.lots.filter(item => item.checked === true),
                user: this.state.userId,
            });
            toast(`Lotes entregados con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            await this.setState({ loading: false });
            this.downloadPdfs(false);
            this.loadLots(this.state.userId);
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    setChecked = async (index) => {
        let lots = this.state.lots;
        lots[index].checked = !this.state.lots[index].checked;
        await this.setState({
            lots: lots
        });
        if (this.state.lots.filter(item => item.checked === true).length === 0) {
            this.initTotals();
        }
        else {
            this.calculateTotals();
        }
    }

    setCheckedAll = async () => {
        let lots = this.state.lots;
        lots.map(item => item.checked = !this.state.checkAll);
        await this.setState({
            checkAll: !this.state.checkAll,
            lots: lots
        });
        if (this.state.lots.filter(item => item.checked === true).length === 0) {
            this.initTotals();
        }
        else {
            this.calculateTotals();
        }
    }

    initTotals = async () => {
        await this.setState({
            totals: {
                total: 0,
                comision: 0,
                iva: 0,
                totalToPay: 0,
                totalUYU: 0,
                comisionUYU: 0,
                ivaUYU: 0,
                totalToPayUYU: 0,
                sign: 0,
                signUYU: 0
            }
        });
    }

    calculateTotals = async () => {
        try {
            let response = await calculateTotals({
                user: this.state.userId,
                auction: this.state.auctionSelect.value.id,
                token: this.props.sessionProps.account.token,
                lots: this.state.lots.filter(item => item.checked === true)
            });
            await this.setState({
                ...this.state,
                totals: {
                    total: response.data.data.total,
                    comision: response.data.data.comision,
                    iva: response.data.data.iva,
                    totalToPay: response.data.data.totalToPay,
                    totalUYU: response.data.data.totalUYU,
                    comisionUYU: response.data.data.comisionUYU,
                    ivaUYU: response.data.data.ivaUYU,
                    totalToPayUYU: response.data.data.totalToPayUYU,
                    sign: response.data.data.sign,
                    signUYU: response.data.data.signUYU
                }
            });
        } catch (error) {
        }
    }

    handleSelectChange = async (selectedOption) => {
        this.initTotals();
        if (selectedOption.value === null) {
            await this.setState({
                auctionSelect: selectedOption
            });
            this.loadLots(this.state.userSelect.value);
            return;
        }
        let signMoney = this.state.signMoney;
        this.state.moneys.map(item => { if (item.value === selectedOption.value.money) { signMoney = item } });
        await this.setState({
            auctionSelect: selectedOption,
            signMoney: signMoney,
            lots: [],
            checkAll: false
        });
        this.loadLots(this.state.userSelect.value);
    };

    _downloadPdfs = () => {
        confirmAlert({
            title: 'Descargar PDFs de compradores',
            message: `¿Esta seguro que desea descargar los PDFs de los compradores?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this.downloadPdfs()
                    }
                }
            ]
        });
    }

    downloadPdfs = async (showTotals = true) => {
        if (this.state.lots.filter(item => item.checked === true).length === 0) {
            toast.error(`Debe seleccionar los lotes a descargar.`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            return;
        }
        await this.setState({ loading: true });
        try {
            let response = await downloadPdfsDeliveryLots({
                usersId: [this.state.userId],
                token: this.props.sessionProps.account.token,
                lots: this.state.lots.filter(item => item.checked === true),
                showTotals: showTotals
            });
            let link = document.createElement('a');
            link.href = response.data.data.url;
            link.download = `entrega_lotes_${this.state.userId}.pdf`;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            await this.setState({ loading: false });
        } catch (error) {
            await this.setState({ loading: false });
            let msg = 'Ocurrió un error al procesar la solicitud, reintentar.';
            if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                msg = error.response.data[0] || error.response.data.message;
            }
            toast.error(msg, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    addSign = async () => {
        confirmAlert({
            title: 'Agregar seña a cliente',
            message: `¿Esta seguro que desea agregar ${this.state.signMoney.label} ${this.state.sign} de seña al cliente seleccionado?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._addSign()
                    }
                }
            ]
        });
    }

    _addSign = async () => {
        await this.setState({ loading: true });
        try {
            let response = await addSign({
                userId: this.state.userId,
                auctionId: this.state.auctionSelect.value.id,
                sign: this.state.sign === '' ? 0 : this.state.sign,
                signMoney: this.state.signMoney.value,
                token: this.props.sessionProps.account.token,
                lots: this.state.lots.filter(item => item.checked === true)
            });
            await this.setState({ loading: false });
            toast(`Seña ingresada con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            this.calculateTotals();
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                let msg = 'Ocurrió un error al procesar la solicitud, reintentar.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    fetchSign = async () => {
        try {
            await this.setState({ sign: '' });
            if (this.state.userId !== null && this.state.auctionSelect.value !== null) {
                let response = await getSign({
                    userId: this.state.userId,
                    auctionId: this.state.auctionSelect.value.id,
                    token: this.props.sessionProps.account.token
                });
                if (response.data.data.sign !== null && response.data.data.sign.sign !== 0) {
                    let signMoney = this.state.signMoney;
                    this.state.moneys.map(item => { if (item.value === response.data.data.sign.money) { signMoney = item } });
                    await this.setState({
                        sign: response.data.data.sign.sign,
                        signMoney: signMoney
                    });
                }
            }
        } catch (error) {

        }
    }

    invoiceLots = () => {
        if (this.state.lots.filter(item => item.checked === true).length === 0) {
            toast.error(`Debe seleccionar lotes a facturar.`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            return;
        }
        confirmAlert({
            title: 'Facturar lotes',
            message: `¿Esta seguro que desea facturar los lotes seleccionados?`,
            buttons: [
                {
                    label: 'Cancelar',
                    id: 'cancel'
                },
                /*{
                    label: 'Facturar Cuenta #1'
                },
                {
                    label: 'Facturar Cuenta #2'
                },*/
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._invoiceLots()
                    }
                },
            ]
        });
    }

    invoiceAndDeliveryLots = () => {
        if (this.state.lots.filter(item => item.checked === true).length === 0) {
            toast.error(`Debe seleccionar lotes a facturar y entregar.`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            return;
        }
        confirmAlert({
            title: 'Facturar y entregar lotes',
            message: `¿Esta seguro que desea facturar y entregar los lotes seleccionados?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._invoiceLots(true)
                    }
                }
            ]
        });
    }

    _invoiceLots = async (invoiceAndDelivery = false) => {
        await this.setState({ loading: true });
        try {
            let response = await invoiceLots({
                token: this.props.sessionProps.account.token,
                lots: this.state.lots.filter(item => item.checked === true),
                user: this.state.userId,
                invoiceAndDelivery: invoiceAndDelivery
            });
            toast(`Lotes facturados ${invoiceAndDelivery ? 'y entregados' : ''} con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            await this.setState({ loading: false });
            this.downloadPdfs();
            this.loadLots(this.state.userId);
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                let msg = 'Ocurrió un error al procesar la solicitud, reintentar.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    render() {
        return (
            <div>
                <div className="page-content">
                    {this.state.loading && <Loading />}
                    <Container fluid>
                        <Breadcrumbs title="Entregar lotes" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Cliente</Label>
                                            <Col md={10}>
                                                <Select
                                                    options={this.state.customers.map(item => ({ value: item.id_user, label: item.name }))}
                                                    className="select2 select2-multiple"
                                                    placeholder="Seleccionar cliente a buscar"
                                                    onChange={(e) => this.loadLots(e.value, e)}
                                                    value={this.state.userSelect}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Remate</Label>
                                            <Col md={10}>
                                                <Select
                                                    options={[
                                                        { value: null, label: 'Seleccionar filtro de remate' },
                                                        ...this.state.auctions.map(item => ({ value: item, label: item.title }))
                                                    ]}
                                                    className="select2 select2-multiple"
                                                    placeholder="Seleccionar filtro de remate"
                                                    value={this.state.auctionSelect}
                                                    onChange={this.handleSelectChange}
                                                    isDisabled={this.state.userSelect.value === null}
                                                />
                                            </Col>
                                        </FormGroup>
                                        {
                                            this.state.userSelect !== null && this.state.auctionSelect.value !== null &&
                                            <FormGroup row>
                                                <Label className="col-md-2 col-form-label">Seña a cuenta</Label>
                                                <Col md={5}>
                                                    <input type="number" className="form-control" placeholder="Ingrese ingrese la seña recibida"
                                                        name="sign" value={this.state.sign} onChange={this.handleChange} />
                                                </Col>
                                                <Col md={5} style={{ display: 'flex' }}>
                                                    <Select
                                                        name="money"
                                                        className="select2 select2-multiple w-100"
                                                        placeholder="Seleccionar moneda"
                                                        options={this.state.moneys}
                                                        value={this.state.signMoney}
                                                        onChange={(sl) => this.setState({ signMoney: sl })}
                                                    />
                                                    <Link onClick={() => this.addSign()} className="ml-1 text-danger">
                                                        <i className="mdi mdi-check-circle-outline font-size-24"
                                                            style={{ color: 'green', marginTop: '0.1rem' }}></i>
                                                    </Link>
                                                </Col>
                                            </FormGroup>
                                        }

                                        <div className="table-responsive">
                                            <Table className="mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            {
                                                                this.state.lots.length > 0 &&
                                                                <input type="checkbox" checked={this.state.checkAll} onChange={() => this.setCheckedAll()} />
                                                            }
                                                        </th>
                                                        <th>Lote</th>
                                                        <th>Nombre</th>
                                                        <th>Remate</th>
                                                        <th>Precio subastado</th>
                                                        <th>Estado</th>
                                                        <th>Facturado</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.lots.map((item, index) =>
                                                            <tr>
                                                                <td><input type="checkbox" checked={item.checked} onChange={() => this.setChecked(index)} /></td>
                                                                <th scope="row">{item.id_lot}</th>
                                                                <td>{item.remision.title}</td>
                                                                <td>{item.auction.title}</td>
                                                                <td>{item.auction.moneySymbol} {item.offer}</td>
                                                                <td>
                                                                    {item.status == Constants.STATUS_PENDING && <div className="badge badge-soft-warning font-size-12">Subastado</div>}
                                                                    {item.status == Constants.STATUS_DELIVERED && <div className="badge badge-soft-success font-size-12">Entregado</div>}
                                                                </td>
                                                                <td>{item.id_invoice !== null ?
                                                                    <div className="badge badge-soft-danger font-size-12">Facturado</div> /*<a>{item.id_invoice}</a>*/
                                                                    : '-'}</td>
                                                                <td>
                                                                    <a href={`/remisiones/${item.id_remision}`} target="_blank" className="mr-3 text-primary"><i className="mdi mdi-layers-search font-size-22"></i></a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        {
                                            this.state.auctionSelect.value !== null &&
                                            <div className="row" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                                                <div className="col-12 text-right" style={{ display: 'inline-flex', justifyContent: 'flex-end' }}>
                                                    <div>
                                                        <h6>Sub total</h6>
                                                        <h6>Comisión</h6>
                                                        <h6>IVA</h6>
                                                        <h6>Seña</h6>
                                                        <h5>Total a pagar</h5>
                                                    </div>
                                                    <div style={{ marginLeft: '0.5rem' }}>
                                                        <h6>{this.state.auctionSelect.value.moneySymbol}</h6>
                                                        <h6>{this.state.auctionSelect.value.moneySymbol}</h6>
                                                        <h6>{this.state.auctionSelect.value.moneySymbol}</h6>
                                                        <h6>{this.state.auctionSelect.value.moneySymbol}</h6>
                                                        <h5>{this.state.auctionSelect.value.moneySymbol}</h5>
                                                    </div>
                                                    <div className="text-right" style={{ marginLeft: '0.2rem' }}>
                                                        <h6>{this.state.totals.total}</h6>
                                                        <h6>{this.state.totals.comision}</h6>
                                                        <h6>{this.state.totals.iva}</h6>
                                                        <h6>-{this.state.totals.sign}</h6>
                                                        <h5>{this.state.totals.totalToPay}</h5>
                                                    </div>
                                                    {
                                                        this.state.auctionSelect.value.money === Constants.MONEY_USD &&
                                                        <React.Fragment>
                                                            <div style={{ marginLeft: '0.2rem' }}>
                                                                <h6> - {this.state.auctionSelect.value.moneySymbol2}</h6>
                                                                <h6> - {this.state.auctionSelect.value.moneySymbol2}</h6>
                                                                <h6> - {this.state.auctionSelect.value.moneySymbol2}</h6>
                                                                <h6> - {this.state.auctionSelect.value.moneySymbol2}</h6>
                                                                <h5> - {this.state.auctionSelect.value.moneySymbol2}</h5>
                                                            </div>
                                                            <div className="text-right" style={{ marginLeft: '0.2rem' }}>
                                                                <h6>{this.state.totals.totalUYU}</h6>
                                                                <h6>{this.state.totals.comisionUYU}</h6>
                                                                <h6>{this.state.totals.ivaUYU}</h6>
                                                                <h6>-{this.state.totals.signUYU}</h6>
                                                                <h5>{this.state.totals.totalToPayUYU}</h5>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <FormGroup className="mb-0 text-right" style={{ marginTop: '2rem' }}>
                                            <div>
                                                {
                                                    this.state.lots.filter(item => item.checked).filter(item => item.status === Constants.STATUS_DELIVERED).length === 0 &&
                                                    this.state.lots.filter(item => item.checked).filter(item => item.id_invoice !== null).length === 0 &&
                                                    <Button type="button" color="warning" className="ml-2" onClick={this.invoiceAndDeliveryLots}>
                                                        <i className="mdi mdi-check-box-outline"></i> Fact. y Entregar
                                                    </Button>
                                                }
                                                {
                                                    this.state.lots.filter(item => item.checked).filter(item => item.status === Constants.STATUS_DELIVERED).length === 0 &&
                                                    <Button type="button" color="success" className="ml-2" onClick={this.deliveryLots}>
                                                        <i className="mdi mdi-check-box-outline"></i> Entregar
                                                    </Button>
                                                }
                                                {
                                                    this.state.lots.filter(item => item.checked).filter(item => item.id_invoice !== null).length === 0 &&
                                                    <Button type="button" color="danger" className="ml-2" onClick={this.invoiceLots}>
                                                        <i className="mdi mdi-receipt"></i> Facturar
                                                    </Button>
                                                }
                                                <Button type="button" color="secondary" className="ml-2" onClick={this._downloadPdfs}>
                                                    <i className="mdi mdi-pdf-box"></i> Reporte
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LotsDeliver);