import React, { Component } from 'react';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Container, Row, Col, Card, CardBody, Table, Nav, NavItem, NavLink } from 'reactstrap';
import './InvoiceList.scss';
import PaginationCustom from '../../components/pagination/Pagination';
import { Link } from 'react-router-dom';
import { getInvoices, downloadInvoicePdf, downloadInvoicePdfLiquidation } from '../../services/BillingServices';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { logoutAction } from '../../redux/actions/UserActions';

class InvoiceList extends Component {
    state = {
        breadcrumbItems: [
            { title: "Facturación", link: "#" },
            { title: "Listado", link: "#" }
        ],
        invoices: [],
        activeTab: '0',
        page: 1,
        pages: 0,
    }

    componentDidMount = () => {
        toast.configure();
        this.fetchInvoices();
    }

    fetchInvoices = async (loading = true) => {
        loading && await this.setState({ loading: true });
        try {
            let response = await getInvoices({
                token: this.props.sessionProps.account.token,
                page: this.state.page,
                type: this.state.activeTab
            });
            await this.setState({
                pages: response.data.data.pages,
                invoices: response.data.data.invoices,
                loading: false
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    toggleTab = async (tab) => {
        if (this.state.activeTab !== tab) {
            await this.setState({
                activeTab: tab
            });
            this.fetchInvoices();
        }
    }

    print = async (id) => {
        await this.setState({ loading: true });
        try {
            let response = await downloadInvoicePdf({
                token: this.props.sessionProps.account.token,
                invoiceId: id,
            });
            let link = document.createElement('a');
            link.href = response.data.data.pdf;
            link.download = `factura_electronica_1.pdf`;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            await this.setState({ loading: false });
        } catch (error) {
            await this.setState({ loading: false });
        }
    }

    printWithLiquidationData = async (id) => {
        await this.setState({ loading: true });
        try {
            let response = await downloadInvoicePdfLiquidation({
                token: this.props.sessionProps.account.token,
                invoiceId: id,
            });
            let link = document.createElement('a');
            link.href = response.data.data.url;
            link.download = `factura_electronica_1.pdf`;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            await this.setState({ loading: false });
        } catch (error) {
            await this.setState({ loading: false });
        }
    }

    onPrev = async () => {
        await this.setState({ page: this.state.page - 1 });
        this.fetchInvoices();
    }

    onNext = async () => {
        await this.setState({ page: this.state.page + 1 });
        this.fetchInvoices();
    }

    onPage = async (page) => {
        await this.setState({ page: page });
        this.fetchInvoices();
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    {this.state.loading && <Loading />}
                    <Container fluid>
                        <Breadcrumbs title="Listado facturas" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody className="without-padding">
                                        <Nav tabs className="nav-tabs-custom mb-4">
                                            <NavItem>
                                                <NavLink onClick={() => { this.toggleTab('0'); }} className={`${this.state.activeTab === '0' && 'active'} font-weight-bold p-3`}>Todas</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink onClick={() => { this.toggleTab('1'); }} className={`${this.state.activeTab === '1' && 'active'} font-weight-bold p-3`}>Compradores</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink onClick={() => { this.toggleTab('2'); }} className={`${this.state.activeTab === '2' && 'active'} p-3 font-weight-bold`}>Remitentes</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink onClick={() => { this.toggleTab('3'); }} className={`${this.state.activeTab === '3' && 'active'} p-3 font-weight-bold`}>Manuales</NavLink>
                                            </NavItem>
                                        </Nav>
                                        <div className="table-responsive">
                                            <Table className="mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Nombre</th>
                                                        <th>Fecha</th>
                                                        <th>Número</th>
                                                        <th>Estado</th>
                                                        <th width="10%">Imprimir</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.invoices.map(item =>
                                                            <tr style={{ color: `${item.have_credit_note ? 'red' : '#505d69'}` }}>
                                                                <th scope="row">{item.id} {item.have_credit_note ? 'Anulada' : ''}</th>
                                                                <td>{item.customer_name}</td>
                                                                <td>{item.date}</td>
                                                                <td>{item.serie} {item.number}</td>
                                                                <td>{item.error ? 'Error' : 'Éxito'}</td>
                                                                <td>
                                                                    <Link onClick={() => this.print(item.id)} className="text-success"><i className="mdi mdi-cloud-print font-size-18"></i></Link>
                                                                    <Link onClick={() => this.printWithLiquidationData(item.id)} className="text-info" style={{ marginLeft: '0.5rem' }}>
                                                                        <i className="mdi mdi-printer font-size-18"></i>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        <PaginationCustom page={this.state.page} pages={this.state.pages}
                                            onPrev={this.onPrev} onNext={this.onNext} onPage={this.onPage} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);