import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardBody, Input, Table, Button, FormGroup } from 'reactstrap';
import { getRemisions } from '../../services/RemisionServices';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { customersListAutocomplete } from '../../services/UserServices';
import Loading from '../Loading';
import { logoutAction } from '../../redux/actions/UserActions';
import PaginationCustom from '../pagination/Pagination';
import './ModalAddMultipleRemision.scss';

class ModalAddMultipleRemision extends Component {
    state = {
        remisions: [],
        page: 1,
        pages: 0,
        customers: [],
        customerSelect: { value: null, label: 'Filtrar por remitente' },
        search: '',
        selectRemisions: []
    }

    componentDidMount = () => {
        toast.configure();
        this.fetchRemisions();
        this.fetchCustomers();
    }

    fetchRemisions = async (showSpinner = true) => {
        showSpinner && await this.setState({ loading: true });
        try {
            let response = await getRemisions({
                token: this.props.sessionProps.account.token,
                page: this.state.page,
                remitter: this.state.customerSelect.value,
                search: encodeURIComponent(this.state.search),
                filter: 'onlyunsold'
            });
            let remisions = response.data.data.remisions;
            for (let remision of remisions) {
                let temporal = document.createElement("div");
                temporal.innerHTML = remision.description;
                remision.description = temporal.textContent || temporal.innerText || "";
            }
            await this.setState({
                pages: response.data.data.pages,
                remisions: remisions,
                loading: false
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    onPrev = async () => {
        await this.setState({ page: this.state.page - 1 });
        this.fetchRemisions();
    }

    onNext = async () => {
        await this.setState({ page: this.state.page + 1 });
        this.fetchRemisions();
    }

    onPage = async (page) => {
        await this.setState({ page: page });
        this.fetchRemisions();
    }

    fetchCustomers = async () => {
        try {
            let response = await customersListAutocomplete({
                token: this.props.sessionProps.account.token,
                onlyRemitter: true
            });
            await this.setState({
                customers: response.data.data.customers
            });
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    handleChangeSearch = async (event) => {
        const { name, value } = event.target;
        await this.setState({
            [name]: value,
            page: 1
        });
        this.fetchRemisions(false);
    }

    handleSelectChange = async (selectedOption) => {
        await this.setState({ customerSelect: selectedOption, page: 1 });
        this.fetchRemisions();
    };

    close = () => {
        this.props.close();
    }

    lotRemisions = (remision) => {
        this.props.lotMultipleRemision(remision);
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading && <Loading />}
                <div className="container-modal">
                    <div className="content-modal">
                        <Container>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <div className="row">
                                                <div className="col-10">
                                                    <h5 style={{ marginBottom: 0 }}>Loteo de remisiones</h5>
                                                </div>
                                                <div className="col-2" onClick={() => this.close()} style={{ textAlign: 'right', cursor: 'pointer' }}>
                                                    <h5 style={{ marginBottom: 0 }}>X</h5>
                                                </div>
                                            </div>
                                            <hr style={{ marginTop: '0.5rem' }} />
                                            <div class="row" style={{ marginBottom: '1rem' }}>
                                                <div className="col-12 col-md-6">
                                                    <Select
                                                        className="select2 select2-multiple"
                                                        placeholder="Seleccionar filtro de remitente"
                                                        options={this.state.customers.map(item => ({ value: item.id, label: `${item.id} - ${item.name}` }))}
                                                        value={this.state.customerSelect}
                                                        onChange={this.handleSelectChange}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="form-inline">
                                                        <div className="search-box" style={{ width: '100%' }}>
                                                            <div className="position-relative">
                                                                <Input type="text" name="search" className="form-control rounded" placeholder="Buscar #, nombre o descripción"
                                                                    value={this.state.search} onChange={this.handleChangeSearch} style={{ width: '100%' }} />
                                                                <i className="mdi mdi-magnify search-icon" ></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ paddingTop: '.5rem' }}>
                                                <div className="col-12 text-center">
                                                    <PaginationCustom page={this.state.page} pages={this.state.pages}
                                                        onPrev={this.onPrev} onNext={this.onNext} onPage={this.onPage} />
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <Table className="mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Remisión</th>
                                                            <th>Nombre</th>
                                                            <th>Descripción</th>
                                                            <th>Remitente</th>
                                                            <th width="10%">Precio base</th>
                                                            <th width="10%">Cantidad</th>
                                                            <th>Cant. disponibles</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.remisions.map((item, index) =>
                                                                <tr>
                                                                    <th scope="row">{item.id_remitter}/{item.remision}</th>
                                                                    <td>{item.title}</td>
                                                                    <td>{item.description}</td>
                                                                    <td>{item.remitter?.name}</td>
                                                                    <td>{item.price_base}</td>
                                                                    <td>{item.quantity}</td>
                                                                    <td>{item.quantityunsold}</td>
                                                                    <td>
                                                                        <Button onClick={() => this.lotRemisions(`${item.id_remitter}/${item.remision}`)} color="primary" className="ml-2">
                                                                            Lotear
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <PaginationCustom page={this.state.page} pages={this.state.pages}
                                                onPrev={this.onPrev} onNext={this.onNext} onPage={this.onPage} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddMultipleRemision);