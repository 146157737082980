import React, { Component } from 'react';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Input, Button } from 'reactstrap';
import './InvoiceConfig.scss';
import Select from 'react-select';
import { connect } from 'react-redux';
import { getInvoiceConfigs, putInvoiceConfigs } from '../../services/BillingServices';
import { toast } from 'react-toastify';

class InvoiceConfig extends Component {
    state = {
        breadcrumbItems: [
            { title: "Facturación", link: "#" },
            { title: "Configuración", link: "#" }
        ],
        user: '',
        password: '',
        sucursal: '',
        format_printer: { value: 'a4', label: 'A4' },
        razon_social: '',
        address: '',
        phone: '',
        email: '',
        info_adicional: '',
        rut: ''
    }

    componentDidMount = () => {
        toast.configure();
        this.fetchConfigs();
    }

    fetchConfigs = async () => {
        await this.setState({ loading: true });
        try {
            let response = await getInvoiceConfigs({
                token: this.props.sessionProps.account.token,
            });
            await this.setState({
                loading: false,
                user: response.data.data.config.username,
                password: response.data.data.config.password,
                sucursal: response.data.data.config.sucursal,
                format_printer: response.data.data.config.printer === 'a4' ? { value: 'a4', label: 'A4' } : { value: 'ticket', label: 'Ticket' },
                razon_social: response.data.data.config.razon_social,
                address: response.data.data.config.address,
                phone: response.data.data.config.phone,
                email: response.data.data.config.email,
                info_adicional: response.data.data.config.info_adicional,
                rut: response.data.data.config.rut
            })
        } catch (error) {
            await this.setState({ loading: false });
        }
    }

    handleSelectChange = async (selectedOption, name) => {
        await this.setState({
            [name]: selectedOption
        });
    };

    handleChange = (event, auction = true) => {
        const { name, value } = event.target;
        this.setState({
            ...this.state,
            [name]: value
        });
    }

    save = async (e) => {
        e.preventDefault();
        await this.setState({ loading: true });
        try {
            let response = await putInvoiceConfigs({
                token: this.props.sessionProps.account.token,
                user: this.state.user,
                password: this.state.password,
                sucursal: this.state.sucursal,
                format_printer: this.state.format_printer.value,
                razon_social: this.state.razon_social,
                address: this.state.address,
                phone: this.state.phone,
                email: this.state.email,
                info_adicional: this.state.info_adicional,
                rut: this.state.rut
            });
            await this.setState({
                loading: false
            })
            toast(`Configuraciónes guardadas con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            await this.setState({ loading: false });
            let msg = 'Ocurrió un error, reintentar.';
            if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                msg = error.response.data.message;
            }
            toast.error(msg, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    {this.state.loading && <Loading />}
                    <Container fluid>
                        <Breadcrumbs title="Configuración facturación" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <form onSubmit={this.save}>
                                            <FormGroup>
                                                <Label>Usuario</Label>
                                                <Input name="user" className="form-control" type="text" placeholder="Ingrese el usuario"
                                                    value={this.state.user} onChange={this.handleChange} required />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Contraseña</Label>
                                                <Input name="password" className="form-control" type="text" placeholder="Ingrese la contraseña"
                                                    value={this.state.password} onChange={this.handleChange} required />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Sucursal</Label>
                                                <Input name="sucursal" className="form-control" type="text" placeholder="Ingrese la sucursal"
                                                    value={this.state.sucursal} onChange={this.handleChange} required />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Tamaño predeterminado impresión</Label>
                                                <Select
                                                    name="format_printer"
                                                    className="select2 select2-multiple"
                                                    options={[{ value: 'ticket', label: 'Ticket' }, { value: 'a4', label: 'A4' }]}
                                                    value={this.state.format_printer}
                                                    onChange={(e) => this.handleSelectChange(e, 'format_printer')}
                                                    required
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>RUT</Label>
                                                <Input name="rut" className="form-control" type="text" placeholder="Ingrese el RUT"
                                                    value={this.state.rut} onChange={this.handleChange} required />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Razón social</Label>
                                                <Input name="razon_social" className="form-control" type="text" placeholder="Ingrese la razón social"
                                                    value={this.state.razon_social} onChange={this.handleChange} required />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Dirección</Label>
                                                <Input name="address" className="form-control" type="text" placeholder="Ingrese la dirección"
                                                    value={this.state.address} onChange={this.handleChange} required />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Teléfono</Label>
                                                <Input name="phone" className="form-control" type="text" placeholder="Ingrese el teléfono"
                                                    value={this.state.phone} onChange={this.handleChange} required />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Email</Label>
                                                <Input name="email" className="form-control" type="text" placeholder="Ingrese el email"
                                                    value={this.state.email} onChange={this.handleChange} required />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Info adicional</Label>
                                                <Input name="info_adicional" className="form-control" type="text" placeholder="Ingrese la info adicional"
                                                    value={this.state.info_adicional} onChange={this.handleChange} required />
                                            </FormGroup>
                                            <FormGroup className="mb-0 text-right">
                                                <div>
                                                    <Button type="submit" color="primary" className="ml-2">
                                                        Guardar
                                                </Button>
                                                </div>
                                            </FormGroup>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
})

export default connect(mapStateToProps, null)(InvoiceConfig);