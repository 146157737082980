import React, { Component } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
// Import menuDropdown
import ProfileMenu from "./ProfileMenu";
import './Header.scss';
import { connect } from "react-redux";
import { Constants } from "../../Constants";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSearch: false,
            isSocialPf: false
        };
        document.body.classList.remove('sidebar-enable');
    }

    /**
     * Toggle sidebar
     */
    toggleMenu() {
        if (document.body.className !== '') {
            document.body.classList.remove('sidebar-enable');
        }
        else {
            document.body.classList.add('sidebar-enable');
        }
    }

    toggleFullscreen() {
        if (
            !document.fullscreenElement &&
            !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                );
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <header id="page-topbar">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <Button size="sm" color="none" type="button" onClick={this.toggleMenu}
                                className="px-3 font-size-24 header-item waves-effect order-md-2 order-1" id="vertical-menu-btn">
                                <i className="ri-menu-2-line align-middle"></i>
                            </Button>
                            <div className="order-md-1 order-2">
                                <div className="navbar-brand-box">
                                    <Link to="#" className="logo logo-light">
                                        <span className="logo">
                                            <img src={this.props.configAccount.config?.logo_admin ? Constants.BASE_URL + this.props.configAccount.config.logo_admin : ''}
                                                alt="" style={{ width: 'auto', height: '100%' }} />
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="dropdown d-none d-lg-inline-block ml-1">
                                <Button color="none" type="button" className="header-item noti-icon waves-effect" onClick={this.toggleFullscreen}>
                                    <i className="ri-fullscreen-line"></i>
                                </Button>
                            </div>
                            <ProfileMenu />
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    configAccount: state.accountReducer
})

export default connect(mapStateToProps, null)(Header);