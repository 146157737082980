import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import AuctionsList from "../pages/Auctions/AuctionsList";
import AuctionsAddEdit from "../pages/Auctions/AuctionsAddEdit";
import BannersList from "../pages/Banners/BannersList";
import CustomersList from "../pages/Customers/CustomersList";
import CustomersFrequent from "../pages/Customers/CustomersFrequent";
import HelpPage from "../pages/HelpPage/HelpPage";
import LotsDeliver from "../pages/Lots/LotsDeliver";
import HelpPageFAQ from "../pages/HelpPage/HelpPageFAQ";
import ActualAuctionLots from "../pages/Auctions/ActualAuctionLots";
import ActualAuctionLogs from "../pages/Auctions/ActualAuctionLogs";
import AuctionTypes from "../pages/Auctions/AuctionTypes";
import RemittersList from "../pages/Remitter/RemittersList";
import RemisionAddEdit from "../pages/Remisions/RemisionAddEdit";
import RemisionsList from "../pages/Remisions/RemisionsList";
import RemitterLiquid from "../pages/Remitter/RemitterLiquid";
import InvoiceList from "../pages/Invoices/InvoiceList";
import InvoiceConfig from "../pages/Invoices/InvoiceConfig";
import InvoiceAddManual from "../pages/Invoices/InvoiceAddManual";
import AuctionConfig from "../pages/Auctions/AuctionConfig";
import AccountConfig from "../pages/Account/AccountConfig";
import LiquidationsList from "../pages/Remitter/LiquidationsList";
import DeliverList from "../pages/Lots/DeliverList";

const privateRoutes = [
	{ path: "/dashboard", component: Dashboard },
	{ path: "/remates/actual/lotes", component: ActualAuctionLots },
	{ path: "/remates/actual/logs", component: ActualAuctionLogs },
	{ path: "/remates/tipos", component: AuctionTypes },
	{ path: "/remates/configs", component: AuctionConfig },
	{ path: "/remates/:id", component: AuctionsAddEdit },
	{ path: "/remates", component: AuctionsList },
	{ path: "/lotes/entregas", component: DeliverList },
	{ path: "/lotes/entregar", component: LotsDeliver },
	{ path: "/remisiones/:id", component: RemisionAddEdit },
	{ path: "/remisiones", component: RemisionsList },
	{ path: "/banners", component: BannersList },

	{ path: "/clientes/frecuentes", component: CustomersFrequent },
	{ path: "/clientes", component: CustomersList },
	{ path: "/remitentes", component: RemittersList },
	{ path: "/liquidar-remitentes", component: RemitterLiquid },
	{ path: "/liquidaciones", component: LiquidationsList },
	{ path: "/pagina/:type", component: HelpPage },

	{ path: "/facturas/listar", component: InvoiceList },
	{ path: "/facturas/configuracion", component: InvoiceConfig },
	{ path: "/facturas", component: InvoiceAddManual },

	{ path: "/cuenta/configuracion", component: AccountConfig },

	{ path: "/", exact: true, component: () => <Redirect to="/remates" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "", component: Login }
];

export { privateRoutes, publicRoutes };