import React, { Component } from 'react';
import './ModalCreateRemision.scss';
import { Container, Row, Col, Card, CardBody, FormGroup, Table } from 'reactstrap';

class ModalCreateRemision extends Component {

    close = () => {
        this.props.close();
    }

    render() {
        return (
            <div className="container-modal">
                <div className="content-modal">
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div onClick={() => this.close()} style={{ textAlign: 'right', cursor: 'pointer' }}>
                                            X
                                        </div>
                                        <div>
                                            <h4>Agregar remisiones</h4>
                                        </div>
                                        <Table className="mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Remisión (B)</th>
                                                    <th>Nombre (C)</th>
                                                    <th>Descripción (D + E + F)</th>
                                                    <th>Cantidad (G)</th>
                                                    <th>Precio base (H)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.props.remisions.map(item =>
                                                        <tr>
                                                            <td>{`${item.B}`}</td>
                                                            <td>{`${item.C}`}</td>
                                                            <td>
                                                                {`${item.D !== null ? item.D : ''} 
                                                                ${item.E !== null ? item.E : ''} ${item.F !== null ? item.F : ''}`}
                                                            </td>
                                                            <td>{`${item.G !== null ? item.G : 1}`}</td>
                                                            <td>{`${item.H !== null ? item.H : 0}`}</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                        <FormGroup className="mb-0 text-right" style={{ marginTop: '1rem' }}>
                                            <div>
                                                <button onClick={() => this.close()} type="button" className="btn btn-secondary">Cerrar</button>
                                                <button onClick={() => this.props.createRemisions()} style={{ marginLeft: '0.5rem' }} type="submit" className="btn btn-primary">Guardar</button>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

export default ModalCreateRemision;