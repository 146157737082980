import React, { Component } from 'react';
import './ModalConfirmRemision.scss';
import { connect } from 'react-redux';
import { logoutAction } from '../../redux/actions/UserActions';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Table, Input } from 'reactstrap';

class ModalConfirmRemision extends Component {
    state = {
        title: '',
        priceBase: '',
        quantity: '',
        description: '',
        remision: '',
        remitterName: ''
    }

    componentDidMount = async () => {
        let id_lot = this.props.id_lot ? parseInt(this.props.id_lot) + 1 : 1;
        await this.setState({
            title: this.props.lot.title,
            priceBase: this.props.lot.priceBase,
            quantity: this.props.lot.quantity,
            description: this.props.lot.description,
            remision: this.props.lot.remision,
            id_lot: id_lot,
            remitterName: this.props.lot.remitter?.name
        });
        document.getElementById("id_lot").focus();
    }

    close = () => {
        this.props.close();
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    confirmRemision = () => {
        this.props.confirmRemision({
            title: this.state.title,
            priceBase: this.state.priceBase,
            quantity: this.state.quantity,
            description: this.state.description,
            lot: this.state.id_lot
        });
        document.getElementById("id_lot").focus();
    }

    onKeyDown = (e) => {
        if (e.keyCode === 13) {
            this.confirmRemision()
        }
        if (e.keyCode === 27) {
            this.close();
        }
    }

    render() {
        return (
            <div className="container-modal">
                <div className="content-modal">
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div className="row">
                                            <div className="col-10">
                                                <h5 style={{ marginBottom: 0 }}>Confirmar loteo de remisión {this.state.remision} en el lote {this.state.id_lot}</h5>
                                            </div>
                                            <div className="col-2" onClick={() => this.close()} style={{ textAlign: 'right', cursor: 'pointer' }}>
                                                <h5 style={{ marginBottom: 0 }}>X</h5>
                                            </div>
                                        </div>
                                        <hr style={{ marginTop: '0.5rem' }} />
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Lote</Label>
                                            <Col md={10}>
                                                <Input id="id_lot" name="id_lot" type="text" className="form-control"
                                                    value={this.state.id_lot} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Remitente</Label>
                                            <Col md={10}>
                                                <Input id="remitter" name="remitter" type="text" className="form-control"
                                                    value={this.state.remitterName} disabled={true} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Cantidad</Label>
                                            <Col md={10}>
                                                <Input id="quantity" name="quantity" type="text" className="form-control" onKeyDown={(e) => { this.onKeyDown(e) }}
                                                    value={this.state.quantity} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Título</Label>
                                            <Col md={10}>
                                                <Input name="title" type="text" className="form-control" onKeyDown={(e) => { this.onKeyDown(e) }}
                                                    value={this.state.title} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Precio base</Label>
                                            <Col md={10}>
                                                <Input name="priceBase" type="text" className="form-control" onKeyDown={(e) => { this.onKeyDown(e) }}
                                                    value={this.state.priceBase} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Descripción</Label>
                                            <Col md={10}>
                                                <Input name="description" type="text" className="form-control" onKeyDown={(e) => { this.onKeyDown(e) }}
                                                    value={this.state.description} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="mb-0 text-right" style={{ marginTop: '1rem' }}>
                                            <div>
                                                <Button onClick={() => this.close()} type="reset" color="secondary">
                                                    Cerrar
                                                </Button>
                                                <Button onClick={() => this.confirmRemision()} color="primary" className="ml-2">
                                                    Confirmar
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalConfirmRemision);