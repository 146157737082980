import React, { Component } from "react";
// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <div id="layout-wrapper">
          <Header />
          <Sidebar
            isMobile={this.state.isMobile}
          />
          <div className="main-content">
            {this.props.children}
            <Footer />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Layout;

