import React, { Component } from 'react';
import {
    Col, Row, Card, CardBody, Container, Table,
    Nav, NavItem, NavLink, Input
} from 'reactstrap';
import './AuctionsList.scss';
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { auctionGetAll, deleteAuction } from '../../services/AuctionServices';
import { connect } from 'react-redux';
import PaginationCustom from '../../components/pagination/Pagination';
import moment from 'moment';
import 'moment/locale/es';
import Loading from '../../components/Loading';

import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { logoutAction } from '../../redux/actions/UserActions';
import click from '../../assets/images/click.svg';

class AuctionsList extends Component {
    state = {
        breadcrumbItems: [
            { title: "Remates", link: "#" }
        ],
        activeTab: '0',
        page: 1,
        pages: 0,
        auctions: [],
        loading: false,
        search: '',
        lastId2021: 0,
    }

    componentDidMount = async () => {
        moment.locale('es');
        toast.configure();
        this.fetchAuctions();
    }

    fetchAuctions = async (loading = true) => {
        loading && await this.setState({ loading: true });
        try {
            let response = await auctionGetAll({
                token: this.props.sessionProps.account.token,
                page: this.state.page,
                type: this.state.activeTab,
                search: encodeURIComponent(this.state.search)
            });
            await this.setState({
                pages: response.data.data.pages,
                auctions: response.data.data.auctions,
                loading: false,
                lastId2021: response.data.data.lastId2021
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    toggleTab = async (tab) => {
        if (this.state.activeTab !== tab) {
            await this.setState({
                activeTab: tab
            });
            this.fetchAuctions();
        }
    }

    getEstado(auction) {
        let dateFrom = moment(auction.date_from);
        let dateTo = moment(auction.date_to);
        if (auction.lots.length > 0) {
            dateTo = moment(auction.lots[auction.lots.length - 1].date_close);
        }
        if (dateTo > moment() && dateFrom < moment()) {
            return 'En proceso';
        }
        if (dateTo < moment() && dateFrom < moment()) {
            return 'Completado';
        }
        if (dateTo > moment() && dateFrom > moment()) {
            return 'Pendiente';
        }
        if (auction.date_to === null) {
            return 'Pendiente';
        }
    }

    showDeleteModal = (id, title) => {
        confirmAlert({
            title: 'Eliminar subasta',
            message: `¿Esta seguro que desea eliminar la subasta ${title}?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this.delete(id)
                    }
                }
            ]
        });
    }

    delete = async (id) => {
        await this.setState({ loading: true });
        try {
            let response = await deleteAuction({
                token: this.props.sessionProps.account.token,
                id: id
            });
            this.fetchAuctions();
            toast("La subasta ha sido eliminada con éxito", {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            await this.setState({ loading: false });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    onPrev = async () => {
        await this.setState({ page: this.state.page - 1 });
        this.fetchAuctions();
    }

    onNext = async () => {
        await this.setState({ page: this.state.page + 1 });
        this.fetchAuctions();
    }

    onPage = async (page) => {
        await this.setState({ page: page });
        this.fetchAuctions();
    }

    handleChangeSearch = async (event) => {
        const { name, value } = event.target;
        await this.setState({
            [name]: value,
            page: 1
        });
        this.fetchAuctions(false);
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading && <Loading />}
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Remates" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody className="without-padding">
                                        <Nav tabs className="nav-tabs-custom mb-4">
                                            <NavItem>
                                                <NavLink onClick={() => { this.toggleTab('0'); }} className={`${this.state.activeTab === '0' && 'active'} font-weight-bold p-3`}>Todos</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink onClick={() => { this.toggleTab('1'); }} className={`${this.state.activeTab === '1' && 'active'} font-weight-bold p-3`}>Pendientes</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink onClick={() => { this.toggleTab('2'); }} className={`${this.state.activeTab === '2' && 'active'} p-3 font-weight-bold`}>En proceso</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink onClick={() => { this.toggleTab('3'); }} className={`${this.state.activeTab === '3' && 'active'} p-3 font-weight-bold`}>Completados</NavLink>
                                            </NavItem>
                                        </Nav>
                                        <div class="row">
                                            <div className="col-md-4 col-6 order-1 order-md-1">
                                                <div className="form-inline mb-2">
                                                    <div className="search-box">
                                                        <div className="position-relative">
                                                            <Input type="text" className="form-control rounded" placeholder="Buscar nombre"
                                                                value={this.state.search} onChange={this.handleChangeSearch} name="search-auction" id="search-auction" />
                                                            <i className="mdi mdi-magnify search-icon"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12 order-3 order-md-2 text-center">
                                                <PaginationCustom page={this.state.page} pages={this.state.pages}
                                                    onPrev={this.onPrev} onNext={this.onNext} onPage={this.onPage} />
                                            </div>
                                            <div className="col-md-4 col-6 order-2 order-md-3 text-right div-btn-add">
                                                <Link to="remates/0" className="btn btn-success btn-add w-xs">
                                                    <i className="mdi mdi-plus"></i> Agregar
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <Table className="mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Nombre</th>
                                                        <th>Desde</th>
                                                        <th>Hasta</th>
                                                        <th>Visible</th>
                                                        <th>Estado</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.auctions.map(item =>
                                                            <tr>
                                                                <th scope="row">
                                                                    {
                                                                        String(item.date_to).includes('2022') ?
                                                                            item.id - this.state.lastId2021 :
                                                                            item.id
                                                                    }
                                                                </th>
                                                                <td>{item.title}</td>
                                                                <td>{moment(item.date_from).format('dddd, D. MMMM HH:mm')}hs</td>
                                                                <td>{item.date_to === null ? '-' : moment(item.date_to).format('dddd, D. MMMM HH:mm') + 'hs'}</td>
                                                                <td>{item.visible == 1 ? 'Visible' : 'No visible'}</td>
                                                                <td>{this.getEstado(item)}</td>
                                                                <td>
                                                                    <Link onClick={() => this.showDeleteModal(item.id, item.title)} className="text-danger"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                                                                    <Link to={`remates/${item.id}`} className="mr-3 text-primary"><i className="mdi mdi-pencil font-size-18"></i></Link>
                                                                    {
                                                                        this.getEstado(item) === 'En proceso' &&
                                                                        <div style={{ position: 'relative' }}>
                                                                            <div style={{ position: 'absolute', right: '0rem', top: '-2rem' }}>
                                                                                <img style={{ height: '2.5rem', objectFit: 'cover' }} src={click} />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        <PaginationCustom page={this.state.page} pages={this.state.pages}
                                            onPrev={this.onPrev} onNext={this.onNext} onPage={this.onPage} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuctionsList);