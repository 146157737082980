import React, { Component } from 'react';
import { logoutAction } from '../../redux/actions/UserActions';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardBody, FormGroup } from 'reactstrap';
import Loading from '../Loading';
import { printRemisions } from '../../services/RemisionServices';
import { toast } from 'react-toastify';
import DatePicker from 'react-datetime';

class ModalPrintRemisions extends Component {
    state = {
        from: '',
        to: '',
        loading: false,
        dateReturn: null
    }

    componentDidMount = () => {
        toast.configure();
    }

    close = () => {
        this.props.close();
    }

    print = async () => {
        await this.setState({ loading: true });
        try {
            let response = await printRemisions({
                token: this.props.sessionProps.account.token,
                id: this.props.remitter.value,
                from: this.state.from,
                to: this.state.to,
                dateReturn: this.state.dateReturn?.format('YYYY-MM-DD'),
                filter: this.props.filter
            });
            let link = document.createElement('a');
            link.href = response.data.data.url;
            link.download = `boletas_señas.pdf`;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            await this.setState({ loading: false });
            this.close();
        } catch (error) {
            await this.setState({ loading: false });
            toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    render() {
        return (
            <div className="container-modal">
                {this.state.loading && <Loading />}
                <div className="content-modal">
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div onClick={() => this.close()} style={{ textAlign: 'right', cursor: 'pointer' }}>
                                            X
                                        </div>
                                        <div>
                                            <h4>Imprimir remisiones del remitente #{this.props.remitter.label}</h4>
                                        </div>
                                        <div style={{ marginTop: '1rem' }}>
                                            <div className="form-group">
                                                <label>Desde</label>
                                                <input type="text" className="form-control" placeholder="Ingrese remisión desde" name="from"
                                                    value={this.state.from} onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>Hasta</label>
                                                <input type="text" className="form-control" placeholder="Ingrese remisión hasta" name="to"
                                                    value={this.state.to} onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>Solo devolución realizada el día</label>
                                                <DatePicker
                                                    value={this.state.dateReturn} timeFormat={false}
                                                    onChange={val => this.setState({ dateReturn: val })}
                                                />
                                            </div>
                                        </div>
                                        <FormGroup className="mb-0 text-right" style={{ marginTop: '1rem' }}>
                                            <div>
                                                <button onClick={() => this.close()} type="button" className="btn btn-secondary">Cerrar</button>
                                                <button onClick={() => this.print()} style={{ marginLeft: '0.5rem' }} className="btn btn-primary">Imprimir</button>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalPrintRemisions);