/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import {
    Col, Row, Card, CardBody, Container, FormGroup, Label, Input, Button, CustomInput,
    Nav, NavItem, NavLink, TabPane, TabContent, Pagination, PaginationItem, PaginationLink, Table, Form
} from 'reactstrap';
import './AuctionsAddEdit.scss';
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

// Form Editor
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import axios from 'axios';
import { connect } from 'react-redux';
import { Constants } from '../../Constants';
import { auctionAdd, auctionUpdate, auctionGet, sendPdfs, auctionGetMoneys, getItemsType, downloadPdfs, downloadSigns, getConfigs, postLogsByAuction } from '../../services/AuctionServices';

//Dropzone
import Dropzone from "react-dropzone";
import { addLotsMassive, deliveryLots, getLots, add, changeRemision, importLots } from '../../services/LotServices';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import { confirmAlert } from 'react-confirm-alert';
import { logoutAction } from '../../redux/actions/UserActions';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import Select from 'react-select';
import ModalForceWin from '../../components/ModalForceWin';
import * as XLSX from 'xlsx';
import ModalCreateLots from '../../components/ModalCreateLots/ModalCreateLots';
import PaginationCustom from '../../components/pagination/Pagination';
import { editRemision, getRemision, editRemisionMasive } from '../../services/RemisionServices';
import ModalMapLotRemision from '../../components/ModalMapLotRemision/ModalMapLotRemision';
import ModalShowImage from '../../components/ModalShowImage/ModalShowImage';
import ModalConfirmRemision from '../../components/ModalConfirmRemision/ModalConfirmRemision';
import ModalAddMultipleRemision from '../../components/ModalAddMultipleRemision/ModalAddMultipleRemision';
import ImageGallery from 'react-image-gallery';
import ModalImportLotsFromAuction from '../../components/ModalImportLotsFromAuction/ModalImportLotsFromAuction';
import DeleteLot from '../../components/Auctions/actions/delete';
class AuctionsAddEdit extends Component {
    state = {
        breadcrumbItems: [
            { title: "Remates", link: "/remates" },
            { title: `${this.props.match.params.id === '0' ? 'Crear remate' : 'Editar remate'}`, link: "#" }
        ],
        activeTab: 1,
        auction: {
            id: parseInt(this.props.match.params.id),
            title: '',
            description: EditorState.createEmpty(),
            dateTimeFrom: '',
            dateTimeTo: '',
            image: '',
            image_thumb: '',
            image_name: '',
            image_size: '',
            catalog: '',
            visible: { value: 0, label: 'No visible' },
            finished: false,
            money: null,
            moneyInvoice: null,
            logs: [],
            auctionType: null,
            currencyExchange: '',
            moneySymbol2: '',
            moneySymbol: '',
            totalIngress: 0,
            totalIngressUYU: 0,
            iva: 0,
            ivaUYU: 0,
            subtotal: 0,
            subtotalUYU: 0,
            comision: 0,
            comisionUYU: 0,
            tax_municipal: Constants.MUNICIPAL_TAX,
            info_aditional: EditorState.createEmpty(),
            lastIdLot: '',
            lastId2021: 0,
        },
        selectedFiles: [],
        loading: false,
        moneys: [],
        items: [],
        lotSelect: null,
        showModalCreateLots: false,
        lotsFromExcel: [],
        lots: [],
        page: 1,
        pages: 0,
        addRemision: null,
        searchLot: '',
        mapLotRemision: [],
        showModalMapLotRemision: false,
        imageShow: null,
        imageShowIdItem: null,
        selectTaxMunicipal: this.props.configAccount.config?.ley_12700_defecto ? { value: 1, label: 'Si' } : { value: 0, label: 'No' },
        showModalConfirmationRemision: false,
        lotConfirmationRemision: null,
        filterStatus: { value: 'all', label: 'Filtrar por estado' },
        showModalAddMultipleRemision: false,
        initApplyRemision: true,
        modeViewList: 'list', // 'card'
        orderLots: { value: 'asc', label: 'Orden ASC' },
        remisionsWithErrors: [],
        showModalImportLotsFromAuction: false,
        processFiles: null,
        uploadedFiles: null,
    }

    componentDidMount = async () => {
        toast.configure();
        await this.fetchMoneys();
        await this.fetchItems();
        await this.fetchConfigs();
        if (this.state.auction.id !== 0) {
            await this.fetchAuction(this.state.auction.id);
            await this.fetchLots();
        }
    }

    componentDidUpdate = async (prevProps) => {
        if (prevProps.configAccount.config !== this.props.configAccount.config) {
            if (this.props.configAccount.config?.ley_12700_defecto !== null && this.state.auction.id === 0) {
                await this.setState({
                    selectTaxMunicipal: this.props.configAccount.config.ley_12700_defecto ? { value: 1, label: 'Si' } : { value: 0, label: 'No' }
                });
            }
        }
    }

    fetchConfigs = async () => {
        await this.setState({ loading: true });
        try {
            let response = await getConfigs({
                token: this.props.sessionProps.account.token,
            });
            await this.setState({
                loading: false,
                auction: {
                    ...this.state.auction,
                    description: EditorState.createWithContent(stateFromHTML(response.data.data.config.default_description)),
                    info_aditional: EditorState.createWithContent(stateFromHTML(response.data.data.config.info_aditional)),
                }
            })
        } catch (error) {
            await this.setState({ loading: false });
        }
    }

    fetchMoneys = async () => {
        await this.setState({ loading: true });
        try {
            let response = await auctionGetMoneys({
                token: this.props.sessionProps.account.token,
            });
            await this.setState({
                moneys: response.data.data,
                auction: {
                    ...this.state.auction,
                    money: response.data.data.length > 0 ? response.data.data[0] : null,
                    moneyInvoice: response.data.data.length > 0 ? response.data.data[0] : null
                },
                loading: false
            })
        } catch (error) {
            await this.setState({ loading: false });
        }
    }

    fetchLogs = async () => {
        await this.setState({ loading: true });
        try {
            let response = await postLogsByAuction({
                token: this.props.sessionProps.account.token,
                auction: this.state.auction.id
            });
            await this.setState({
                auction: {
                    ...this.state.auction,
                    logs: response.data.data.logs,
                },
                loading: false
            })
        } catch (error) {
            await this.setState({ loading: false });
        }
    }

    fetchLots = async () => {
        await this.setState({ loading: true });
        try {
            let data = {
                token: this.props.sessionProps.account.token,
                page: this.state.page,
                auction: this.state.auction.id,
                offset: 50,
                orderLots: this.state.orderLots.value
            };
            if (this.state.filterStatus.value !== 'all') {
                data.filterStatus = this.state.filterStatus.value;
            }
            let response = await getLots(data);
            let lots = response.data.data.lots;
            lots.map(item => {
                item.showButtonSaveTitle = false;
                item.showButtonSavePriceBase = false;
                item.showButtonSaveDescription = false;
                item.showButtonSaveQuantity = false;
                let temporal = document.createElement("div");
                temporal.innerHTML = item.description;
                item.description = temporal.textContent || temporal.innerText || "";
                item.applyRemision = this.state.initApplyRemision;
            });
            await this.setState({
                pages: response.data.data.pages,
                lots: lots,
                auction: {
                    ...this.state.auction,
                    totalIngress: response.data.totalIngress,
                    totalIngressUYU: response.data.totalIngressUYU,
                    iva: response.data.iva,
                    ivaUYU: response.data.ivaUYU,
                    subtotal: response.data.subTotal,
                    subtotalUYU: response.data.subTotalUYU,
                    comision: response.data.comision,
                    comisionUYU: response.data.comisionUYU,
                    lastIdLot: response.data.lastIdLot
                },
                loading: false
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    fetchItems = async () => {
        await this.setState({ loading: true });
        try {
            let response = await getItemsType({
                token: this.props.sessionProps.account.token
            });
            let auctionType = null;
            if (response.data.data.items.length > 0) {
                auctionType = response.data.data.items[0];
                auctionType = { value: auctionType.id, label: auctionType.description, online: Boolean(auctionType.online) };
            }
            await this.setState({
                items: response.data.data.items.map(item => ({ value: item.id, label: item.description, online: Boolean(item.online) })),
                auction: {
                    ...this.state.auction,
                    auctionType: auctionType
                },
                loading: false
            })
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }


    fetchAuction = async (id, showSpinner = true) => {
        showSpinner && await this.setState({ loading: true });
        try {
            let response = await auctionGet({
                token: this.props.sessionProps.account.token,
                id: id
            });
            let { title, description, date_from, date_to, image, image_thumb, visible, finished, money, moneySymbol, auction_type,
                currency_exchange, moneySymbol2, money_invoice, moneyInvoiceSymbol, tax_municipal, info_aditional, catalog, lastId2021 } = response.data.data;

            let auctionType = this.state.items.filter(item => item.value === auction_type);
            // let items = this.state.items;
            if (auctionType.length > 0) {
                auctionType = auctionType[0];
                // items = this.state.items.filter(item => item.online === auctionType.online);
            }
            await this.setState({
                auction: {
                    ...this.state.auction,
                    id: id,
                    title: title,
                    description: description !== "<p><br></p>" ? EditorState.createWithContent(stateFromHTML(description)) : this.state.auction.description,
                    dateTimeFrom: moment(date_from.replace(' ', 'T')),
                    dateTimeTo: date_to !== null && moment(date_to.replace(' ', 'T')),
                    image: image,
                    image_thumb: image_thumb,
                    image_name: `Imagen ${title}`,
                    image_size: `${Math.floor(Math.random() * (300 - 50 + 1)) + 50}`,
                    finished: finished,
                    visible: { value: visible, label: visible == 0 ? 'No visible' : 'Visible' },
                    money: { value: money, label: moneySymbol },
                    moneyInvoice: { value: money_invoice, label: moneyInvoiceSymbol },
                    moneySymbol: moneySymbol,
                    auctionType: auctionType ? auctionType : null,
                    currencyExchange: currency_exchange,
                    moneySymbol2: moneySymbol2,
                    tax_municipal: tax_municipal,
                    info_aditional: info_aditional !== "<p><br></p>" ? EditorState.createWithContent(stateFromHTML(info_aditional)) : this.state.auction.info_aditional,
                    catalog: catalog,
                    lastId2021: lastId2021,
                },
                loading: false,
                // items: items,
                selectTaxMunicipal: tax_municipal === null ? { value: 0, label: 'No' } : { value: 1, label: 'Si' }
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    handleSelectChange = async (selectedOption, name, auction = true) => {
        if (auction) {
            await this.setState({
                auction: {
                    ...this.state.auction,
                    [name]: selectedOption
                }
            });
        }
        else {
            if (name === 'selectTaxMunicipal') {
                await this.setState({
                    [name]: selectedOption,
                    auction: {
                        ...this.state.auction,
                        tax_municipal: selectedOption.value === 1 ? Constants.MUNICIPAL_TAX : null
                    }
                });
            }
            await this.setState({
                [name]: selectedOption
            });
        }
    };

    handleFileChange = async (event) => {
        for (let file of event.target.files) {
            var form = new FormData();
            form.append('file', file);
            try {
                const result = await axios.post(`${Constants.BASE_URL}auctions/image_upload`, form, {
                    headers: {
                        'Authorization': this.props.sessionProps.account.token,
                        'content-type': 'multipart/form-data'
                    }
                });
                await this.setState({
                    auction: {
                        ...this.state.auction,
                        image: result.data.data.image,
                        image_thumb: result.data.data.image_thumb,
                        image_name: file.name,
                        image_size: (file.size / 1024).toFixed(2)
                    }
                });
            } catch (error) {
                if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                    this.props.logout();
                    this.props.history.push('/');
                }
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    handleCatalogChange = async (event) => {
        for (let file of event.target.files) {
            await this.setState({ loading: true });
            var form = new FormData();
            form.append('file', file);
            try {
                const result = await axios.post(`${Constants.BASE_URL}auctions/image_upload?type=catalogs`, form, {
                    headers: {
                        'Authorization': this.props.sessionProps.account.token,
                        'content-type': 'multipart/form-data'
                    }
                });
                await this.setState({
                    auction: {
                        ...this.state.auction,
                        catalog: result.data.data.image
                    },
                    loading: false
                });
            } catch (error) {
                await this.setState({ loading: false });
                if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                    this.props.logout();
                    this.props.history.push('/');
                }
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    handleFileChangeLots = async (files) => {
        await this.setState({
            loading: true,
        })

        const newFiles = []
        for await (let file of files) {
            const newFile = await this.getCroppedImg(file);
            newFiles.push({
                file: newFile,
                name: file.name,
            });
            // cada 10 push que meto en new files muestro el mensaje
            if (newFiles.length % 10 === 0) {
                await this.setState({
                    processFiles: `Procesando ${newFiles.length} de ${files.length} archivos`
                })
            }
        }

        await this.setState({
            processFiles: null
        })

        let count = 0
        for await (let file of newFiles) {
            if (count % 10 === 0) {
                await this.setState({
                    uploadedFiles: `Subiendo ${count} de ${files.length} archivos`
                })
            }
            count += 1
            try {
                var form = new FormData();
                form.append('file', file.file);
                try {
                    await this.setState({ loading: true });
                    const result = await axios.post(`${Constants.BASE_URL_IMAGES}image_upload?type=lots&owner=${Constants.API_NAME}`, form, {
                        headers: {
                            'Authorization': this.props.sessionProps.account.token,
                            'content-type': 'multipart/form-data'
                        }
                    });

                    let lot = file.name.split('_');
                    if (lot[1] === undefined) {
                        toast.error(`El nombre de la imagen no tiene el formato requerido (nombre_lote ej. imagen_001)`, {
                            position: toast.POSITION.BOTTOM_CENTER,
                            pauseOnHover: false,
                            duration: 10000
                        });
                        return;
                    }
                    //[imagen,001,01.jpg]
                    let idLot = null;
                    let orderImg = 0;
                    if (lot[1].includes('.')) { //[imagen,001.jpg]
                        // es la primera
                        lot = lot[1].split('.');
                        idLot = lot[0];
                    }
                    else {
                        // es la secundaria
                        idLot = lot[1]
                        let order = lot[2].split('.');
                        orderImg = parseInt(order[0]);
                    }
                    let selectedFiles = this.state.selectedFiles;
                    let existFile = selectedFiles.find(file => file.idLot === idLot)
                    if (existFile) {
                        existFile.images.push({
                            image: `${Constants.BASE_URL_IMAGES}${result.data.data.image}`,
                            imageThumb: `${Constants.BASE_URL_IMAGES}${result.data.data.image_thumb}`,
                            orderImg,
                        })
                    }
                    else {
                        selectedFiles.push({
                            image: `${Constants.BASE_URL_IMAGES}${result.data.data.image}`,
                            imageThumb: `${Constants.BASE_URL_IMAGES}${result.data.data.image_thumb}`,
                            images: [{
                                image: `${Constants.BASE_URL_IMAGES}${result.data.data.image}`,
                                imageThumb: `${Constants.BASE_URL_IMAGES}${result.data.data.image_thumb}`,
                                orderImg,
                            }],
                            idLot: idLot,
                            title: '',
                            description: '',
                            priceBase: 0,
                            quantity: 1
                        });
                    }
                    selectedFiles.sort(function (a, b) {
                        if (a.idLot > b.idLot) return 1;
                        if (a.idLot < b.idLot) return -1;
                        return 0;
                    });
                    selectedFiles.map(selectedFile => {
                        selectedFile.images.sort(function (a, b) {
                            if (a.orderImg > b.orderImg) {
                                return 1;
                            }
                            if (a.orderImg < b.orderImg) {
                                return -1;
                            }
                            return 0;
                        });
                    })
                    await this.setState({
                        selectedFiles: selectedFiles
                    });
                } catch (error) {
                    await this.setState({ loading: false });
                    if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                        this.props.logout();
                        this.props.history.push('/');
                    }
                    toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                        position: toast.POSITION.BOTTOM_CENTER,
                        pauseOnHover: false,
                        duration: 10000
                    });
                }
            } catch (errorEx) {
                if (errorEx !== undefined && errorEx.response !== undefined && errorEx.response.status === 401) {
                    this.props.logout();
                    this.props.history.push('/');
                }
                console.log(errorEx)
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
                await this.setState({
                    loading: false,
                })
            }
        }

        await this.setState({
            uploadedFiles: null,
            loading: false,
        })
    }

    getCroppedImg = async (file) => {
        // Crea una imagen a partir de la fuente proporcionada
        // Crea una URL desde el objeto File
        const imageSrc = URL.createObjectURL(file);

        // Carga la imagen
        const image = await new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = (e) => {
                reject(new Error(`Failed to load image: ${e}`));
            };
            img.id = file.name;
            img.src = imageSrc;
        });

        URL.revokeObjectURL(imageSrc);

        // Define las dimensiones originales de la imagen
        let width = image.width;
        let height = image.height;

        // Configura el tamaño máximo que la imagen debe tener
        const maxSize = 900;
        if (width > height) {
            // Si el ancho es mayor que el alto
            if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
            }
        } else {
            // Si el alto es mayor que el ancho
            if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
            }
        }

        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        // Limpiar el canvas antes de dibujar
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Establece las dimensiones del canvas basadas en las dimensiones ajustadas
        canvas.width = width;
        canvas.height = height;

        // Dibuja la imagen redimensionada en el canvas
        ctx.drawImage(image, 0, 0, width, height);

        // Retorna un blob de la imagen redimensionada
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                resolve(blob);
            }, 'image/jpeg');
        });
    }

    handleFileChangeLot = async (files, item) => {
        await this.setState({
            loading: true,
        })

        const newFiles = []
        for await (let file of files) {
            const newFile = await this.getCroppedImg(file);
            newFiles.push({
                file: newFile,
                name: file.name,
            });
            // cada 10 push que meto en new files muestro el mensaje
            if (newFiles.length % 10 === 0) {
                await this.setState({
                    processFiles: `Procesando ${newFiles.length} de ${files.length} archivos`
                })
            }
        }

        await this.setState({
            processFiles: null
        })

        let count = 0
        let images = [...item.images];
        for await (let file of newFiles) {
            if (count % 10 === 0) {
                await this.setState({
                    uploadedFiles: `Subiendo ${count} de ${files.length} archivos`
                })
            }
            count += 1
            try {
                var form = new FormData();
                form.append('file', file.file);
                try {
                    await this.setState({ loading: true });
                    const result = await axios.post(`${Constants.BASE_URL_IMAGES}image_upload?type=lots&owner=${Constants.API_NAME}`, form, {
                        headers: {
                            'Authorization': this.props.sessionProps.account.token,
                            'content-type': 'multipart/form-data'
                        }
                    });
                    images.push({
                        id_lot: item.id,
                        image: `${Constants.BASE_URL_IMAGES}${result.data.data.image}`,
                        image_thumb: `${Constants.BASE_URL_IMAGES}${result.data.data.image_thumb}`,
                    })
                    await this.setState({ loading: false });
                    this.fetchLots();
                } catch (error) {
                    await this.setState({ loading: false });
                    if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                        this.props.logout();
                        this.props.history.push('/');
                    }
                    toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                        position: toast.POSITION.BOTTOM_CENTER,
                        pauseOnHover: false,
                        duration: 10000
                    });
                }
            } catch (errorEx) {
                if (errorEx !== undefined && errorEx.response !== undefined && errorEx.response.status === 401) {
                    this.props.logout();
                    this.props.history.push('/');
                }
                console.log(errorEx)
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
                await this.setState({
                    loading: false,
                })
            }
        }

        await this.saveRemision(item, null, null, null, null, images);

        await this.setState({
            uploadedFiles: null,
            loading: false,
        })
    }

    removeSelectedFilesLot = async (index) => {
        let selectedFiles = this.state.selectedFiles;
        selectedFiles.splice(index, 1);
        await this.setState({ selectedFiles: selectedFiles });
    }

    handleChange = (event, auction = true) => {
        const { name, value } = event.target;
        if (auction) {
            this.setState({
                auction: {
                    ...this.state.auction,
                    [name]: value
                }
            });
        }
        else {
            this.setState({
                ...this.state,
                [name]: value
            });
        }
    }

    handleChangeLot = async (event, i) => {
        let { name, value } = event.target;
        let selectedFiles = this.state.selectedFiles;
        if (name === 'quantity') {
            if (value < 1 && value !== '') {
                value = 1;
            }
        }
        selectedFiles[i][name] = value;
        await this.setState({
            selectedFiles: selectedFiles
        });
    }

    onEditorStateChange = (description) => {
        this.setState({
            auction: {
                ...this.state.auction,
                description: description
            }
        });
    };

    onEditorStateChangeInfoAditional = (info_aditional) => {
        this.setState({
            auction: {
                ...this.state.auction,
                info_aditional: info_aditional
            }
        });
    };

    saveAuction = async (event) => {
        event.preventDefault();
        if (this.state.auction.auctionType === null) {
            toast.error(`Debe asignar el tipo de remate`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
            return;
        }
        if (this.state.auction.auctionType.online && this.state.auction.image === '') {
            toast.error(`Debe asignar una imagen`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
            return;
        }
        if (this.state.auction.dateTimeFrom === '' || (this.state.auction.auctionType.online && this.state.auction.dateTimeTo === '')) {
            toast.error(`Debe ingresar fecha de inicio y fecha de fin`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
            return;
        }
        if (this.state.auction.auctionType.online && this.state.auction.dateTimeFrom > this.state.auction.dateTimeTo) {
            toast.error(`La fecha de fin debe ser mayor a la de inicio`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
            return;
        }
        await this.setState({ loading: true });
        try {
            let data = {
                id: this.state.auction.id,
                token: this.props.sessionProps.account.token,
                description: this.state.auction.auctionType.online ? stateToHTML(this.state.auction.description.getCurrentContent()) : null,
                title: this.state.auction.title,
                date_from: this.state.auction.dateTimeFrom.format('YYYY-MM-DD HH:mm:ss'),
                date_to: this.state.auction.auctionType.online ? this.state.auction.dateTimeTo.format('YYYY-MM-DD HH:mm:ss') : null,
                image: this.state.auction.auctionType.online ? this.state.auction.image : null,
                image_thumb: this.state.auction.auctionType.online ? this.state.auction.image_thumb : null,
                visible: this.state.auction.visible.value,
                money: this.state.auction.money.value,
                moneyInvoice: this.state.auction.moneyInvoice.value,
                auction_type: this.state.auction.auctionType.value,
                currency_exchange: this.state.auction.currencyExchange,
                tax_municipal: this.state.selectTaxMunicipal.value === 1 ? this.state.auction.tax_municipal : null,
                info_aditional: stateToHTML(this.state.auction.info_aditional.getCurrentContent()),
                catalog: this.state.auction.catalog !== '' ? this.state.auction.catalog : null
            };
            let response = null;
            if (this.state.auction.id === 0) {
                response = await auctionAdd(data);
            }
            else {
                response = await auctionUpdate(data);
            }
            toast(`La subasta ha sido ${this.state.auction.id === 0 ? 'creada' : 'modificada'} con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            await this.setState({
                loading: false,
                auction: {
                    ...this.state.auction,
                    id: response.data.data.auction !== undefined ? response.data.data.auction.id : response.data.data.id
                }
            });
            this.fetchAuction(this.state.auction.id)
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    finishAuction = () => {
        confirmAlert({
            title: 'Finalizar remate',
            message: `¿Esta seguro que desea finalizar el remate?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._finishAuction()
                    }
                }
            ]
        });
    }

    _finishAuction = async () => {
        await this.setState({ loading: true });
        try {
            let data = {
                id: this.state.auction.id,
                token: this.props.sessionProps.account.token,
                date_to: moment().subtract(1, 'hours').format('YYYY-MM-DD HH:mm:ss')
            };
            let response = await auctionUpdate(data);
            toast(`La subasta ha sido finalizada con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            await this.setState({ loading: false });
            this.fetchAuction(this.state.auction.id)
            this.fetchLots();
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
        if (tab === 4) {
            this.fetchLogs();
        }
    }

    createLots = (e) => {
        e.preventDefault();
        this._createLots(this.state.selectedFiles);
    }

    _createLots = async (lots) => {
        await this.setState({ loading: true, remisionsWithError: [] });
        try {
            let response = await addLotsMassive({
                idRemate: this.state.auction.id,
                lots: lots,
                token: this.props.sessionProps.account.token
            });
            toast(`Lotes creados con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            this.fetchLots();
            await this.setState({ loading: false });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                let msg = 'Ocurrió un error, reintentar.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                    if (error.response.data.remisionsWithErrors) {
                        await this.setState({
                            remisionsWithErrors: error.response.data.remisionsWithErrors
                        })
                    }
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    handleChangeRemisionNumber = async (event) => {
        let { name, value } = event.target;
        let lots = this.state.lots;
        for (let lot of lots) {
            if (lot.id == name) {
                lot.remision_number = value;
                lot.showButtonSaveRemisionNumber = true;
                break;
            }
        }
        await this.setState({
            auction: {
                ...this.state.auction,
                lots: lots
            }
        })
    }

    handleChangeIdLot = async (event) => {
        let { name, value } = event.target;
        let lots = this.state.lots;
        for (let lot of lots) {
            if (lot.id == name) {
                lot.id_lot = value;
                lot.showButtonSaveIdLot = true;
                break;
            }
        }
        await this.setState({
            auction: {
                ...this.state.auction,
                lots: lots
            }
        })
    }

    handleChangeLotTitle = async (event) => {
        let { name, value } = event.target;
        let lots = this.state.lots;
        for (let lot of lots) {
            if (lot.id == name) {
                lot.title = value;
                lot.showButtonSaveTitle = true;
                break;
            }
        }
        await this.setState({
            auction: {
                ...this.state.auction,
                lots: lots
            }
        })
    }

    handleChangeLotQuantity = async (event) => {
        let { name, value } = event.target;
        let lots = this.state.lots;
        for (let lot of lots) {
            if (lot.id == name) {
                lot.quantity = value !== '' && value < 1 ? 1 : value;
                lot.showButtonSaveQuantity = true;
                break;
            }
        }
        await this.setState({
            auction: {
                ...this.state.auction,
                lots: lots
            }
        })
    }

    handleChangeLotPriceBase = async (event) => {
        let { name, value } = event.target;
        let lots = this.state.lots;
        for (let lot of lots) {
            if (lot.id == name) {
                lot.price_base = value;
                lot.showButtonSavePriceBase = true;
                break;
            }
        }
        await this.setState({
            auction: {
                ...this.state.auction,
                lots: lots
            }
        })
    }

    handleChangeLotDescription = async (event) => {
        let { name, value } = event.target;
        let lots = this.state.lots;
        for (let lot of lots) {
            if (lot.id == name) {
                lot.description = value;
                lot.showButtonSaveDescription = true;
                break;
            }
        }
        await this.setState({
            auction: {
                ...this.state.auction,
                lots: lots
            }
        })
    }

    toggleChange = async (item) => {
        let lots = this.state.lots;
        for (let lot of lots) {
            if (lot.id == item.id) {
                lot.applyRemision = !item.applyRemision;
                break;
            }
        }
        await this.setState({
            auction: {
                ...this.state.auction,
                lots: lots
            }
        })
    }

    saveRemision = async (item, title, description, priceBase, quantity = null, images = null, id_lot = null) => {
        await this.setState({ loading: true });
        try {
            let data = {
                id: item.id_remision,
                token: this.props.sessionProps.account.token,
                id_remate: item.id_remate,
                isFromLot: item.id,
                applyRemision: item.applyRemision
            };
            if (title !== null) {
                data.title = title;
            }
            if (description !== null) {
                data.description = description;
            }
            if (priceBase !== null) {
                data.price_base = priceBase;
            }
            if (quantity !== null) {
                data.quantity = quantity;
            }
            if (images !== null) {
                data.images = images;
            }
            if (id_lot !== null) {
                data.id_lot = id_lot;
            }
            let response = await editRemision(data);
            await this.setState({ loading: false });
            this.fetchLots();
            toast(`El lote ha sido modificado con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                let msg = 'Ocurrió un error, reintentar.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    saveRemisionsMasive = async () => {
        let lotsWithChanges = this.state.lots.filter(item => item.showButtonSaveRemisionNumber ||
            item.showButtonSaveTitle ||
            item.showButtonSaveQuantity ||
            item.showButtonSavePriceBase ||
            item.showButtonSaveDescription);
        await this.setState({ loading: true });
        try {
            let data = {
                token: this.props.sessionProps.account.token,
                lots: []
            };
            for (let item of lotsWithChanges) {
                let _data = {
                    id: item.id_remision,
                    id_remate: item.id_remate,
                    isFromLot: item.id,
                    applyRemision: item.applyRemision
                };
                if (item.showButtonSaveTitle) {
                    _data.title = item.title;
                }
                if (item.showButtonSaveDescription) {
                    _data.description = item.description;
                }
                if (item.showButtonSavePriceBase) {
                    _data.price_base = item.price_base;
                }
                if (item.showButtonSaveQuantity) {
                    _data.quantity = item.quantity;
                }
                data.lots.push(_data);
            }
            let response = await editRemisionMasive(data);
            await this.setState({ loading: false });
            this.fetchLots();
            toast(`Los lotes han sido modificados con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                let msg = 'Ocurrió un error, reintentar.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    _sendPdfs = () => {
        confirmAlert({
            title: 'Enviar PDFs a compradores',
            message: `¿Esta seguro que desea enviar los PDFs a los compradores?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this.sendPdfs()
                    }
                }
            ]
        });
    }

    sendPdfs = async () => {
        await this.setState({ loading: true });
        try {
            sendPdfs({
                auctionId: this.state.auction.id,
                token: this.props.sessionProps.account.token
            });
            await this.setState({ loading: false });
            toast(`PDFs a compradores enviados con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            } else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    _downloadPdfs = () => {
        confirmAlert({
            title: 'Descargar PDFs de compradores',
            message: `¿Esta seguro que desea descargar los PDFs de los compradores?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this.downloadPdfs()
                    }
                }
            ]
        });
    }

    downloadPdfs = async () => {
        await this.setState({ loading: true });
        try {
            let response = await downloadPdfs({
                auctionId: this.state.auction.id,
                token: this.props.sessionProps.account.token
            });
            let link = document.createElement('a');
            link.href = response.data.data.url;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            await this.setState({ loading: false });
        } catch (error) {
            await this.setState({ loading: false });
            let msg = 'Ocurrió un error, reintentar.';
            if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                msg = error.response.data.message;
            }
            toast.error(msg, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
    }

    _downloadSigns = () => {
        confirmAlert({
            title: 'Descargar boletas de seña',
            message: `¿Esta seguro que desea descargar las boletas de seña?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this.downloadSigns()
                    }
                }
            ]
        });
    }

    downloadSigns = async () => {
        await this.setState({ loading: true });
        try {
            let response = await downloadSigns({
                auctionId: this.state.auction.id,
                token: this.props.sessionProps.account.token
            });
            let link = document.createElement('a');
            link.href = response.data.data.url;
            link.download = `boletas_señas.pdf`;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            await this.setState({ loading: false });
        } catch (error) {
            await this.setState({ loading: false });
        }
    }

    closeModal = async () => {
        await this.setState({
            lotSelect: null
        });
        this.fetchLots();
    }

    handleUpload = (e) => {
        e.preventDefault();
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = async function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            let wsname = readedData.SheetNames[0];
            let ws = readedData.Sheets[wsname];
            let lots = XLSX.utils.sheet_to_json(ws, { header: "A", defval: null, range: 2 });
            let _tmpLots = [];
            let remisionDuplicated = null;
            for (let item of lots) {
                if (_tmpLots.includes(item.B)) {
                    remisionDuplicated = item.B;
                    break;
                }
                _tmpLots.push(item.B);
            }
            if (remisionDuplicated !== null) {
                let _lots = '';
                await lots.filter(item => item.B === remisionDuplicated).map(item => {
                    _lots += item.A + ', ';
                });
                confirmAlert({
                    title: 'Remisión repetida',
                    message: `¿La remisión ${remisionDuplicated} se encuentra repetida en los lotes ${_lots} desea continuar?`,
                    buttons: [
                        {
                            label: 'Cancelar'
                        },
                        {
                            label: 'Confirmar',
                            onClick: async () => {
                                await this.setState({
                                    showModalCreateLots: true,
                                    lotsFromExcel: lots
                                });
                            }
                        }
                    ]
                });
            }
            else {
                await this.setState({
                    showModalCreateLots: true,
                    lotsFromExcel: lots
                });
            }
        }.bind(this);
        e.target.value = '';
        reader.readAsBinaryString(f)
    }

    handleUploadRemisions = (e) => {
        e.preventDefault();
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = async function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            let wsname = readedData.SheetNames[0];
            let ws = readedData.Sheets[wsname];
            let remisions = XLSX.utils.sheet_to_json(ws, { header: "A", defval: null, range: 2 });
            let _tmpRemisions = [];
            let remisionDuplicated = null;
            for (let item of remisions) {
                if (_tmpRemisions.includes(item.B)) {
                    remisionDuplicated = item.B;
                    break;
                }
                _tmpRemisions.push(item.B);
            }
            if (remisionDuplicated !== null) {
                let lots = '';
                await remisions.filter(item => item.B === remisionDuplicated).map(item => {
                    lots += item.A + ', ';
                });
                confirmAlert({
                    title: 'Remisión repetida',
                    message: `¿La remisión ${remisionDuplicated} se encuentra repetida en los lotes ${lots} desea continuar?`,
                    buttons: [
                        {
                            label: 'Cancelar'
                        },
                        {
                            label: 'Confirmar',
                            onClick: () => {
                                this.openModalMapLotRemision(remisions)
                            }
                        }
                    ]
                });
            }
            else {
                this.openModalMapLotRemision(remisions);
            }
        }.bind(this);
        e.target.value = '';
        reader.readAsBinaryString(f)
    }

    openModalMapLotRemision = async (remisions) => {
        let result = true;
        for (let item of remisions) {
            if (item.C === null) {
                item.remision = item.B;
                result = await this.loadRemision(item);
                if (result) {
                    item.H = item.priceBase;
                    item.G = item.G !== null ? item.G : item.quantity;
                    item.D = item.description;
                    item.C = item.title;
                }
                else {
                    return;
                }
            }
        }
        if (result) {
            await this.setState({
                mapLotRemision: remisions,
                showModalMapLotRemision: true
            });
        }
    }

    mapearLotRemision = async () => {
        let lots = this.state.selectedFiles;
        lots.map(item => {
            this.state.mapLotRemision.map(remision => {
                if (parseInt(item.idLot) === parseInt(remision.A)) {
                    item.remision = remision.B;
                    item.title = remision.C;
                    item.priceBase = remision.H !== null ? remision.H : 0;
                    item.quantity = remision.G !== null ? remision.G : 1;
                    item.description = `${remision.D !== null && remision.D !== '' ? remision.D.trim() : ''} ${remision.E !== null ? remision.E.trim() : ''} ${remision.F ? remision.F.trim() : ''}`;
                }
            });
        });
        await this.setState({ selectedFiles: lots });
        this.closeModalMapLotRemision();
    }

    loadLotsFromImages = async () => {
        let lots = [];
        await this.setState({ loading: true });
        try {
            let response = await getLots({
                token: this.props.sessionProps.account.token,
                auction: this.state.auction.id
            });
            lots = response.data.data.lots;
            lots.map(item => {
                item.showButtonSaveTitle = false;
                item.showButtonSavePriceBase = false;
                item.showButtonSaveDescription = false;
                item.showButtonSaveQuantity = false;
                let temporal = document.createElement("div");
                temporal.innerHTML = item.description;
                item.description = temporal.textContent || temporal.innerText || "";
            });
            await this.setState({ loading: false });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
        //
        let selectedFiles = this.state.selectedFiles;
        selectedFiles.map(item => {
            lots.map(lot => {
                if (parseInt(item.idLot) === parseInt(lot.id_lot)) {
                    item.remision = lot.remision_number;
                    item.title = lot.title;
                    item.priceBase = lot.price_base;
                    item.quantity = lot.quantity;
                    item.description = lot.description;
                }
            });
        });
        await this.setState({ selectedFiles: selectedFiles });
    }

    closeModalCreate = async () => {
        await this.setState({
            showModalCreateLots: false,
            lotsFromExcel: []
        });
    }

    closeModalAddMultipleRemision = async () => {
        await this.setState({
            showModalAddMultipleRemision: false
        });
    }

    closeModalImportLotsFromAuction = async () => {
        await this.setState({
            showModalImportLotsFromAuction: false
        });
    }

    closeModalMapLotRemision = async () => {
        await this.setState({
            showModalMapLotRemision: false,
            mapLotRemision: []
        });
    }

    createLotsFromExcel = async () => {
        let lots = [];
        this.state.lotsFromExcel.map(item => {
            lots.push({
                image: null,
                imageThumb: null,
                idLot: item.A,
                title: `${item.C !== null && item.C !== '' ? item.C : ''} ${item.E !== null ? item.E : ''} ${item.F !== null ? item.F : ''}`,
                priceBase: `${item.H !== null ? item.H : 0}`,
                quantity: `${item.G !== null ? item.G : 1}`,
                remision: item.B
            })
        });
        await this._createLots(lots);
        this.closeModalCreate();
    }

    onPrev = async () => {
        await this.setState({ page: this.state.page - 1 });
        this.fetchLots();
    }

    onNext = async () => {
        await this.setState({ page: this.state.page + 1 });
        this.fetchLots();
    }

    onPage = async (page) => {
        await this.setState({ page: page });
        this.fetchLots();
    }

    loadRemision = async (lot, getQuantityStock = false) => {
        await this.setState({ loading: true });
        try {
            let response = await getRemision({
                token: this.props.sessionProps.account.token,
                id_remision: encodeURIComponent(lot.remision),
                checkExist: true,
                quantity: lot.quantity ? lot.quantity : 1,
                getQuantityStock: getQuantityStock,
                auction: lot.auction ? lot.auction : null
            });
            let { title, price_base, quantity, description } = response.data.data;
            lot.title = title;
            lot.priceBase = price_base;
            lot.quantity = quantity;
            lot.description = description;
            lot.remitter = response.data.data.remitter;
            await this.setState({ loading: false });
            return true;
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                let msg = 'No existe la remisión ingresada, reintentar';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                    if (error.response.data.remisionsWithErrors) {
                        await this.setState({
                            remisionsWithErrors: [...this.state.remisionsWithErrors, error.response.data.remisionsWithErrors]
                        })
                    }
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
            return false;
        }
    }

    closeModalConfirmationRemision = async () => {
        await this.setState({
            showModalConfirmationRemision: false,
            lotConfirmationRemision: null
        });
        document.getElementById("addRemision").focus();
    }

    openModalConfirmRemision = async (lot) => {
        await this.setState({
            showModalConfirmationRemision: true,
            lotConfirmationRemision: lot
        });
    }

    addLot = async (remision = null) => {
        let lot = {};
        lot.remision = remision || this.state.addRemision;
        lot.auction = this.state.auction.id;
        let result = await this.loadRemision(lot);
        if (result) {
            this.openModalConfirmRemision(lot);
        }
        else {
            confirmAlert({
                title: 'Error en remisión',
                message: `¿Esta seguro que desea dejar un lote en blanco?`,
                buttons: [
                    {
                        label: 'Cancelar',
                        onClick: () => {
                        }
                    },
                    {
                        label: 'Confirmar',
                        onClick: () => {
                            this._addLot({
                                auction: this.state.auction.id,
                                quantity: 1,
                                force: true
                            })
                        }
                    }
                ],
            });
        }
    }

    __addLot = async (lot) => {
        if (lot.quantity < 0) {
            toast.error('Cantidad no puede ser menor a 0', {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
        let showConfirmation = false;
        let msg = '';
        await this.setState({ loading: true });
        try {
            let response = await add({
                token: this.props.sessionProps.account.token,
                remision: encodeURIComponent(this.state.addRemision),
                auction: this.state.auction.id,
                onlyAsk: true,
                lot: lot
            });
            if (response.data.message !== null) {
                showConfirmation = true;
                msg = response.data.message;
            }
            await this.setState({ loading: false });
        } catch (error) {
            await this.setState({ loading: false });
            let msg = 'No existe la remisión ingresada, reintentar';
            if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                msg = error.response.data.message;
            }
            toast.error(msg, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            return;
        }
        if (showConfirmation) {
            confirmAlert({
                title: msg,
                message: `¿Esta seguro que desea lotear la remisión ${this.state.addRemision}?`,
                buttons: [
                    {
                        label: 'Cancelar',
                        onClick: () => {
                        }
                    },
                    {
                        label: 'Confirmar',
                        onClick: () => {
                            this._addLot(lot)
                        }
                    }
                ],
                onKeypressEscape: () => { this.__addLot(lot) },
            });
        }
        else {
            this._addLot(lot)
        }
    }

    _addLot = async (lot) => {
        await this.setState({ loading: true });
        try {
            let response = await add({
                token: this.props.sessionProps.account.token,
                remision: encodeURIComponent(this.state.addRemision),
                auction: this.state.auction.id,
                lot: lot
            });
            await this.setState({ loading: false, addRemision: '', showModalConfirmationRemision: false, lotConfirmationRemision: null });
            document.getElementById("addRemision").focus();
            toast(`Remisión loteada con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
            this.fetchLots();
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                let msg = 'No existe la remisión ingresada, reintentar';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    openForceWin = async () => {
        for (let lot of this.state.lots) {
            if (parseInt(lot.id_lot) === parseInt(this.state.searchLot)) {
                await this.setState({
                    lotSelect: lot,
                    searchLot: ''
                });
                return;
            }
        }
        toast.error('No existe el número de lote ingresado, verifique.', {
            position: toast.POSITION.BOTTOM_CENTER,
            pauseOnHover: false,
            duration: 10000
        });
    }

    changeRemision = async (item) => {
        confirmAlert({
            title: 'Cambiar remisión',
            message: `¿Esta seguro que desea asignar la remisión ${item.remision_number} al lote ${item.id_lot}?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._changeRemision(item)
                    }
                }
            ]
        });
    }

    _changeRemision = async (item) => {
        await this.setState({ loading: true });
        try {
            let response = await changeRemision({
                token: this.props.sessionProps.account.token,
                remision: encodeURIComponent(item.remision_number),
                auction: this.state.auction.id,
                lot: item.id
            });
            await this.setState({ loading: false, addRemision: '' });
            toast(`Remisión asignada con éxito.`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
            this.fetchLots();
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                let msg = 'No existe la remisión ingresada, reintentar';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    closeShowImage = async () => {
        await this.setState({
            imageShow: null
        });
    }

    printData = () => {
        let link = document.createElement('a');
        let filterStatus = this.state.filterStatus.value !== 'all' ? '&filterStatus=' + this.state.filterStatus.value : '';
        link.href = `${Constants.BASE_URL}exportLots?id=${this.state.auction.id}&auth=${this.props.sessionProps.account.token}${filterStatus}`;
        link.download = `lotes_subasta_${this.state.auction.title}.xlsx`;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
    }

    removeColumns = (table, index) => {
        let row = table.rows;
        for (var j = 0; j < row.length; j++) {
            row[j].deleteCell(index);
        }
        return table;
    }

    changeFilterStatus = async (e) => {
        await this.setState({ filterStatus: e, page: 1 })
        this.fetchLots();
    }

    changeOrderLots = async (e) => {
        await this.setState({ orderLots: e, page: 1 })
        this.fetchLots();
    }

    lotMultipleRemision = (remisions) => {
        let lots = [];
        let lastIdLot = this.state.lots[this.state.lots.length - 1]?.id_lot;

        if (lastIdLot === undefined) {
            lastIdLot = 0;
        }
        for (let item of remisions) {
            lastIdLot = parseInt(lastIdLot) + 1;
            let pad = "000";
            let ans = pad.substring(0, pad.length - lastIdLot.length) + lastIdLot;
            lots.push({
                image: null,
                imageThumb: null,
                idLot: ans,
                title: item.title,
                priceBase: item.price_base,
                quantity: item.quantityunsold,
                remision: item.id_remitter + '/' + item.remision
            });
        }
        this._createLots(lots);
        this.closeModalAddMultipleRemision();
    }

    importSelectedLots = async (lots) => {
        await this.setState({ loading: true });
        try {
            let response = await importLots({
                token: this.props.sessionProps.account.token,
                lotsIds: lots.map(item => item.id),
                auctionId: this.state.auction.id
            });
            await this.setState({ loading: false, showModalImportLotsFromAuction: false });
            toast(`Lotes importados con éxito.`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
            this.fetchLots();
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                let msg = 'Ocurrió un error, reintentar.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    onReorder = (newImages) => {
        let lots = this.state.lots;
        for (let lot of lots) {
            if (lot.id === this.state.imageShowIdItem) {
                lot.images = newImages;
                this.saveRemision(lot, null, null, null, null, newImages);
                return;
            }
        }
    }

    controlModeViewList = () => (
        <React.Fragment>
            <i className="mdi mdi-format-list-bulleted-square"
                onClick={() => this.setState({ modeViewList: 'list' })}
                style={{
                    fontSize: '20px', cursor: 'pointer',
                    color: `${this.state.modeViewList === 'list' ? 'blue' : '#505d69'}`
                }}></i>
            <span style={{
                borderRight: '1px solid black',
                margin: '0 1px'
            }}></span>
            <i className="mdi mdi-view-grid-outline"
                onClick={() => this.setState({ modeViewList: 'card' })}
                style={{
                    fontSize: '20px', cursor: 'pointer',
                    color: `${this.state.modeViewList === 'card' ? 'blue' : '#505d69'}`
                }}></i>
        </React.Fragment>
    );

    modeViewList = () => (
        <React.Fragment>
            {
                this.state.lots.map(item =>
                    <tr>
                        {
                            (this.state.auction.auctionType === null || this.state.auction.auctionType.online) &&
                            <td>
                                <img onClick={() => this.setState({ imageShow: item.images, imageShowIdItem: item.id })}
                                    data-dz-thumbnail="" className="avatar-sm rounded bg-light"
                                    src={item.images[0]?.image_thumb} style={{ width: '5rem', height: '5rem' }} />
                                {
                                    !this.state.auction.finished &&
                                    <div style={{ position: 'relative' }}>
                                        <Dropzone onDrop={acceptedFiles =>
                                            this.handleFileChangeLot({ target: { files: acceptedFiles } })
                                        }>
                                            {({ getRootProps, getInputProps }) => (
                                                <div className="dropzone"
                                                    style={{ minHeight: '0px', width: 'max-content', bottom: '0', position: 'absolute', right: '0', cursor: 'pointer' }}>
                                                    <div className="needsclick" {...getRootProps()}>
                                                        <input {...getInputProps()} type="file" name="file" onChange={(e) => this.handleFileChangeLot(e.target.files, item)} />
                                                        <i className="mdi mdi-upload"></i>
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                    </div>
                                }
                            </td>
                        }
                        <td>
                            <input name={item.id} value={item.id_lot} onChange={this.handleChangeIdLot}
                                disabled={item.status !== Constants.STATUS_INITIAL && item.status !== Constants.STATUS_IN_PROGRESS}
                                onKeyDown={(e) => { if (e.keyCode === 13) { this.saveRemision(item, null, null, null, item.quantity, null, item.id_lot) } }}
                                style={{ width: '100px' }} />
                            {
                                item.showButtonSaveIdLot &&
                                <React.Fragment>
                                    <Link to="#" onClick={() => this.saveRemision(item, null, null, null, item.quantity, null, item.id_lot)}
                                        className="text-success save-edit" id={`saveLot`}><i className="mdi mdi-check-circle font-size-18"></i></Link>
                                </React.Fragment>
                            }
                        </td>
                        <td>
                            <input name={item.id} value={item.remision_number} onChange={this.handleChangeRemisionNumber}
                                disabled={item.status !== Constants.STATUS_INITIAL && item.status !== Constants.STATUS_IN_PROGRESS} style={{ width: '100%' }}
                                onKeyDown={(e) => { if (e.keyCode === 13) { this.changeRemision(item) } }} />
                            {
                                item.showButtonSaveRemisionNumber &&
                                <React.Fragment>
                                    <Link to="#" onClick={() => this.changeRemision(item, null, null, null, item.quantity)} className="text-success save-edit" id={`saveRemision`}><i className="mdi mdi-check-circle font-size-18"></i></Link>
                                </React.Fragment>
                            }
                        </td>
                        <td>
                            <input name={item.id} value={item.title} onChange={this.handleChangeLotTitle}
                                disabled={item.status !== Constants.STATUS_INITIAL && item.status !== Constants.STATUS_IN_PROGRESS} style={{ width: '100%' }}
                                onKeyDown={(e) => { if (e.keyCode === 13) { this.saveRemision(item, item.title, null, null) } }} spellcheck="true" lang="en" />
                            {
                                item.showButtonSaveTitle &&
                                <React.Fragment>
                                    <Link to="#" onClick={() => this.saveRemision(item, item.title, null, null)} className="text-success save-edit" id={`saveTitle`}><i className="mdi mdi-check-circle font-size-18"></i></Link>
                                </React.Fragment>
                            }
                        </td>
                        <td>
                            <input name={item.id} value={item.quantity} onChange={this.handleChangeLotQuantity} style={{ width: '100%' }}
                                onKeyDown={(e) => { if (e.keyCode === 13) { this.saveRemision(item, null, null, null, item.quantity) } }} />
                            {
                                item.showButtonSaveQuantity &&
                                <React.Fragment>
                                    <Link to="#" onClick={() => this.saveRemision(item, null, null, null, item.quantity)} className="text-success save-edit" id={`saveQty`}><i className="mdi mdi-check-circle font-size-18"></i></Link>
                                </React.Fragment>
                            }
                        </td>
                        {
                            (this.state.auction.auctionType === null || this.state.auction.auctionType.online) &&
                            <td width="100px">
                                <input type="number" name={item.id} value={item.price_base} onChange={this.handleChangeLotPriceBase}
                                    disabled={item.status !== Constants.STATUS_INITIAL && item.status !== Constants.STATUS_IN_PROGRESS}
                                    style={{ width: '100%' }} onKeyDown={(e) => { if (e.keyCode === 13) { this.saveRemision(item, null, null, item.price_base) } }} />
                                {
                                    item.showButtonSavePriceBase &&
                                    <React.Fragment>
                                        <Link to="#" onClick={() => this.saveRemision(item, null, null, item.price_base)} className="text-success save-edit" id={`savePriceBase`}><i className="mdi mdi-check-circle font-size-18"></i></Link>
                                    </React.Fragment>
                                }
                                {
                                    item.showButtonSavePriceBase &&
                                    <label>
                                        <input type="checkbox" defaultChecked={item.applyRemision} onChange={() => this.toggleChange(item)} />
                                        &nbsp; Aplicar en remisión
                                    </label>
                                }
                            </td>
                        }
                        {
                            (this.state.auction.auctionType === null || this.state.auction.auctionType.online) &&
                            <td>
                                <input name={item.id} value={item.description} onChange={this.handleChangeLotDescription}
                                    disabled={item.status !== Constants.STATUS_INITIAL && item.status !== Constants.STATUS_IN_PROGRESS} style={{ width: '100%' }}
                                    onKeyDown={(e) => { if (e.keyCode === 13) { this.saveRemision(item, null, item.description, null) } }} spellcheck="true" lang="en" />
                                {
                                    item.showButtonSaveDescription &&
                                    <React.Fragment>
                                        <Link to="#" onClick={() => this.saveRemision(item, null, item.description, null)} className="text-success save-edit" id={`save`}><i className="mdi mdi-check-circle font-size-18"></i></Link>
                                    </React.Fragment>
                                }
                                {
                                    item.showButtonSaveDescription &&
                                    <label>
                                        <input type="checkbox" defaultChecked={item.applyRemision} onChange={() => this.toggleChange(item)} />
                                        &nbsp; Aplicar en remisión
                                    </label>
                                }
                            </td>
                        }
                        <td>
                            {item.bestUserNameOffer}
                            {
                                item.status !== Constants.STATUS_DELIVERED && ((item.status == Constants.STATUS_PENDING || item.status == Constants.STATUS_NOT_AUCTIONED) ||
                                    (this.state.auction.auctionType !== null && !this.state.auction.auctionType.online)) &&
                                <React.Fragment>
                                    <Link to="#" onClick={() => this.setState({ lotSelect: item })} className="text-success"><i className="mdi mdi-account-edit font-size-22" style={{ marginLeft: '5px' }}></i></Link>
                                </React.Fragment>
                            }
                        </td>
                        <td>
                            {this.state.auction.moneySymbol} {new Intl.NumberFormat("de-DE").format(item.offer)}
                            {
                                this.state.auction.money !== null && this.state.auction.money.value === Constants.MONEY_USD &&
                                ` - ${this.state.auction.moneySymbol2} ${item.offerUYU}`
                            }
                        </td>
                        <td>
                            {item.status == Constants.STATUS_INITIAL && <div className="badge badge-soft-info font-size-12">Pendiente</div>}
                            {item.status == Constants.STATUS_PENDING && <div className="badge badge-soft-warning font-size-12">Subastado</div>}
                            {item.status == Constants.STATUS_IN_PROGRESS && <div className="badge badge-soft-info font-size-12">En proceso</div>}
                            {item.status == Constants.STATUS_NOT_AUCTIONED && <div className="badge badge-soft-secondary font-size-12">No subastado</div>}
                            {item.status == Constants.STATUS_DELIVERED && <div className="badge badge-soft-success font-size-12">Entregado</div>}
                            {
                                item.id_invoice && <React.Fragment>
                                    <br />
                                    <div className="badge badge-soft-danger font-size-12">Facturado</div>
                                </React.Fragment>
                            }
                        </td>
                        <td>1</td>
                        <td>
                            <a href={`/remisiones/${item.id_remision}`} target="_blank" className="mr-3 text-primary" id={"view"}>
                                <i className="mdi mdi-layers-search font-size-22"></i>
                            </a>
                            <DeleteLot item={item} fetchLots={this.fetchLots} />
                        </td>
                    </tr>
                )
            }
        </React.Fragment>
    );

    modeViewCard = () => (
        <React.Fragment>
            {
                this.state.lots.map((item, index) =>
                    <div className="row" style={{ padding: '1.5rem 0rem', borderBottom: `${this.state.lots.length - 1 > index ? '1px solid grey' : 'none'}` }}>
                        <div className="col-6">
                            {
                                (this.state.auction.auctionType === null || this.state.auction.auctionType.online) &&
                                <React.Fragment>
                                    <ImageGallery items={item.images.map(item =>
                                        ({ id: 0, original: item.image, thumbnail: item.image_thumb }))}
                                        showPlayButton={false} />
                                </React.Fragment>
                            }
                        </div>
                        <div className="col-6">
                            <div style={{ marginBottom: '1rem', marginTop: '0.5rem' }}>
                                <h5>LOTE {item.id_lot}</h5>
                            </div>
                            <div style={{ marginBottom: '1rem' }}>
                                <label style={{ marginBottom: '0rem' }}>Nombre</label>
                                <input name={item.id} value={item.title} onChange={this.handleChangeLotTitle}
                                    disabled={item.status !== Constants.STATUS_INITIAL && item.status !== Constants.STATUS_IN_PROGRESS} style={{ width: '100%' }}
                                    onKeyDown={(e) => { if (e.keyCode === 13) { this.saveRemision(item, item.title, null, null) } }} spellcheck="true" lang="en" />
                                {
                                    item.showButtonSaveTitle &&
                                    <React.Fragment>
                                        <Link to="#" onClick={() => this.saveRemision(item, item.title, null, null)} className="text-success save-edit" id={`saveTitle`}><i className="mdi mdi-check-circle font-size-18"></i></Link>
                                    </React.Fragment>
                                }
                            </div>
                            <div style={{ marginBottom: '1rem' }}>
                                <label style={{ marginBottom: '0rem' }}>Cantidad</label>
                                <input name={item.id} value={item.quantity} onChange={this.handleChangeLotQuantity}
                                    disabled={item.status !== Constants.STATUS_INITIAL && item.status !== Constants.STATUS_IN_PROGRESS} style={{ width: '100%' }}
                                    onKeyDown={(e) => { if (e.keyCode === 13) { this.saveRemision(item, null, null, null, item.quantity) } }} />
                                {
                                    item.showButtonSaveQuantity &&
                                    <React.Fragment>
                                        <Link to="#" onClick={() => this.saveRemision(item, null, null, null, item.quantity)} className="text-success save-edit" id={`saveTitle`}><i className="mdi mdi-check-circle font-size-18"></i></Link>
                                    </React.Fragment>
                                }
                            </div>
                            {
                                (this.state.auction.auctionType === null || this.state.auction.auctionType.online) &&
                                <div style={{ marginBottom: '1rem' }}>
                                    <label style={{ marginBottom: '0rem' }}>Precio base</label>
                                    <input type="number" name={item.id} value={item.price_base} onChange={this.handleChangeLotPriceBase}
                                        disabled={item.status !== Constants.STATUS_INITIAL} style={{ width: '100%' }}
                                        onKeyDown={(e) => { if (e.keyCode === 13) { this.saveRemision(item, null, null, item.price_base) } }} />
                                    {
                                        item.showButtonSavePriceBase &&
                                        <React.Fragment>
                                            <Link to="#" onClick={() => this.saveRemision(item, null, null, item.price_base)} className="text-success save-edit" id={`savePriceBase`}><i className="mdi mdi-check-circle font-size-18"></i></Link>
                                        </React.Fragment>
                                    }
                                    {
                                        item.showButtonSavePriceBase &&
                                        <label>
                                            <input type="checkbox" defaultChecked={item.applyRemision} onChange={() => this.toggleChange(item)} />
                                            &nbsp; Aplicar en remisión
                                        </label>
                                    }
                                </div>
                            }
                            {
                                (this.state.auction.auctionType === null || this.state.auction.auctionType.online) &&
                                <div style={{ marginBottom: '1rem' }}>
                                    <label style={{ marginBottom: '0rem' }}>Descripción</label>
                                    <input name={item.id} value={item.description} onChange={this.handleChangeLotDescription}
                                        disabled={item.status !== Constants.STATUS_INITIAL && item.status !== Constants.STATUS_IN_PROGRESS} style={{ width: '100%' }}
                                        onKeyDown={(e) => { if (e.keyCode === 13) { this.saveRemision(item, null, item.description, null) } }} spellcheck="true" lang="en" />
                                    {
                                        item.showButtonSaveDescription &&
                                        <React.Fragment>
                                            <Link to="#" onClick={() => this.saveRemision(item, null, item.description, null)} className="text-success save-edit" id={`save`}><i className="mdi mdi-check-circle font-size-18"></i></Link>
                                        </React.Fragment>
                                    }
                                    {
                                        item.showButtonSaveDescription &&
                                        <label>
                                            <input type="checkbox" defaultChecked={item.applyRemision} onChange={() => this.toggleChange(item)} />
                                            &nbsp; Aplicar en remisión
                                        </label>
                                    }
                                </div>
                            }
                            <div style={{ textAlign: 'right' }}>
                                <a href={`/remisiones/${item.id_remision}`} target="_blank" className="text-primary" id={"view"}>
                                    <i className="mdi mdi-layers-search font-size-22"></i>
                                </a>
                                <DeleteLot item={item} fetchLots={this.fetchLots} />
                            </div>
                        </div>
                    </div>
                )
            }
        </React.Fragment>
    );

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    {this.state.loading && <Loading processFiles={this.state.processFiles} uploadedFiles={this.state.uploadedFiles} />}
                    {
                        this.state.showModalCreateLots &&
                        <ModalCreateLots lots={this.state.lotsFromExcel} close={() => this.closeModalCreate()}
                            createLots={() => this.createLotsFromExcel()} />
                    }
                    {
                        this.state.showModalMapLotRemision &&
                        <ModalMapLotRemision lots={this.state.mapLotRemision} close={() => this.closeModalMapLotRemision()}
                            mapearLotRemision={() => this.mapearLotRemision()} />
                    }
                    {
                        this.state.showModalConfirmationRemision &&
                        <ModalConfirmRemision lot={this.state.lotConfirmationRemision} confirmRemision={(lot) => this.__addLot(lot)} close={() => this.closeModalConfirmationRemision()}
                            id_lot={this.state.auction.lastIdLot} />
                    }
                    {
                        this.state.showModalAddMultipleRemision &&
                        <ModalAddMultipleRemision lotMultipleRemision={async (remision) => {
                            this.addLot(remision);
                            this.setState({
                                showModalAddMultipleRemision: false
                            })
                        }}
                            close={() => this.closeModalAddMultipleRemision()} />
                    }
                    {
                        this.state.showModalImportLotsFromAuction &&
                        <ModalImportLotsFromAuction close={() => this.closeModalImportLotsFromAuction()} importSelectedLots={(lots) => this.importSelectedLots(lots)} />
                    }
                    {this.state.imageShow !== null && <ModalShowImage images={this.state.imageShow} close={() => this.closeShowImage()}
                        onReorder={(newImages) => this.onReorder(newImages)} />}
                    <Container fluid>
                        <Breadcrumbs title="Remates" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        {this.state.auction.id != '0' &&
                                            <React.Fragment>
                                                <h5>
                                                    {
                                                        String(this.state.auction.dateTimeTo).includes('2022') ?
                                                            this.state.auction.id - this.state.auction.lastId2021 : this.state.auction.id
                                                    } - {this.state.auction.title}
                                                </h5>
                                                <Nav tabs className="nav-tabs-custom mb-4">
                                                    <NavItem>
                                                        <NavLink onClick={() => { this.toggleTab(1); }} className={`${this.state.activeTab === 1 && 'active'} font-weight-bold p-3`}>Información</NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink onClick={() => { this.toggleTab(2); }} className={`${this.state.activeTab === 2 && 'active'} font-weight-bold p-3`}>Lotes</NavLink>
                                                    </NavItem>
                                                    {
                                                        this.state.auction.finished && (this.state.auction.auctionType === null || this.state.auction.auctionType.online) &&
                                                        <NavItem>
                                                            <NavLink onClick={() => { this.toggleTab(4); }} className={`${this.state.activeTab === 4 && 'active'} font-weight-bold p-3`}>Logs</NavLink>
                                                        </NavItem>
                                                    }
                                                    {
                                                        !this.state.auction.finished && (this.state.auction.auctionType === null || this.state.auction.auctionType.online) &&
                                                        <NavItem>
                                                            <NavLink onClick={() => { this.toggleTab(3); }} className={`${this.state.activeTab === 3 && 'active'} font-weight-bold p-3`}>Agregar Lotes</NavLink>
                                                        </NavItem>
                                                    }
                                                </Nav>
                                            </React.Fragment>
                                        }
                                        <TabContent activeTab={this.state.activeTab} className="twitter-bs-wizard-tab-content">
                                            <TabPane tabId={1}>
                                                <form onSubmit={this.saveAuction}>
                                                    <FormGroup row>
                                                        <Col md={2}>
                                                            <Label className="col-form-label">Tipo de remate</Label>
                                                        </Col>
                                                        <Col md={10}>
                                                            <Select
                                                                name="auctionType"
                                                                className="select2 select2-multiple"
                                                                placeholder="Seleccionar tipo de remate"
                                                                options={this.state.items}
                                                                value={this.state.auction.auctionType}
                                                                onChange={(sl) => this.handleSelectChange(sl, 'auctionType')}
                                                                required
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label className="col-md-2 col-form-label">Nombre</Label>
                                                        <Col md={10}>
                                                            <Input name="title" className="form-control" type="text" placeholder="Ingrese el nombre del remate"
                                                                value={this.state.auction.title} onChange={this.handleChange} required spellcheck="true" lang="en" />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label className="col-md-2 col-form-label">
                                                            Fecha {(this.state.auction.auctionType === null || this.state.auction.auctionType.online) && 'desde'}
                                                        </Label>
                                                        <Col md={10}>
                                                            <DatePicker
                                                                value={this.state.auction.dateTimeFrom} timeFormat="HH:mm"
                                                                onChange={val => this.setState({ auction: { ...this.state.auction, dateTimeFrom: val } })}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    {
                                                        (this.state.auction.auctionType === null || this.state.auction.auctionType.online || this.state.auction.dateTimeTo) &&
                                                        <FormGroup row>
                                                            <Label className="col-md-2 col-form-label">
                                                                Fecha {(this.state.auction.auctionType === null || this.state.auction.auctionType.online) ? 'hasta' : 'finalización'}
                                                            </Label>
                                                            <Col md={10}>
                                                                <DatePicker
                                                                    value={this.state.auction.dateTimeTo} timeFormat="HH:mm"
                                                                    onChange={val => this.setState({ auction: { ...this.state.auction, dateTimeTo: val } })}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    }
                                                    {
                                                        (this.state.auction.auctionType === null || this.state.auction.auctionType.online) &&
                                                        <React.Fragment>
                                                            <FormGroup row>
                                                                <Label htmlFor="example-datetime-local-input" className="col-md-2 col-form-label">Imagen</Label>
                                                                <Col md={10}>
                                                                    <div className="custom-file">
                                                                        <CustomInput type="file" name="file" className="custom-file-input" onChange={this.handleFileChange} />
                                                                        <Label className="custom-file-label">Seleccionar imagen</Label>
                                                                    </div>
                                                                </Col>
                                                            </FormGroup>
                                                            {
                                                                this.state.auction.image !== '' &&
                                                                <FormGroup row>
                                                                    <Col md={2} />
                                                                    <Col md={10}>
                                                                        <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                                                            <div className="p-2">
                                                                                <Row className="align-items-center">
                                                                                    <Col className="col-auto">
                                                                                        <img
                                                                                            onClick={() => this.setState({
                                                                                                imageShow: [{
                                                                                                    image: `${Constants.BASE_URL}${this.state.auction.image}`,
                                                                                                    image_thumb: `${Constants.BASE_URL}${this.state.auction.image}`
                                                                                                }]
                                                                                            })}
                                                                                            data-dz-thumbnail=""
                                                                                            height="80"
                                                                                            className="avatar-sm rounded bg-light"
                                                                                            alt={`${Constants.BASE_URL}${this.state.auction.image_name}`}
                                                                                            src={`${Constants.BASE_URL}${this.state.auction.image}`}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <a
                                                                                            href={this.state.auction.image}
                                                                                            className="text-muted font-weight-bold"
                                                                                            target="_blank"
                                                                                        >
                                                                                            {this.state.auction.image_name}
                                                                                        </a>
                                                                                        <p className="mb-0">
                                                                                            <strong>{this.state.auction.image_size} KB</strong>
                                                                                        </p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </Card>
                                                                    </Col>
                                                                </FormGroup>
                                                            }
                                                            <FormGroup row>
                                                                <Label htmlFor="example-datetime-local-input" className="col-md-2 col-form-label">Catálogo</Label>
                                                                <Col md={10}>
                                                                    <div className="custom-file">
                                                                        <CustomInput type="file" name="file" className="custom-file-input" onChange={this.handleCatalogChange} />
                                                                        <Label className="custom-file-label">Seleccionar catálogo</Label>
                                                                    </div>
                                                                </Col>
                                                            </FormGroup>
                                                            {
                                                                this.state.auction.catalog !== '' && this.state.auction.catalog !== null &&
                                                                <FormGroup row>
                                                                    <Col md={2} />
                                                                    <Col md={10}>
                                                                        <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                                                            <div className="p-2">
                                                                                <Row className="align-items-center">
                                                                                    <Col className="col-auto">
                                                                                        <a href={`${Constants.BASE_URL}${this.state.auction.catalog}`} target="_blank">Ver catálogo actual</a>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </Card>
                                                                    </Col>
                                                                </FormGroup>
                                                            }
                                                            <FormGroup row>
                                                                <Col md={2}>
                                                                    <Label className="col-form-label">Visibilidad</Label>
                                                                </Col>
                                                                <Col md={10}>
                                                                    <Select
                                                                        name="visible"
                                                                        className="select2 select2-multiple"
                                                                        placeholder="Seleccionar visibilidad"
                                                                        options={[{ value: 0, label: 'No visible' }, { value: 1, label: 'Visible' }]}
                                                                        value={this.state.auction.visible}
                                                                        onChange={(sl) => this.handleSelectChange(sl, 'visible')}
                                                                        required
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                        </React.Fragment>
                                                    }
                                                    <FormGroup row>
                                                        <Col md={2}>
                                                            <Label className="col-form-label">Moneda</Label>
                                                        </Col>
                                                        <Col md={10}>
                                                            <Select
                                                                name="money"
                                                                className="select2 select2-multiple"
                                                                placeholder="Seleccionar moneda"
                                                                options={this.state.moneys}
                                                                value={this.state.auction.money}
                                                                onChange={(sl) => this.handleSelectChange(sl, 'money')}
                                                                required
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Col md={2}>
                                                            <Label className="col-form-label">Moneda Facturación</Label>
                                                        </Col>
                                                        <Col md={10}>
                                                            <Select
                                                                name="money"
                                                                className="select2 select2-multiple"
                                                                placeholder="Seleccionar moneda de facturación"
                                                                options={this.state.moneys}
                                                                value={this.state.auction.moneyInvoice}
                                                                onChange={(sl) => this.handleSelectChange(sl, 'moneyInvoice')}
                                                                required
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    {
                                                        this.state.auction.money !== null && this.state.auction.money.value === Constants.MONEY_USD &&
                                                        <FormGroup row>
                                                            <Label className="col-md-2 col-form-label">Cotización</Label>
                                                            <Col md={10}>
                                                                <Input name="currencyExchange" className="form-control" type="number" placeholder="Ingrese la cotización"
                                                                    value={this.state.auction.currencyExchange} onChange={this.handleChange} />
                                                            </Col>
                                                        </FormGroup>
                                                    }
                                                    <FormGroup row>
                                                        <Col md={2}>
                                                            <Label className="col-form-label">Liquida Ley 12700</Label>
                                                        </Col>
                                                        <Col md={this.state.selectTaxMunicipal.value === 1 ? 4 : 10}>
                                                            <Select
                                                                name="selectTaxMunicipal"
                                                                className="select2 select2-multiple"
                                                                options={[{ value: 0, label: 'No' }, { value: 1, label: 'Si' }]}
                                                                value={this.state.selectTaxMunicipal}
                                                                onChange={(sl) => this.handleSelectChange(sl, 'selectTaxMunicipal', false)}
                                                                required
                                                            />
                                                        </Col>
                                                        {
                                                            this.state.selectTaxMunicipal.value === 1 &&
                                                            <React.Fragment>
                                                                <Label className="col-md-2 col-form-label">% Ley 12700</Label>
                                                                <Col md={4}>
                                                                    <Input name="tax_municipal" className="form-control" type="number" placeholder="Ingrese % Ley 12700"
                                                                        value={this.state.auction.tax_municipal} onChange={this.handleChange} requerido />
                                                                </Col>
                                                            </React.Fragment>
                                                        }
                                                    </FormGroup>
                                                    {
                                                        (this.state.auction.auctionType === null || this.state.auction.auctionType.online) &&
                                                        <React.Fragment>
                                                            <FormGroup>
                                                                <Label>Descripción</Label>
                                                                <Editor
                                                                    editorState={this.state.auction.description}
                                                                    onEditorStateChange={this.onEditorStateChange}
                                                                    toolbarClassName="toolbarClassName"
                                                                    wrapperClassName="wrapperClassName"
                                                                    editorClassName="editorClassName"
                                                                    toolbar={{
                                                                        options: ['inline', 'blockType', 'link', 'emoji', 'image'],
                                                                        inline: { inDropdown: true },
                                                                        link: { inDropdown: true },
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label>Información adicional</Label>
                                                                <Editor
                                                                    editorState={this.state.auction.info_aditional}
                                                                    onEditorStateChange={this.onEditorStateChangeInfoAditional}
                                                                    toolbarClassName="toolbarClassName"
                                                                    wrapperClassName="wrapperClassName"
                                                                    editorClassName="editorClassName"
                                                                    toolbar={{
                                                                        options: ['inline', 'blockType', 'link', 'emoji', 'image'],
                                                                        inline: { inDropdown: true },
                                                                        link: { inDropdown: true },
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </React.Fragment>
                                                    }
                                                    <FormGroup className="mb-0 text-right">
                                                        <div>
                                                            {
                                                                this.state.auction.auctionType !== null && !this.state.auction.auctionType.online && !this.state.auction.dateTimeTo &&
                                                                moment(this.state.auction.dateTimeFrom).format('YYYY-MM-DD HH:mm:ss') <= moment().format('YYYY-MM-DD HH:mm:ss') && this.state.auction.id !== 0 &&
                                                                <Button type="button" onClick={() => this.finishAuction()} color="info" className="ml-2">
                                                                    Finalizar
                                                                </Button>
                                                            }
                                                            <Button type="submit" color="primary" className="ml-2">
                                                                Guardar
                                                            </Button>
                                                        </div>
                                                    </FormGroup>
                                                </form>
                                            </TabPane>
                                            <TabPane tabId={2}>
                                                <Row>
                                                    {
                                                        this.state.auction.auctionType !== null && !this.state.auction.auctionType.online &&
                                                        !this.state.auction.finished &&
                                                        <div className="ml-auto col-6 text-right div-btn-add" style={{ marginBottom: '1rem' }}>
                                                            <Link onClick={() => this._downloadSigns()} className="btn btn-success btn-add w-xs">
                                                                <i className="mdi mdi-pdf-box"></i> Descargar boletas seña
                                                            </Link>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.auction.finished &&
                                                        <div className="ml-auto col-6 text-right div-btn-add" style={{ marginBottom: '1rem' }}>
                                                            <Link onClick={() => this._downloadPdfs()} className="btn btn-success btn-add w-xs">
                                                                <i className="mdi mdi-pdf-box"></i> Descargar PDFs
                                                            </Link>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.auction.finished &&
                                                        <div className="col-6 text-right div-btn-add margin-left-btn-add" style={{
                                                            marginBottom: '1rem', maxWidth: 'max-content'
                                                        }}>
                                                            <Link onClick={() => this._sendPdfs()} className="btn btn-success w-xs"
                                                                style={{ padding: '3px 1rem', marginLeft: 'auto' }}>
                                                                <i className="mdi mdi-pdf-box"></i> Enviar PDFs
                                                            </Link>
                                                        </div>
                                                    }
                                                </Row>
                                                <div className="row" style={{ marginBottom: '1rem' }}>
                                                    <div className="col-6" style={{ display: 'flex', gap: '1rem' }}>
                                                        <div style={{
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}>
                                                            <Link onClick={() => this.setState({ showModalImportLotsFromAuction: true })} className="btn btn-info btn-add w-xs">
                                                                <i className="mdi mdi-download"></i> Importar lotes
                                                            </Link>
                                                        </div>
                                                        {
                                                            this.state.auction.auctionType !== null && !this.state.auction.auctionType.online &&
                                                            moment(this.state.auction.dateTimeFrom).format('YYYY-MM-DD HH:mm:ss') >= moment().format('YYYY-MM-DD HH:mm:ss') &&
                                                            <div style={{ display: 'grid' }}>
                                                                <label>Cargar lotes</label>
                                                                <input type="file" name="file" onChange={this.handleUpload} />
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className={`col-6`}
                                                        style={{ display: 'flex' }}>
                                                        <FormGroup className="ml-auto mr-2" style={{ width: 'max-content' }}>
                                                            <Label>Ingresar comprador</Label>
                                                            <Input name="searchLot" type="text" className="form-control" value={this.state.searchLot}
                                                                onChange={(e) => this.handleChange(e, false)} placeholder="Número de lote"
                                                                onKeyDown={(e) => { if (e.keyCode === 13) { this.openForceWin() } }} />
                                                            <Button type="button" onClick={() => this.openForceWin()} className="mr-3 text-danger"
                                                                style={{
                                                                    position: 'absolute', padding: '0px', right: '13rem',
                                                                    top: '2.05rem', background: 'transparent', border: 'none'
                                                                }}>
                                                                <i className="mdi mdi-check-circle-outline font-size-22" style={{ color: 'green' }}></i>
                                                            </Button>
                                                        </FormGroup>
                                                        <FormGroup style={{ width: 'max-content' }}>
                                                            <Button type="button" onClick={() => this.setState({ showModalAddMultipleRemision: true })} className="mr-3 text-danger"
                                                                style={{
                                                                    position: 'absolute', padding: '0px', right: '5px',
                                                                    top: '-6px', background: 'transparent', border: 'none'
                                                                }}>
                                                                <i className="mdi mdi-clipboard-list-outline font-size-22" style={{ color: 'grey' }}></i>
                                                            </Button>
                                                            <Label>Lotear remisión</Label>
                                                            <Input id="addRemision" name="addRemision" type="text" className="form-control" value={this.state.addRemision}
                                                                onChange={(e) => this.handleChange(e, false)} placeholder="Remisión"
                                                                onKeyDown={(e) => { if (e.keyCode === 13) { this.addLot() } }} />
                                                            <Button type="button" onClick={() => this.addLot()} className="mr-3 text-danger"
                                                                style={{
                                                                    position: 'absolute', padding: '0px', right: '5px',
                                                                    top: '2.05rem', background: 'transparent', border: 'none'
                                                                }}>
                                                                <i className="mdi mdi-check-circle-outline font-size-22" style={{ color: 'green' }}></i>
                                                            </Button>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div className="col-md-4 col-12 order-2 order-md-1 text-left div-btn-add">
                                                        <Select
                                                            name="orderBy"
                                                            className="select2 select2-multiple w-100"
                                                            options={[
                                                                { value: 'all', label: 'Mostrar todos' },
                                                                { value: Constants.STATUS_INITIAL, label: 'Pendiente' },
                                                                { value: Constants.STATUS_PENDING, label: 'Subastado' },
                                                                { value: Constants.STATUS_IN_PROGRESS, label: 'En proceso' },
                                                                { value: Constants.STATUS_NOT_AUCTIONED, label: 'No subastado' },
                                                                { value: Constants.STATUS_DELIVERED, label: 'Entregado' },
                                                                { value: 'facturados', label: 'Facturados' }
                                                            ]}
                                                            value={this.state.filterStatus}
                                                            onChange={(e) => this.changeFilterStatus(e)}
                                                        />
                                                    </div>
                                                    <div class="col-md-4 col-12 order-3 order-md-2 text-center">
                                                        <PaginationCustom page={this.state.page} pages={this.state.pages}
                                                            onPrev={this.onPrev} onNext={this.onNext} onPage={this.onPage} />
                                                    </div>
                                                    <div class="col-md-2 col-12 order-1 order-md-3 text-right">
                                                        <Link onClick={() => this.printData()} className="btn btn-info btn-add w-xs"
                                                            style={{ marginBottom: '0.5rem', height: '35px', float: 'right' }}>
                                                            <i className="mdi mdi-printer"></i> Imprimir
                                                        </Link>
                                                    </div>
                                                    <div class="col-md-2 col-12 order-1 order-md-3 text-right">
                                                        <Select
                                                            name="orderLots"
                                                            className="select2 select2-multiple w-100"
                                                            options={[
                                                                { value: 'asc', label: 'Orden ASC' },
                                                                { value: 'desc', label: 'Orden DESC' },
                                                            ]}
                                                            value={this.state.orderLots}
                                                            onChange={(e) => this.changeOrderLots(e)}
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    this.state.modeViewList === 'list' ?
                                                        <div className="table-responsive">
                                                            <Table className="mb-0" style={{ width: "100%" }} id="printTable">
                                                                <thead>
                                                                    <tr>
                                                                        {
                                                                            (this.state.auction.auctionType === null || this.state.auction.auctionType.online) &&
                                                                            <th></th>
                                                                        }
                                                                        <th style={{ width: '100px' }}>Lote</th>
                                                                        <th style={{ width: '10%' }}>Remisión</th>
                                                                        <th>Nombre</th>
                                                                        <th style={{ width: '10%' }}>Cantidad</th>
                                                                        {
                                                                            (this.state.auction.auctionType === null || this.state.auction.auctionType.online) &&
                                                                            <th style={{ width: '10%' }}>Precio base</th>
                                                                        }
                                                                        {
                                                                            (this.state.auction.auctionType === null || this.state.auction.auctionType.online) &&
                                                                            <th>Descripción</th>
                                                                        }
                                                                        <th>Comprador</th>
                                                                        <th>
                                                                            {
                                                                                (this.state.auction.auctionType === null || this.state.auction.auctionType.online) ?
                                                                                    'Mejor oferta'
                                                                                    : 'Precio subastado'

                                                                            }
                                                                        </th>
                                                                        <th>Estado</th>
                                                                        {
                                                                            (this.state.auction.auctionType === null || !this.state.auction.auctionType.online) &&
                                                                            <th>Nº Boleta</th>
                                                                        }
                                                                        <th style={{
                                                                            paddingLeft: '0',
                                                                            paddingRight: '0',
                                                                            textAlign: 'center'
                                                                        }}>
                                                                            <this.controlModeViewList />
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <this.modeViewList />
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                        :
                                                        <React.Fragment>
                                                            <div className="row">
                                                                <div className="col-12 text-center">
                                                                    <this.controlModeViewList />
                                                                </div>
                                                            </div>
                                                            <this.modeViewCard />
                                                            <div className="row">
                                                                <div className="col-12 text-center">
                                                                    <this.controlModeViewList />
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                }
                                                <PaginationCustom page={this.state.page} pages={this.state.pages}
                                                    onPrev={this.onPrev} onNext={this.onNext} onPage={this.onPage} />
                                                <div className="row">
                                                    <div className="col-12 col-md-4">
                                                        {
                                                            this.state.lots.filter(item => item.showButtonSaveRemisionNumber ||
                                                                item.showButtonSaveTitle ||
                                                                item.showButtonSaveQuantity ||
                                                                item.showButtonSavePriceBase ||
                                                                item.showButtonSaveDescription).length > 0 &&
                                                            <FormGroup className="mb-0 text-left" style={{ marginTop: '1rem' }}>
                                                                <div>
                                                                    <Button onClick={() => this.saveRemisionsMasive()} color="primary" className="ml-2">
                                                                        Guardar
                                                                    </Button>
                                                                </div>
                                                            </FormGroup>
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-8 text-right" style={{
                                                        alignItems: 'flex-end',
                                                        justifyContent: 'flex-end'
                                                    }}>
                                                        <h6>Subtotal:
                                                            {` ${this.state.auction.moneySymbol}`} {this.state.auction.subtotal}
                                                            {
                                                                this.state.auction.money !== null && this.state.auction.money.value === Constants.MONEY_USD &&
                                                                ` - ${this.state.auction.moneySymbol2} ${this.state.auction.subTotalUYU}`
                                                            }
                                                        </h6>
                                                        <h6>IVA:
                                                            {` ${this.state.auction.moneySymbol}`} {this.state.auction.iva}
                                                            {
                                                                this.state.auction.money !== null && this.state.auction.money.value === Constants.MONEY_USD &&
                                                                ` - ${this.state.auction.moneySymbol2} ${this.state.auction.ivaUYU}`
                                                            }
                                                        </h6>
                                                        <h6>Comisión:
                                                            {` ${this.state.auction.moneySymbol}`} {this.state.auction.comision}
                                                            {
                                                                this.state.auction.money !== null && this.state.auction.money.value === Constants.MONEY_USD &&
                                                                ` - ${this.state.auction.moneySymbol2} ${this.state.auction.comisionUYU}`
                                                            }
                                                        </h6>
                                                        <h5>Total ingresado:
                                                            {` ${this.state.auction.moneySymbol}`} {this.state.auction.totalIngress}
                                                            {
                                                                this.state.auction.money !== null && this.state.auction.money.value === Constants.MONEY_USD &&
                                                                ` - ${this.state.auction.moneySymbol2} ${this.state.auction.totalIngressUYU}`
                                                            }
                                                        </h5>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId={3}>
                                                <Form onSubmit={this.createLots}>
                                                    <Dropzone onDrop={acceptedFiles =>
                                                        this.handleFileChangeLots({ target: { files: acceptedFiles } })
                                                    }>
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div className="dropzone">
                                                                <div
                                                                    className="dz-message needsclick mt-2"
                                                                    {...getRootProps()}
                                                                >
                                                                    <input {...getInputProps()} type="file" name="file" onChange={(e) => this.handleFileChangeLots(e.target.files)} />
                                                                    <div className="mb-3">
                                                                        <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                                    </div>
                                                                    <h4>Soltar archivos o click para subir.</h4>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                    <div className="dropzone-previews mt-3" id="file-previews">
                                                        {
                                                            this.state.selectedFiles.length > 0 &&
                                                            <div className="row">
                                                                <div className="col-12 text-right">
                                                                    <div>
                                                                        <label>Cargar remisiones</label>
                                                                        <input className="ml-2" type="file" name="file" onChange={this.handleUploadRemisions} />
                                                                        <Link style={{ height: '40px' }} onClick={() => this.loadLotsFromImages()} className="btn btn-info btn-add w-xs">
                                                                            <i className="mdi mdi-download"></i> Cargar desde lotes
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.selectedFiles.map((f, i) => {
                                                            return (
                                                                <Card
                                                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                    key={i + "-file"}
                                                                >
                                                                    <div className="p-2" style={{
                                                                        border: `1px solid ${this.state.remisionsWithErrors.includes(f.remision) ? 'red' : 'transparent'}`
                                                                    }}>
                                                                        <Row className="align-items-center">
                                                                            <Col className="col-auto">
                                                                                <img
                                                                                    onClick={() => this.setState({ imageShow: f.images.map(img => { return { image: img.image, image_thumb: img.imageThumb } }) })}
                                                                                    data-dz-thumbnail=""
                                                                                    className="avatar-sm rounded bg-light"
                                                                                    src={f.images[0].image}
                                                                                    style={{ width: '7rem', height: '7rem' }}
                                                                                />
                                                                            </Col>
                                                                            <Col className="col-auto">
                                                                                <span>LOTE {f.idLot}</span><br />
                                                                                <span>{f.images.length} Imagenes</span>
                                                                            </Col>
                                                                            <Col md={2}>
                                                                                <FormGroup>
                                                                                    <Label>Remisión</Label>
                                                                                    <Input name="remision" type="text" className="form-control" value={f.remision}
                                                                                        onChange={(e) => this.handleChangeLot(e, i)} />
                                                                                    <Button type="button" onClick={() => this.loadRemision(f)} className="mr-3 text-danger"
                                                                                        style={{
                                                                                            position: 'absolute', padding: '0px', right: '5px',
                                                                                            top: '2.05rem', background: 'transparent', border: 'none'
                                                                                        }}>
                                                                                        <i className="mdi mdi-check-circle-outline font-size-22" style={{ color: 'green' }}></i>
                                                                                    </Button>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col>
                                                                                <FormGroup>
                                                                                    <Label>Nombre</Label>
                                                                                    <Input name="title" type="text" className="form-control" value={f.title}
                                                                                        onChange={(e) => this.handleChangeLot(e, i)} required={f.remision} />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col>
                                                                                <FormGroup>
                                                                                    <Label>Descripción</Label>
                                                                                    <Input name="description" type="text" className="form-control" value={f.description}
                                                                                        onChange={(e) => this.handleChangeLot(e, i)} />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md={1}>
                                                                                <FormGroup>
                                                                                    <Label>Cantidad</Label>
                                                                                    <Input name="quantity" type="text" className="form-control" value={f.quantity}
                                                                                        onChange={(e) => this.handleChangeLot(e, i)} required={f.remision} />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md={1}>
                                                                                <FormGroup>
                                                                                    <Label>Base</Label>
                                                                                    <Input name="priceBase" type="number" className="form-control" value={f.priceBase}
                                                                                        onChange={(e) => this.handleChangeLot(e, i)} required={f.remision} />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col className="col-auto">
                                                                                <Button type="button" onClick={() => this.removeSelectedFilesLot(i)} className="mr-3 text-danger" style={{ marginTop: '10px' }}>
                                                                                    <i className="mdi mdi-close-circle-outline font-size-18"></i>
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Card>
                                                            );
                                                        })}
                                                    </div>
                                                    {
                                                        this.state.selectedFiles.length > 0 &&
                                                        <FormGroup className="mb-0 text-right" style={{ marginTop: '1rem' }}>
                                                            <div>
                                                                <Button type="submit" color="primary" className="ml-2">
                                                                    Guardar
                                                                </Button>
                                                            </div>
                                                        </FormGroup>
                                                    }
                                                </Form>
                                            </TabPane>
                                            <TabPane tabId={4}>
                                                <div className="table-responsive">
                                                    <Table className="mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Lote</th>
                                                                <th>Nombre</th>
                                                                <th>Cliente</th>
                                                                <th>Oferta</th>
                                                                <th>Fecha</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.auction.logs.map(item =>
                                                                    <tr>
                                                                        <th scope="row">{item.lot?.id_lot}</th>
                                                                        <td>{item.lot?.remision?.title}</td>
                                                                        <td>{item.user && item.user?.name}</td>
                                                                        <td>{item.money} {new Intl.NumberFormat("de-DE").format(item.offer)}</td>
                                                                        <td>{`${moment(item.datetime).format('dddd, D. MMMM HH:mm')} hs`}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {
                    this.state.lotSelect !== null &&
                    <ModalForceWin lot={this.state.lotSelect} money={this.state.auction.moneySymbol} close={this.closeModal}
                        isOnline={this.state.auction.auctionType !== null && !this.state.auction.auctionType.online} />
                }
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuctionsAddEdit);
