import './LiquidationsList.scss'

import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Table } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Select from 'react-select';
import { customersListAutocomplete } from '../../services/UserServices';
import { connect } from 'react-redux';
import { printRemitterLiquid } from '../../services/LotServices';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import { logoutAction } from '../../redux/actions/UserActions';
import './RemitterLiquid.scss';
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';
import { Constants } from '../../Constants';
import moment from 'moment';
import PaginationCustom from '../../components/pagination/Pagination';

const getLiquidationList = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}lots/getLiquidationList`, data, httpOptions);
    } catch (error) {
        throw error;
    }

}

const postCancelLiquidation = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}lots/cancelLiquidation`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

class LiquidationsList extends Component {
    state = {
        breadcrumbItems: [
            { title: "Remisiones", link: "#" },
            { title: 'Liquidaciones', link: "#" }
        ],
        customers: [],
        customerId: null,
        customerSelect: null,
        loading: false,
        moneySymbol: null,
        page: 1,
        pages: 0,
        liquidations: [],
    }

    componentDidMount = () => {
        toast.configure();
        this.fetchCustomers();
        this.fetchLiquidationsList();
    }

    fetchCustomers = async () => {
        try {
            let response = await customersListAutocomplete({
                token: this.props.sessionProps.account.token,
                onlyRemitter: true
            });
            await this.setState({
                customers: response.data.data.customers
            });
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    changeCustomer = async (customerId, e = null) => {
        await this.setState({
            customerId: customerId,
            customerSelect: e !== null ? e : this.state.customerSelect,
        });
        this.fetchLiquidationsList();
    }

    fetchLiquidationsList = async () => {
        await this.setState({ loading: true });
        try {
            let response = await getLiquidationList({
                token: this.props.sessionProps.account.token,
                filterRemitterId: this.state.customerId,
                page: this.state.page
            });
            await this.setState({
                liquidations: response.data.data.liquidations,
                pages: response.data.data.pages,
            });
            await this.setState({ loading: false });
        } catch (error) {
            await this.setState({ loading: false });
            let msg = 'Ocurrió un error al procesar la solicitud, reintentar.';
            if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                msg = error.response.data.message;
            }
            toast.error(msg, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
    }

    downloadExcel = (lots, expenses, id_remitter) => {
        let link = document.createElement('a');
        let filters = `user=${id_remitter}`;
        filters += `&lots=${lots.map(lot => lot.id).join(',')}`;
        filters += `&expenses=${expenses}`;
        link.href = `${Constants.BASE_URL}print_liquidated_lots_excel?${filters}`;
        link.download = `lotes_subasta.xlsx`;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
    }

    downloadPdfs = async (lots, expenses, id_remitter) => {
        await this.setState({ loading: true });
        try {
            let response;
            let link = document.createElement('a');
            response = await printRemitterLiquid({
                user: id_remitter,
                token: this.props.sessionProps.account.token,
                lots,
                expenses: expenses,
            });
            link.href = response.data.data.url;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            await this.setState({ loading: false });
        } catch (error) {
            await this.setState({ loading: false });
            let msg = 'Ocurrió un error, reintentar.';
            if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                msg = error.response.data.message;
            }
            toast.error(msg, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
    }

    onPrev = async () => {
        await this.setState({ page: this.state.page - 1 });
        this.fetchLiquidationsList();
    }

    onNext = async () => {
        await this.setState({ page: this.state.page + 1 });
        this.fetchLiquidationsList();
    }

    onPage = async (page) => {
        await this.setState({ page: page });
        this.fetchLiquidationsList();
    }

    _cancelLiquidation = (id) => {
        confirmAlert({
            title: 'Cancelar liquidación',
            message: `¿Esta seguro que desea cancelar la liquidación?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this.cancelLiquidation(id)
                    }
                }
            ]
        });
    }

    cancelLiquidation = async (id) => {
        await this.setState({ loading: true });
        try {
            await postCancelLiquidation({
                id,
                token: this.props.sessionProps.account.token
            });
            await this.setState({ loading: false });
            toast.success(`Se anuló la liquidación`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            this.fetchLiquidationsList();
        } catch (error) {
            await this.setState({ loading: false });
            let msg = 'Ocurrió un error al procesar la solicitud, reintentar.';
            if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                msg = error.response.data.message;
            }
            toast.error(msg, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
    }

    render() {
        return (
            <div id="liquidation-list">
                <div className="page-content">
                    {this.state.loading && <Loading />}
                    <Container fluid>
                        <Breadcrumbs title="Listado de liquidaciones" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Remitente</Label>
                                            <Col md={10}>
                                                <Select
                                                    options={this.state.customers.map(item => ({ value: item.id, label: `${item.id} - ${item.name}` }))}
                                                    className="select2 select2-multiple"
                                                    placeholder="Seleccionar remitente a buscar"
                                                    onChange={(e) => this.changeCustomer(e.value, e)}
                                                    value={this.state.customerSelect}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <div className="table-responsive">
                                            <Table className="mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Fecha</th>
                                                        <th>Remitente</th>
                                                        <th>Lotes</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.liquidations.map((item, index) =>
                                                            <tr>
                                                                <th scope="row">{moment(item.created_at).format('DD/MM/YYYY')}</th>
                                                                <td>{item.client_name}</td>
                                                                <td>
                                                                    {
                                                                        item.lots?.map((lot) => lot.id_lot).join(', ')
                                                                    }
                                                                </td>
                                                                <td>{item.canceled_at ?
                                                                    <div className="badge badge-soft-warning font-size-12">Anulado</div>
                                                                    : null}</td>
                                                                <td>
                                                                    <Button onClick={() => this.downloadPdfs(item.lots, item.expenses, item.id_remitter)}>
                                                                        <i className="mdi mdi-pdf-box"></i>
                                                                    </Button>
                                                                    <Button onClick={() => this.downloadExcel(item.lots, item.expenses, item.id_remitter)}>
                                                                        <i className="mdi mdi-file-excel"></i>
                                                                    </Button>
                                                                    {
                                                                        !item.canceled_at ?
                                                                            <Button className="delete-delivery" onClick={() => this._cancelLiquidation(item.id)}>
                                                                                <i className="mdi mdi-close"></i>
                                                                            </Button> : null
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        <PaginationCustom page={this.state.page} pages={this.state.pages}
                                            onPrev={this.onPrev} onNext={this.onNext} onPage={this.onPage} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LiquidationsList);