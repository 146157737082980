/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import './RemisionAddEdit.scss';
import { Container, Card, CardBody, Row, Nav, NavItem, NavLink, TabPane, TabContent, Col, Form, FormGroup, Label, Input, Button, Table } from "reactstrap";
import { Link } from "react-router-dom";
import classnames from 'classnames';
import axios from 'axios';
import { Constants } from '../../Constants';
import { connect } from 'react-redux';

//Dropzone
import Dropzone from "react-dropzone";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { logoutAction } from '../../redux/actions/UserActions';
import { customersListAutocomplete } from '../../services/UserServices';

import Select from 'react-select';
import { addRemision, editRemision, getRemision } from '../../services/RemisionServices';
import ModalShowImage from '../../components/ModalShowImage/ModalShowImage';

class RemisionAddEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Remisiones", link: "#" },
                { title: `${this.props.match.params.id === '0' ? 'Crear remisión' : 'Editar remisión'}`, link: "#" }
            ],
            selectedFiles: [],
            description: '',
            title: '',
            priceBase: 0,
            id: parseInt(this.props.match.params.id),
            loading: false,
            customers: [],
            customerSelect: null,
            quantity: 1,
            remision: '',
            nameRemision: null,
            lots: [],
            imageShow: null,
            applyLots: true,
            imageOtherLot: [],
            comision: '',
            applyComision: false,
            processFiles: null,
            uploadedFiles: null,
            location: '',
            imageShowIdItem: null,
        }
    }

    componentDidMount() {
        toast.configure();
        this.fetchCustomers();
        if (this.state.id !== 0) {
            this.fetchRemision();
        }
    }

    fetchCustomers = async () => {
        try {
            let response = await customersListAutocomplete({
                token: this.props.sessionProps.account.token
            });
            await this.setState({
                customers: response.data.data.customers
            });
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    fetchRemision = async () => {
        await this.setState({ loading: true });
        try {
            let response = await getRemision({
                token: this.props.sessionProps.account.token,
                id: this.state.id
            });
            let { id, title, description, price_base, images, quantityReal, remitter, remision, id_remitter, lots, comision, location } = response.data.data;
            lots.map(item => {
                item.imagesShow = [];
                let count = 1
                for (let image of item.images) {
                    item.imagesShow.push({
                        image: image.image,
                        image_thumb: image.image_thumb,
                        image_name: `Imagen_${title}_${id}_${Math.floor(Math.random() * (300 - 50 + 1)) + 50}`,
                        image_size: `${Math.floor(Math.random() * (300 - 50 + 1)) + 50}`,
                        id: image.id,
                        position: count
                    });
                    count += 1
                }
            })
            let imagesArray = [];
            for (let image of images) {
                imagesArray.push({
                    image: image.image,
                    image_thumb: image.image_thumb,
                    image_name: `Imagen_${title}_${id}_${Math.floor(Math.random() * (300 - 50 + 1)) + 50}`,
                    image_size: `${Math.floor(Math.random() * (300 - 50 + 1)) + 50}`
                });
            }
            await this.setState({
                title: title,
                description: description,
                priceBase: price_base,
                quantity: quantityReal,
                customerSelect: { value: remitter, label: `${remitter.id} - ${remitter.name}` },
                remision: ` ${id_remitter}/${remision}`,
                selectedFiles: imagesArray,
                loading: false,
                lots: lots,
                comision: comision,
                location
            });
        } catch (error) {
            console.log(error)
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    handleChange = (event) => {
        let { name, value } = event.target;
        if (name === 'quantity') {
            if (value < 1 && value !== '') {
                value = 1;
            }
        }
        this.setState({
            [name]: value
        });
    }

    saveRemision = async (e = null) => {
        if (e) {
            e.preventDefault();
        }
        if (this.state.customerSelect === null) {
            toast.error(`Debe seleccionar un remitente`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            return;
        }
        await this.setState({ loading: true });
        let id_remision = this.state.customerSelect.value.remision === null ? 1 : this.state.customerSelect.value.remision;
        try {
            let data = {
                id: this.state.id,
                token: this.props.sessionProps.account.token,
                price_base: this.state.priceBase,
                title: this.state.title,
                description: this.state.description,
                id_remitter: this.state.customerSelect.value.id,
                remision: id_remision,
                quantity: this.state.quantity,
                imagesGlobal: this.state.selectedFiles,
                comision: this.state.comision,
                applyComision: this.state.applyComision,
                location: this.state.location ? this.state.location : null
            };
            let response = null;
            if (this.state.id === 0) {
                response = await addRemision(data);
                await this.setState({
                    loading: false,
                    description: '',
                    title: '',
                    priceBase: 0,
                    quantity: 1,
                    customerSelect: {
                        ...this.state.customerSelect,
                        value: {
                            ...this.state.customerSelect.value,
                            remision: id_remision + 1
                        }
                    },
                    selectedFiles: [],
                    location: '',
                });
            }
            else {
                let images = [];
                for (let lot of this.state.lots) {
                    for (let image of lot.imagesShow) {
                        image.id_lot = lot.id;
                        images.push(image);
                    }
                }
                data.images = images;
                data.applyLots = this.state.applyLots;
                response = await editRemision(data);
                await this.setState({ loading: false });
            }
            toast(`La remisión ha sido ${this.state.id === 0 ? 'creada' : 'modificada'} con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            document.getElementById("remisionName").focus();
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                let msg = 'Ocurrió un error, reintentar.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    handleFileChange = async (files, item) => {
        let lots = this.state.lots;
        for (let lot of lots) {
            if (lot.id === item.id) {
                await this.setState({
                    loading: true,
                })

                const newFiles = []
                for await (let file of files) {
                    const newFile = await this.getCroppedImg(file);
                    newFiles.push({
                        file: newFile,
                        name: file.name,
                    });
                    // cada 10 push que meto en new files muestro el mensaje
                    if (newFiles.length % 10 === 0) {
                        await this.setState({
                            processFiles: `Procesando ${newFiles.length} de ${files.length} archivos`
                        })
                    }
                }

                await this.setState({
                    processFiles: null
                })

                let count = 0
                for await (let file of newFiles) {
                    if (count % 10 === 0) {
                        await this.setState({
                            uploadedFiles: `Subiendo ${count} de ${files.length} archivos`
                        })
                    }
                    count += 1
                    try {
                        var form = new FormData();
                        form.append('file', file.file);
                        try {
                            const result = await axios.post(`${Constants.BASE_URL_IMAGES}image_upload?type=lots&owner=${Constants.API_NAME}`, form, {
                                headers: {
                                    'Authorization': this.props.sessionProps.account.token,
                                    'content-type': 'multipart/form-data'
                                }
                            });
                            lot.imagesShow.push({
                                image: `${Constants.BASE_URL_IMAGES}${result.data.data.image}`,
                                image_thumb: `${Constants.BASE_URL_IMAGES}${result.data.data.image_thumb}`,
                                image_name: file.name,
                                image_size: (file.file.size / 1024).toFixed(2)
                            })
                        } catch (error) {
                            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                                this.props.logout();
                                this.props.history.push('/');
                            }
                            toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                                position: toast.POSITION.BOTTOM_CENTER,
                                pauseOnHover: false,
                                duration: 10000
                            });
                        }
                    } catch (errorEx) {
                        if (errorEx !== undefined && errorEx.response !== undefined && errorEx.response.status === 401) {
                            this.props.logout();
                            this.props.history.push('/');
                        }
                        console.log(errorEx)
                        toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                            position: toast.POSITION.BOTTOM_CENTER,
                            pauseOnHover: false,
                            duration: 10000
                        });
                        await this.setState({
                            loading: false,
                        })
                    }
                }

                await this.setState({
                    uploadedFiles: null,
                    loading: false,
                })
            }
        }
        await this.setState({
            lots: lots
        });
    }

    deleteImage = async (item, image) => {
        let lots = this.state.lots;
        for (let lot of lots) {
            if (lot.id === item.id) {
                let imagesShow = [];
                for (let _image of lot.imagesShow) {
                    if (_image !== image) {
                        imagesShow.push(_image);
                    }
                }
                lot.imagesShow = imagesShow;
            }
        }
        await this.setState({
            lots: lots
        });
    }

    getCroppedImg = async (file) => {
        // Crea una imagen a partir de la fuente proporcionada
        // Crea una URL desde el objeto File
        const imageSrc = URL.createObjectURL(file);

        // Carga la imagen
        const image = await new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = (e) => {
                reject(new Error(`Failed to load image: ${e}`));
            };
            img.id = file.name;
            img.src = imageSrc;
        });

        URL.revokeObjectURL(imageSrc);

        // Define las dimensiones originales de la imagen
        let width = image.width;
        let height = image.height;

        // Configura el tamaño máximo que la imagen debe tener
        const maxSize = 900;
        if (width > height) {
            // Si el ancho es mayor que el alto
            if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
            }
        } else {
            // Si el alto es mayor que el ancho
            if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
            }
        }

        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        // Limpiar el canvas antes de dibujar
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Establece las dimensiones del canvas basadas en las dimensiones ajustadas
        canvas.width = width;
        canvas.height = height;

        // Dibuja la imagen redimensionada en el canvas
        ctx.drawImage(image, 0, 0, width, height);

        // Retorna un blob de la imagen redimensionada
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                resolve(blob);
            }, 'image/jpeg');
        });
    }

    handleFileChangeGlobal = async (files) => {
        await this.setState({
            loading: true,
        })

        const newFiles = []
        for await (let file of files) {
            const newFile = await this.getCroppedImg(file);
            newFiles.push({
                file: newFile,
                name: file.name,
            });
            // cada 10 push que meto en new files muestro el mensaje
            if (newFiles.length % 10 === 0) {
                await this.setState({
                    processFiles: `Procesando ${newFiles.length} de ${files.length} archivos`
                })
            }
        }

        await this.setState({
            processFiles: null
        })

        let count = 0
        for await (let file of newFiles) {
            if (count % 10 === 0) {
                await this.setState({
                    uploadedFiles: `Subiendo ${count} de ${files.length} archivos`
                })
            }
            count += 1
            try {
                var form = new FormData();
                form.append('file', file.file);
                try {
                    const result = await axios.post(`${Constants.BASE_URL_IMAGES}image_upload?type=lots&owner=${Constants.API_NAME}`, form, {
                        headers: {
                            'Authorization': this.props.sessionProps.account.token,
                            'content-type': 'multipart/form-data'
                        }
                    });
                    this.setState({
                        selectedFiles: [
                            ...this.state.selectedFiles, {
                                image: `${Constants.BASE_URL_IMAGES}${result.data.data.image}`,
                                image_thumb: `${Constants.BASE_URL_IMAGES}${result.data.data.image_thumb}`,
                                image_name: file.name,
                                image_size: (file.file.size / 1024).toFixed(2)
                            }
                        ]
                    });
                } catch (error) {
                    if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                        this.props.logout();
                        this.props.history.push('/');
                    }
                    toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                        position: toast.POSITION.BOTTOM_CENTER,
                        pauseOnHover: false,
                        duration: 10000
                    });
                }
            } catch (errorEx) {
                if (errorEx !== undefined && errorEx.response !== undefined && errorEx.response.status === 401) {
                    this.props.logout();
                    this.props.history.push('/');
                }
                console.log(errorEx)
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
                await this.setState({
                    loading: false,
                })
            }
        }

        await this.setState({
            uploadedFiles: null,
            loading: false,
        })
    }

    deleteImageGlobal = async (item) => {
        let images = this.state.selectedFiles;
        let result = [];
        for (let image of images) {
            if (image !== item) {
                result.push(image);
            }
        }
        await this.setState({
            selectedFiles: result
        });
    }

    closeShowImage = async () => {
        await this.setState({
            imageShow: null
        });
    }

    toggleChange = () => {
        this.setState({
            applyLots: !this.state.applyLots,
        });
    }

    toggleChangeComision = () => {
        this.setState({
            applyComision: !this.state.applyComision
        });
    }

    getImagesForChose = (id) => {
        let result = []
        let lotsWithImages = this.state.lots.filter(item => item.id !== id && item.imagesShow)
        lotsWithImages.map(item => {
            item.imagesShow.map(image => {
                result.push({ value: image.id, label: `${item.id_lot} - ${image.position}` })
            })
        })
        return result
    }

    handleSelectChange = async (e, id) => {
        if (e) {
            e.map(item => { item.lotId = id })
        }
        let imageOtherLot = this.state.imageOtherLot
        await Promise.all(
            imageOtherLot.map((item, index) => {
                if (item.lotId === id) {
                    imageOtherLot.splice(index, 1);
                }
            })
        )
        let newState
        if (e) {
            newState = [...imageOtherLot, ...e]
        }
        else {
            newState = [...imageOtherLot]
        }
        await this.setState({
            imageOtherLot: newState
        })
    }

    confirmNewImages = async (id_lot) => {
        let lots = this.state.lots;
        for (let lot of lots) {
            if (lot.id_lot === id_lot) {
                let imageOtherLot = this.state.imageOtherLot.filter(image => image.lotId === id_lot)
                imageOtherLot.map(item => {
                    lots.map(_lot => {
                        _lot.imagesShow.map(_lotImageShow => {
                            if (_lotImageShow.id === item.value) {
                                if (lot.imagesShow.filter(imageShowExist => imageShowExist.id === _lotImageShow.id).length === 0) {
                                    lot.imagesShow.push({ ..._lotImageShow })
                                }
                            }
                        })

                    })
                })
            }
        }

        let imageOtherLot = this.state.imageOtherLot
        await Promise.all(
            imageOtherLot.map((item, index) => {
                if (item.lotId === id_lot) {
                    imageOtherLot.splice(index, 1);
                }
            })
        )
        await this.setState(
            {
                imageOtherLot: imageOtherLot,
                lots: lots
            }
        )
    }


    onReorder = async (newImages) => {
        if (this.state.imageShowIdItem) {
            let lots = this.state.lots;
            for (let lot of lots) {
                if (lot.id === this.state.imageShowIdItem) {
                    lot.imagesShow = newImages;
                    this.saveRemision();
                    return;
                }
            }
        }
        await this.setState({
            selectedFiles: newImages
        });
        this.saveRemision();
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    {this.state.loading && <Loading processFiles={this.state.processFiles} uploadedFiles={this.state.uploadedFiles} />}
                    {this.state.imageShow !== null && <ModalShowImage images={this.state.imageShow} close={() => this.closeShowImage()}
                        onReorder={(newImages) => this.onReorder(newImages)}
                    />}
                    <Container fluid>
                        <Breadcrumbs title="Remisiones" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <div id="addproduct-nav-pills-wizard" className="twitter-bs-wizard">
                                            <Form onSubmit={this.saveRemision}>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label htmlFor="productname">Remitente</Label>
                                                            <Select
                                                                options={this.state.customers.map(item => ({ value: item, label: `${item.id} - ${item.name}` }))}
                                                                className="select2 select2-multiple"
                                                                placeholder="Seleccionar remitente"
                                                                value={this.state.customerSelect}
                                                                onChange={(e) => {
                                                                    this.setState({ customerSelect: e, comision: e?.value?.comision !== null ? e?.value?.comision : '16.4' });
                                                                    document.getElementById("remisionName").focus();
                                                                }}
                                                                isDisabled={this.state.id !== 0}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <Label htmlFor="price">Comisión</Label>
                                                            <Input name="comision" type="number" className="form-control"
                                                                value={this.state.comision} onChange={this.handleChange} required />
                                                            <label style={{
                                                                fontSize: '10px',
                                                                display: 'flex',
                                                                alignItems: 'self-start', marginTop: '5px'
                                                            }}>
                                                                <input type="checkbox" defaultChecked={false} onChange={() => this.toggleChangeComision()} />
                                                                &nbsp; Actualizar remitente
                                                            </label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3} style={{ alignItems: 'center', display: 'flex' }}>
                                                        <div>
                                                            <h4 style={{ marginTop: '0.5rem', marginBottom: 0 }}>
                                                                <b>Remisión
                                                                    {
                                                                        this.state.id !== 0 ?
                                                                            this.state.remision
                                                                            :
                                                                            this.state.id === 0 && this.state.customerSelect !== null ?
                                                                                this.state.customerSelect.value.remision !== null ?
                                                                                    ` ${this.state.customerSelect.value.id} / ${this.state.customerSelect.value.remision}`
                                                                                    : ` ${this.state.customerSelect.value.id} / 1`
                                                                                : ' 0/0'
                                                                    }
                                                                </b>
                                                            </h4>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label htmlFor="productname">Nombre</Label>
                                                            <Input name="title" type="text" className="form-control" id="remisionName"
                                                                value={this.state.title} onChange={this.handleChange} required spellcheck="true" lang="en" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={2}>
                                                        <FormGroup>
                                                            <Label htmlFor="price">Ubicación</Label>
                                                            <Input name="location" className="form-control"
                                                                value={this.state.location} onChange={this.handleChange} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={2}>
                                                        <FormGroup>
                                                            <Label htmlFor="price">Cantidad</Label>
                                                            <Input name="quantity" type="number" className="form-control"
                                                                value={this.state.quantity} onChange={this.handleChange} required />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={2}>
                                                        <FormGroup>
                                                            <Label htmlFor="price">Precio base</Label>
                                                            <Input name="priceBase" type="number" className="form-control"
                                                                value={this.state.priceBase} onChange={this.handleChange} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <FormGroup>
                                                    <Label htmlFor="productdesc">Descripción</Label>
                                                    <textarea className="textarea-description" value={this.state.description}
                                                        onChange={this.handleChange} name="description" spellcheck="true" lang="en" />
                                                </FormGroup>
                                                <React.Fragment>
                                                    <Dropzone onDrop={acceptedFiles =>
                                                        this.handleFileChangeGlobal({ target: { files: acceptedFiles } })
                                                    }>
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div className="dropzone">
                                                                <div
                                                                    className="dz-message needsclick mt-2"
                                                                    {...getRootProps()}
                                                                >
                                                                    <input {...getInputProps()} type="file" name="file" onChange={(e) => this.handleFileChangeGlobal(e.target.files)} />
                                                                    <div className="mb-3">
                                                                        <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                                    </div>
                                                                    <h4>Soltar archivos o click para subir.</h4>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                    <div className="dropzone-previews mt-3" id="file-previews">
                                                        {this.state.selectedFiles.map((f, i) => {
                                                            return (
                                                                <Card
                                                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                    key={i + "-file"}
                                                                >
                                                                    <div className="p-2">
                                                                        <Row className="align-items-center">
                                                                            <Col className="col-auto">
                                                                                <img
                                                                                    onClick={() => this.setState({
                                                                                        imageShow: this.state.selectedFiles.map(item => ({
                                                                                            image: item.image,
                                                                                            image_thumb: item.image,
                                                                                            id: item.id || Math.floor(Math.random() * (300 - 50 + 1)) + 50,
                                                                                        })),
                                                                                        imageShowIdItem: null
                                                                                    })}
                                                                                    data-dz-thumbnail=""
                                                                                    height="80"
                                                                                    className="avatar-sm rounded bg-light"
                                                                                    src={f.image_thumb}
                                                                                />
                                                                            </Col>
                                                                            <Col>
                                                                                <Link
                                                                                    to="#"
                                                                                    className="text-muted font-weight-bold"
                                                                                >
                                                                                    {f.image_name}
                                                                                </Link>
                                                                                <p className="mb-0">
                                                                                    <strong>{f.image_size} KB</strong>
                                                                                </p>
                                                                            </Col>
                                                                            <div className="delete-remision" style={{ marginRight: '1rem' }} onClick={() => this.deleteImageGlobal(f)}>
                                                                                <i class="ri-close-circle-line" style={{ fontSize: '1.5rem' }}></i>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                </Card>
                                                            );
                                                        })}
                                                    </div>
                                                </React.Fragment>
                                                <FormGroup className="mb-0 text-right" style={{ marginTop: '1rem' }}>
                                                    <div style={{ width: 'max-content', textAlign: 'left', marginLeft: 'auto' }}>
                                                        <Button type="submit" color="primary">
                                                            Guardar
                                                        </Button>
                                                        {
                                                            this.state.id !== 0 &&
                                                            <React.Fragment>
                                                                <br />
                                                                <label style={{ marginTop: '5px' }}>
                                                                    <input type="checkbox" defaultChecked={this.state.applyLots} onChange={this.toggleChange} />
                                                                    &nbsp; Aplicar en lotes
                                                                </label>
                                                            </React.Fragment>}
                                                    </div>
                                                </FormGroup>
                                                {
                                                    this.state.id !== 0 &&
                                                    <div className="table-responsive" style={{ marginTop: '1rem' }}>
                                                        <Table className="mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Lote</th>
                                                                    <th>Remate</th>
                                                                    <th>Estado</th>
                                                                    <th>Facturado</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    this.state.lots.map(item =>
                                                                        <React.Fragment>
                                                                            <tr>
                                                                                <th scope="row">{item.id_lot}</th>
                                                                                <td>{item.title}</td>
                                                                                <td>
                                                                                    {item.status === Constants.STATUS_INITIAL && <div className="badge badge-soft-info font-size-12">Pendiente</div>}
                                                                                    {item.status === Constants.STATUS_PENDING && <div className="badge badge-soft-warning font-size-12">Subastado</div>}
                                                                                    {item.status === Constants.STATUS_IN_PROGRESS && <div className="badge badge-soft-info font-size-12">En proceso</div>}
                                                                                    {item.status === Constants.STATUS_NOT_AUCTIONED && <div className="badge badge-soft-secondary font-size-12">No subastado</div>}
                                                                                    {item.status === Constants.STATUS_DELIVERED && <div className="badge badge-soft-success font-size-12">Entregado</div>}
                                                                                </td>
                                                                                <td>{item.id_invoice === null ? 'No' : 'Si'}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <div style={{ display: 'flex' }}>
                                                                                    {
                                                                                        (true || item.status === Constants.STATUS_INITIAL || item.status === Constants.STATUS_IN_PROGRESS) &&
                                                                                        <Dropzone onDrop={acceptedFiles =>
                                                                                            this.handleFileChange({ target: { files: acceptedFiles } })
                                                                                        }>
                                                                                            {({ getRootProps, getInputProps }) => (
                                                                                                <div className="dropzone"
                                                                                                    style={{ marginBottom: '1rem', marginRight: '1rem', height: 'max-content', minHeight: '0', width: 'max-content' }}>
                                                                                                    <div
                                                                                                        style={{ padding: '0.5rem' }}
                                                                                                        className="dz-message needsclick mt-2"
                                                                                                        {...getRootProps()}
                                                                                                    >
                                                                                                        <input {...getInputProps()} type="file" name="file" onChange={(e) => this.handleFileChange(e.target.files, item)} />
                                                                                                        <label style={{ fontSize: '16px' }}>Soltar archivos o click para subir.</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                        </Dropzone>
                                                                                    }
                                                                                    {
                                                                                        item.imagesShow.map(image =>
                                                                                            <div style={{
                                                                                                position: 'relative',
                                                                                                width: 'max-content'
                                                                                            }}>
                                                                                                <img
                                                                                                    onClick={() => this.setState({
                                                                                                        imageShow: item.imagesShow,
                                                                                                        imageShowIdItem: item.id
                                                                                                    })}
                                                                                                    src={image.image_thumb}
                                                                                                    style={{ marginBottom: '1rem', height: '5rem', marginRight: '1rem' }}
                                                                                                    alt=""
                                                                                                />
                                                                                                <div className="delete-banner" onClick={() => this.deleteImage(item, image)}>
                                                                                                    <i class="ri-close-circle-line"></i>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    (item.status === Constants.STATUS_INITIAL || item.status === Constants.STATUS_IN_PROGRESS) &&
                                                                                    this.state.lots.filter(item => item.imagesShow).length > 0 &&
                                                                                    <div style={{ marginRight: '0.5rem', display: 'flex', marginBottom: '1rem' }}>
                                                                                        <Select
                                                                                            className="select2 select2-multiple w-100"
                                                                                            placeholder="Usar imagen lote"
                                                                                            isMulti
                                                                                            options={this.getImagesForChose(item.id)}
                                                                                            value={this.state.imageOtherLot.filter(image => image.lotId === item.id_lot)}
                                                                                            onChange={(e) => this.handleSelectChange(e, item.id_lot)}
                                                                                        />
                                                                                        <button type='button' onClick={() => { this.confirmNewImages(item.id_lot) }} style={{ border: '1px solid black', background: 'transparent', borderRadius: '5px', marginLeft: '0.5rem' }}>
                                                                                            Confirmar
                                                                                        </button>
                                                                                    </div>
                                                                                }
                                                                            </tr>
                                                                        </React.Fragment>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                }
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RemisionAddEdit);