import axios from 'axios';
import { Constants } from '../Constants';

export const addRemision = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}remisions`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const editRemision = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.put(`${Constants.BASE_URL}remisions`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const editRemisionMasive = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.put(`${Constants.BASE_URL}remisions/masive`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const getRemision = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        let query = '';
        if (data.id) {
            query = `?id=${data.id}`;
        }
        if (data.id_remision) {
            query = `?id_remision=${data.id_remision}`;
        }
        if (data.checkExist) {
            query += `&checkExist=true&quantity=${data.quantity}`;
        }
        if (data.auction) {
            query += `&auction=${data.auction}`;
        }
        return await axios.get(`${Constants.BASE_URL}remisions/find${query}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const getRemisions = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        let query = '';
        if (data.remitter !== null) {
            query += '&remitter=' + data.remitter;
        }
        if (data.offset) {
            query += '&offset=' + data.offset;
        }
        if (data.search) {
            query += '&search=' + data.search;
        }
        if (data.searchRemision) {
            query += '&searchRemision=' + data.searchRemision;
        }
        if (data.filter) {
            query += '&filter=' + data.filter;
        }
        return await axios.get(`${Constants.BASE_URL}remisions?page=${data.page}${query}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const deleteRemision = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.delete(`${Constants.BASE_URL}remisions/${data.id}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const returnRemision = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}remisions/${data.id}`, {}, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const printRemisionReturn = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}remisions/print_remision_return`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const addExpense = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}remisions/addExpense`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const printRemisions = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        let query = '';
        if (data.from !== null && data.from !== '') {
            query += '&from=' + data.from;
        }
        if (data.to !== null && data.to !== '') {
            query += '&to=' + data.to;
        }
        if (data.filter) {
            query += '&filter=' + data.filter;
        }
        if(data.dateReturn){
            query += '&dateReturn=' + data.dateReturn
        }
        return await axios.get(`${Constants.BASE_URL}remisions/print?id=${data.id}${query}`, httpOptions);
    } catch (error) {
        throw error;
    }
}