import axios from 'axios';
import { Constants } from '../Constants';

export const getInvoiceConfigs = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.get(`${Constants.BASE_URL}invoices/configs`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const putInvoiceConfigs = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.put(`${Constants.BASE_URL}invoices/configs`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const getInvoices = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.get(`${Constants.BASE_URL}invoices?type=${data.type}&page=${data.page}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const downloadInvoicePdf = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}invoices/print`, { invoiceId: data.invoiceId }, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const downloadInvoicePdfLiquidation = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}invoices/print_with_liquidation_data`, { invoiceId: data.invoiceId }, httpOptions);
    } catch (error) {
        throw error;
    }
}
