import React, { Component } from 'react';
import './AuctionTypes.scss';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Input, Button, Collapse, Table } from 'reactstrap';
// Form Editor
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { getItemsType, deleteItemType, addItemType } from '../../services/AuctionServices';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { logoutAction } from '../../redux/actions/UserActions';
import { Link } from "react-router-dom";
import Select from 'react-select';

class AuctionTypes extends Component {
    state = {
        loading: false,
        breadcrumbItems: [
            { title: "Remates", link: "/remates" },
            { title: 'Tipos', link: "#" }
        ],
        description: '',
        comision: '',
        items: [],
        online: { value: 1, label: 'Online' },
        invoice_only_comision: { value: 0, label: 'No' },
        id: 0
    }

    componentDidMount = () => {
        toast.configure();
        this.fetchItems();
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    fetchItems = async () => {
        try {
            let response = await getItemsType({
                token: this.props.sessionProps.account.token
            });
            await this.setState({
                items: response.data.data.items,
            })
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    _delete = async (id) => {
        try {
            let response = await deleteItemType({
                token: this.props.sessionProps.account.token,
                id: id
            });
            toast(`Tipo eliminado con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            this.fetchItems();
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    delete = async (id) => {
        confirmAlert({
            title: 'Eliminar tipo',
            message: `¿Esta seguro que desea eliminar el tipo seleccionado?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._delete(id)
                    }
                }
            ]
        });
    }

    save = async () => {
        if (this.state.description === '' || this.state.comision === '') {
            toast.error(`Nombre y comisión son obligatorios`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            return;
        }
        try {
            let response = await addItemType({
                token: this.props.sessionProps.account.token,
                id: this.state.id,
                description: this.state.description,
                comision: this.state.comision,
                online: this.state.online.value,
                invoice_only_comision: this.state.invoice_only_comision.value
            });
            await this.setState({
                description: '',
                comision: '',
                online: { value: 1, label: 'Online' },
                invoice_only_comision: { value: 0, label: 'No' },
                id: 0
            })
            toast(`Tipo de remate guardado con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            this.fetchItems();
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    handleSelectChange = async (selectedOption, name) => {
        await this.setState({
            [name]: selectedOption
        });
    };

    edit = async (item) => {
        await this.setState({
            description: item.description,
            comision: item.comision,
            id: item.id,
            online: item.online === 1 ? { value: 1, label: 'Online' } : item.online === 2 ? { value: 2, label: 'PreOferta' } : { value: 0, label: 'Presencial' },
            invoice_only_comision: item.invoice_only_comision ? { value: 1, label: 'Si' } : { value: 0, label: 'No' }
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    {this.state.loading && <Loading />}
                    <Container fluid>
                        <Breadcrumbs title="Remates" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <Row>

                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label>Descripción</Label>
                                                    <Input name="description" className="form-control" type="text" placeholder="Ingrese la descripción"
                                                        value={this.state.description} onChange={this.handleChange} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label>Comisión</Label>
                                                    <Input name="comision" className="form-control" type="number" placeholder="Ingrese la comisión"
                                                        value={this.state.comision} onChange={this.handleChange} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label>Modalidad</Label>
                                                    <Select
                                                        name="online"
                                                        className="select2 select2-multiple"
                                                        options={[{ value: 1, label: 'Online' }, { value: 0, label: 'Presencial' }, { value: 2, label: 'PreOferta' }]}
                                                        value={this.state.online}
                                                        onChange={(e) => this.handleSelectChange(e, 'online')}
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label>Factura solo comisión</Label>
                                                    <Select
                                                        name="invoice_only_comision"
                                                        className="select2 select2-multiple"
                                                        options={[{ value: 1, label: 'Si' }, { value: 0, label: 'No' }]}
                                                        value={this.state.invoice_only_comision}
                                                        onChange={(e) => this.handleSelectChange(e, 'invoice_only_comision')}
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup className="mb-0 text-right">
                                            <div>
                                                <Button onClick={this.save} type="submit" color="primary" className="ml-2">
                                                    Guardar
                                                </Button>
                                            </div>
                                        </FormGroup>
                                        <div className="table-responsive" style={{ marginTop: '2rem' }}>
                                            <Table className="mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Descripción</th>
                                                        <th>Comisión</th>
                                                        <th>Modalidad</th>
                                                        <th>Factura solo comisión</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.items.map(item =>
                                                            <tr>
                                                                <td>{item.description}</td>
                                                                <td>{item.comision} %</td>
                                                                <td>{item.online === 1 ? 'Online' : item.online === 2 ? 'PreOferta' : 'Presencial'}</td>
                                                                <td>{item.invoice_only_comision ? 'Si' : 'No'}</td>
                                                                <td>
                                                                    <React.Fragment>
                                                                        <Link onClick={() => this.edit(item)} className="text-primary"><i className="mdi mdi-pencil font-size-18"></i></Link>
                                                                        <Link onClick={() => this.delete(item.id)} className="text-danger"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                                                                    </React.Fragment>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
})

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuctionTypes);