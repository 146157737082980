import React, { Component } from 'react';
import './InvoiceAddManual.scss';
import Loading from '../../components/Loading';
import { Container, Row, Col, Card, CardBody, Label, FormGroup, Button, Table, Form } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { customersListAutocomplete } from '../../services/UserServices';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { logoutAction } from '../../redux/actions/UserActions';
import DatePicker from 'react-datetime';
import moment from 'moment';
import { auctionGetMoneys } from '../../services/AuctionServices';

class InvoiceAddManual extends Component {
    state = {
        breadcrumbItems: [
            { title: "Facturación", link: "#" },
            { title: "Manual", link: "#" }
        ],
        customers: [],
        customerSelect: null,
        showModalCustomer: false,
        date: moment().format('DD/MM/Y'),
        moneys: [],
        money: null,
        totals: {
            subtotal: 0,
            iva22: 0,
            total: 0,
            totalToPay: 0,
            round: 0
        },
        info_adicional: '',
        lines: [
            {
                count: 1,
                details: '',
                total: '',
                iva: 22
            }
        ]
    }

    componentDidMount = () => {
        toast.configure();
        this.fetchCustomers();
        this.fetchMoneys();
    }

    fetchMoneys = async () => {
        await this.setState({ loading: true });
        try {
            let response = await auctionGetMoneys({
                token: this.props.sessionProps.account.token,
            });
            await this.setState({
                moneys: response.data.data,
                money: response.data.data.length > 0 ? response.data.data[0] : null,
                loading: false
            })
        } catch (error) {
            await this.setState({ loading: false });
        }
    }

    fetchCustomers = async (idUser = null, name = '') => {
        try {
            let response = await customersListAutocomplete({
                token: this.props.sessionProps.account.token
            });
            await this.setState({
                customers: response.data.data.customers
            });
            if (idUser !== null) {
                let customer = null;
                for (let _customer of this.state.customers) {
                    if (_customer.id_user === idUser) {
                        customer = _customer;
                    }
                }
                await this.setState({
                    customerSelect: { value: customer, label: name },
                    priceForceWin: this.props.lot.offer !== 0 ? this.props.lot.offer : null
                });
            }
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    closeModal = async (customer) => {
        await this.setState({
            showModalCustomer: false
        });
        this.fetchCustomers(customer.id_user, customer.name);
    }

    handleSelectChange = async (selectedOption, name) => {
        await this.setState({
            [name]: selectedOption
        });
    };

    handleChange = async (event) => {
        let { name, value } = event.target;
        await this.setState({
            [name]: value
        });
    }

    addLine = async () => {
        let count = this.state.lines.length > 0 ? this.state.lines[this.state.lines.length - 1].count + 1 : 1;
        let lines = this.state.lines;
        lines.push({
            count: count,
            description: '',
            total: ''
        });
        await this.setState({
            lines: lines
        });
    }

    delete = async (_line) => {
        let lines = [];
        let count = 1;
        for (let line of this.state.lines) {
            if (line !== _line) {
                line.count = count;
                lines.push(line);
                count = count + 1;
            }
        }
        await this.setState({
            lines: lines
        });
        this.calculateTotals();
    }

    setLine = async (e, name, count) => {
        let lines = [];
        for (let line of this.state.lines) {
            if (line.count === count) {
                line = {
                    ...line,
                    [name]: e.target.value
                }
            }
            lines.push(line);
        }
        await this.setState({
            lines: lines
        });
        if (name === 'total') {
            this.calculateTotals();
        }
    }

    calculateTotals = async () => {
        let subtotal = 0;
        let iva22 = 0;
        let total = 0;
        for (let line of this.state.lines) {
            if (line.total !== '') {
                let _total = parseFloat(line.total);
                subtotal += _total;
                let _iva22 = parseFloat(line.total * 0.22);
                iva22 += _iva22;
                total += _total + _iva22;
            }
        }
        await this.setState({
            totals: {
                subtotal: subtotal.toFixed(2),
                iva22: iva22.toFixed(2),
                round: parseFloat(Math.round(total) - total).toFixed(2),
                total: total.toFixed(2),
                totalToPay: Math.round(total).toFixed(2)
            }
        });
    }

    saveInvoice = async (e) => {
        e.preventDefault();
        if (this.state.customerSelect === null) {
            toast.error(`Debe seleccionar un cliente al cual facturar`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
        let data = {
            customer: this.state.customerSelect.value,
            date: this.state.date,
            lines: this.state.lines,
            money: this.state.money.value,
            info_adicional: this.state.info_adicional
        };
        console.log(data)
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    {this.state.loading && <Loading />}
                    <Container fluid>
                        <Breadcrumbs title="Facturación manual" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <Form onSubmit={this.saveInvoice}>
                                            <FormGroup row>
                                                <Label className="col-md-2 col-form-label">Comprador</Label>
                                                <Col md={10} style={{ zIndex: 9, display: 'flex' }}>
                                                    <Select
                                                        name="features"
                                                        options={this.state.customers.map(item => ({ value: item, label: item.name }))}
                                                        className="select2 select2-multiple w-100"
                                                        placeholder="Seleccionar comprador a buscar"
                                                        value={this.state.customerSelect}
                                                        onChange={(e) => this.setState({ customerSelect: e })}
                                                    />
                                                    <Link onClick={() => this.setState({ showModalCustomer: true })} className="text-success">
                                                        {
                                                            this.state.customerSelect !== null ?
                                                                <i className="mdi mdi-account-edit font-size-22" style={{ marginLeft: '5px' }}></i>
                                                                : <i className="mdi mdi-account-plus font-size-22" style={{ marginLeft: '5px' }}></i>
                                                        }
                                                    </Link>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="col-md-2 col-form-label">Fecha</Label>
                                                <Col md={10}>
                                                    <DatePicker
                                                        value={this.state.date} timeFormat={false}
                                                        onChange={val => this.setState({ date: val })}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md={2}>
                                                    <Label className="col-form-label">Moneda</Label>
                                                </Col>
                                                <Col md={10}>
                                                    <Select
                                                        name="money"
                                                        className="select2 select2-multiple"
                                                        placeholder="Seleccionar moneda"
                                                        options={this.state.moneys}
                                                        value={this.state.money}
                                                        onChange={(sl) => this.handleSelectChange(sl, 'money')}
                                                        required
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Adenda</Label>
                                                <textarea className="textarea-description" value={this.state.info_adicional}
                                                    onChange={this.handleChange} name="info_adicional" />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Lineas</Label>
                                                <div className="table-responsive">
                                                    <Table className="mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '10%' }}>Linea</th>
                                                                <th>Detalles</th>
                                                                <th style={{ width: '20%' }}>Precio</th>
                                                                <th style={{ width: '10%', textAlign: 'right' }}>
                                                                    <Link onClick={() => this.addLine()} className="text-success"><i className="mdi mdi-plus-circle font-size-18"></i></Link>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.lines.map(line =>
                                                                    <tr>
                                                                        <td>{line.count}</td>
                                                                        <td>
                                                                            <input type="text" name="details" value={line.details} onChange={(e) => this.setLine(e, 'details', line.count)}
                                                                                style={{ width: '100%' }} required />
                                                                        </td>
                                                                        <td>
                                                                            <input type="number" name="total" value={line.total} onChange={(e) => this.setLine(e, 'total', line.count)}
                                                                                style={{ width: '100%' }} required />
                                                                        </td>
                                                                        <td style={{ textAlign: 'right' }}>
                                                                            <Link onClick={() => this.delete(line)} className="text-danger"><i className="mdi mdi-close-circle font-size-18"></i></Link>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </FormGroup>
                                            <div className="row" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                                                <div className="col-12 text-right" style={{ display: 'inline-flex', justifyContent: 'flex-end' }}>
                                                    <div>
                                                        <h6>Sub total</h6>
                                                        <h6>IVA 22%</h6>
                                                        <h6>Total</h6>
                                                        <h6>Redondeo</h6>
                                                        <h5>Total a pagar</h5>
                                                    </div>
                                                    <div style={{ marginLeft: '0.5rem' }}>
                                                        <h6>{this.state.money?.label}</h6>
                                                        <h6>{this.state.money?.label}</h6>
                                                        <h6>{this.state.money?.label}</h6>
                                                        <h6>{this.state.money?.label}</h6>
                                                        <h5>{this.state.money?.label}</h5>
                                                    </div>
                                                    <div className="text-right" style={{ marginLeft: '0.2rem' }}>
                                                        <h6>{this.state.totals.subtotal}</h6>
                                                        <h6>{this.state.totals.iva22}</h6>
                                                        <h6>{this.state.totals.total}</h6>
                                                        <h6>{this.state.totals.round}</h6>
                                                        <h5>{this.state.totals.totalToPay}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <FormGroup className="mb-0 text-right" style={{ marginTop: '1rem' }}>
                                                <Button type="submit" color="primary" className="ml-2">
                                                    Guardar
                                            </Button>
                                            </FormGroup>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceAddManual);